import React, { useEffect } from "react";
import { Router, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ROUTES } from "./constants";
import HomePage from "./pages/HomePage/HomePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfUsePage from "./pages/TermsOfUse/TermsOfUse";
import ProjectsPage from "./pages/ProjectsPage/ProjectsPage";
import ProjectDetailPage from "./pages/ProjectDetailPage/ProjectDetailPage";
import { PrivateRoute } from "./component/PrivateRoute";
import { actAlertClear, actAlertLinkClear } from "./redux/action";
import { history } from "./utils/history";
import StakingPortalPage from "./pages/staking/StakingPortal";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { get } from "lodash";
import en from "./i18n/en.json";
import cn from "./i18n/cn.json";
import { changeLanguage } from "./redux/action/language";
import CookiePolicyPage from "./pages/CookiePolicy/CookiePolicy";

const resources = {
  en: {
    translation: en
  },
  cn: {
    translation: cn
  }
}
i18n.use(initReactI18next).init({
  resources: resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  }
});


const App = (props) => {
  const language = useSelector((state) => get(state, "language.language", null));
  const { utils, alert } = useSelector((state) => state);
  const dispatch = useDispatch();
  toggleAlert(true);

  window.setTimeout(function () {
    toggleAlert(false);
    // debugger
    if (alert && JSON.stringify(alert) != JSON.stringify({})) {
      dispatch(actAlertClear());
    }

    if (utils.alertLink && utils.alertLink.type.trim().length > 0) {
      dispatch(actAlertLinkClear());
    }
  }, 5000);

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('TRONPAD_LANGUAGE');
    if (defaultLanguage) {
      dispatch(changeLanguage(defaultLanguage))
    }else{
      dispatch(changeLanguage('en'));
    }
  }, []);

  useEffect(() => {
    if (language) {
      i18n.use(initReactI18next).init({
        resources: resources,
        lng: language,
        fallbackLng: "en",
        interpolation: {
          escapeValue: false
        }
      });
    }
  }, [language]);
  useEffect(()=>{
    // const { location: { lang } } = props;
    let params = (new URL(document.location)).searchParams;
    let lang = params.get("lang");
    console.log("lang==>",lang );
    if(lang && (lang.toLocaleLowerCase()==="cn" || lang.toLocaleLowerCase()==="en" )){
      dispatch(changeLanguage(lang))
    }
  },[])

  return (
    <>
      {alert.message && (
        <div id="toast-container" className="toast-top-right">
          <div id="toast-id" className={`toast toast-${alert.type}`}>
            <div className="toast-message">
              <div>
                <div>{alert.message}</div>
              </div>
            </div>
          </div>
        </div>
      )}
      {utils.alertLink.url.length > 0 && (
        <a
          onClick={() => dispatch(actAlertLinkClear())}
          href={utils.alertLink.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div id="toast-container" className="toast-top-right">
            <div
              id="toast-id"
              className={`toast toast-${utils.alertLink.type}`}
            >
              <div className="toast-message">
                <div>
                  <div>{utils.alertLink.message}</div>
                </div>
              </div>
            </div>
          </div>
        </a>
      )}
      <Router history={history}>
        <Switch>
          <PrivateRoute
            exact
            path={ROUTES.HOMEPAGE}
            component={HomePage}
          />
          <PrivateRoute
            exact
            path={ROUTES.PROJECTS}
            component={ProjectsPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.PROJECT_DETAIL}
            component={ProjectDetailPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.TERMS_OF_USE}
            component={TermsOfUsePage}
          />
          <PrivateRoute
            exact
            path={ROUTES.PRIVACY_POLICY}
            component={PrivacyPolicyPage}
          />
          <PrivateRoute
            exact
            path={ROUTES.COOKIE_POLICY}
            component={CookiePolicyPage}
          />
          {/* <PrivateRoute
            exact
            path={ROUTES.CALENDAR}
            component={CalendarPage}
          />   */}
          <PrivateRoute
            exact
            path={ROUTES.STACKING}
            component={StakingPortalPage}
          />
        </Switch>
      </Router>
    </>
  );
};

export default App;

function toggleAlert(isShow) {
  var alertMsg = document.getElementById("toast-container");
  var alertIdMsg = document.getElementById("toast-id");

  // Prevent link alert is dismiss
  if (alertMsg && alertMsg.parentElement.nodeName === "A") {
    return;
  }

  if (alertMsg) {
    alertMsg.style.display = isShow ? "block" : "none";
  }
  if (alertIdMsg) {
    alertIdMsg.style.opacity = isShow ? 1 : 0;
  }
}
