import BigNumber from "bignumber.js";
import { get, result } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isMobile } from "web3modal";
import { ACTION_CONST, ROUTES } from "../../../constants";
import { changeLanguage } from "../../../redux/action/language";
import { getKYC } from "../../../redux/services/kyc.api";
import { helpers } from "../../../utils/helpers";
import { BRIDGE_URI, MODE, TRONPAD_ADDRESS } from "../../../_configs";

const Header = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [logoURI, setLogoURI] = useState("/images/TronPad_white.svg")
  // const [isBrowserMobile, setIsBrowserMobile] = useState(false)
  const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));
  const showModalHelp = useSelector((state) => get(state, "utils.showModalHelp", false));
  const walletAddress = useSelector((state) => get(state, "utils.walletAddress", false));
  const walletUtils = useSelector((state) => get(state, "utils.walletUtils", null));
  const stakedAmount = useSelector((state) => get(state, "wallet.stakingWalletInfo.stakedAmount", 0));
  const kycStatus = useSelector((state) => get(state, "wallet.kycStatus", null));
  const [tronPadBalance, setTronPadBalance] = useState('0');
  const language = useSelector((state) => get(state, "language.language", null));

  //set balance
  useEffect(() => {
    if (walletUtils) {
      walletUtils.getBalanceAccount().then(balance => {

        dispatch({
          type: ACTION_CONST.GET_TRX_BALANCE,
          data: balance
        })
      })
      //get balance token tronpad
      walletUtils.getTokenBalance({ tokenAddress: TRONPAD_ADDRESS[MODE], decimal: 18 }).then(balance => {

        setTronPadBalance(new BigNumber(balance)
          .dividedBy(10 ** 18)
          .toFixed(6)
          .replace(/\.?0+$/, "")
          .toString())
        dispatch({
          type: ACTION_CONST.GET_TRONPAD_BALANCE,
          data: balance
        })

      })
      const jobGetBalance = setInterval(() => {
        walletUtils.getBalanceAccount().then(balance => {

          dispatch({
            type: ACTION_CONST.GET_TRX_BALANCE,
            data: balance
          })
        })
        //get balance token tronpad
        walletUtils.getTokenBalance({ tokenAddress: TRONPAD_ADDRESS[MODE], decimal: 18 }).then(balance => {

          setTronPadBalance(new BigNumber(balance)
            .dividedBy(10 ** 18)
            .toFixed(6)
            .replace(/\.?0+$/, "")
            .toString())
          dispatch({
            type: ACTION_CONST.GET_TRONPAD_BALANCE,
            data: balance
          })

        })
      }, 11 * 1000)
      dispatch({ type: ACTION_CONST.SET_JOB_GET_BALANCE, data: jobGetBalance })
    }


  }, [walletUtils, walletAddress]);



  const handleOnclickKyc = () => {
    getKYC(walletAddress, 'url').then(data => {
      if (data) {
        const url = data.url
        window.open(url, "_blank")
      }

    }).catch(err => {
      console.log(err);
    })
  }

  const handleChangeLanguage = (e, lang) => {
    e.preventDefault();
    dispatch(changeLanguage(lang));
  }


  //check show hide status
  useEffect(() => {
    if (!walletAddress) {
      return;
    }
    if ((Number(tronPadBalance) + Number(stakedAmount)) >= 10.0) {

      getKYCAddress(walletAddress)

      const job = setInterval(() => {
        getKYCAddress(walletAddress)
      }, 20*1000)

      dispatch({
        type: ACTION_CONST.SET_JOB_GET_KYC,
        data: job
      })
    }
  }, [walletAddress, tronPadBalance, stakedAmount])


  //get kyc
  const getKYCAddress = (address) => {
    getKYC(address, 'tronpad').then(response => {
      address = address.toLowerCase()
      if (response) {

        const state = response.state
        // console.log('state===>', state)
        // console.log('address==>', address)

        if (state === 1) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'START'
          })
        }
        if (state === 2) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'PENDING'
          })
        }
        if (state === 3) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'APPROVED'
          })
        }
        if (state === 4) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'ERROR'
          })
        }
      }

    }).catch(err => {
      console.log(err);
    })
  }

  function toggleTheme() {
    if (document.body.classList.contains('darkmode')) {

      setLogoURI("/images/tronpad_black.svg")
      document.body.classList.remove('darkmode');
    } else {

      setLogoURI("/images/TronPad_white.svg")
      document.body.classList.add('darkmode');
    }
  }



  const handGotToProject = () => {
    dispatch({
      type: ACTION_CONST.CLEAR_INTERVAL_PROJECTS_JOB
    })
  }
  return (
    <>
      <nav id="PPNavbar" className="navbar navbar-expand-md navbar-light bg-white">
        <div className="container">
          <Link className="navbar-brand d-flex align-items-center" to={ROUTES.HOMEPAGE}>
            <img src={logoURI} height="65" alt="TronPad" className="me-2" />
          </Link>
          {isMobile() &&
            <div className="d-flex align-items-center" style={{ flexWrap: 'nowrap' }}>
              <div className="dropdown  d-block d-md-none d-lg-none d-xl-none">
              </div>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"

                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          }

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className={isConnectWallet ? 'navbar-nav ms-auto mb-2 mb-md-0 connected' : 'navbar-nav ms-auto mb-2 mb-md-0'}>
              {
                !isConnectWallet ?
                  <li className="nav-item me-2">
                    <a className="btn btn-sm btn-primary" href="#" data-bs-toggle="modal"
                      data-bs-target={"#connectWalletModal"}
                    >
                      <i className="mdi mdi-wallet-plus-outline me-1"></i>
                      <span>{t('Connect Wallet')}</span>
                    </a>
                  </li>
                  :
                  <>
                    <li className="nav-item me-2">
                      <a className="btn btn-sm btn-primary" href="#" data-bs-toggle="modal" data-bs-target="#walletModal">
                        <i className="mdi mdi-wallet-plus-outline me-1"></i>
                        <span>{helpers.formatTransactionHash(walletAddress, 8, 8)}</span>
                        - <b>{helpers.formatNumberDownRoundWithExtractMax(tronPadBalance, 4)}</b> <span>TRONPAD</span>
                      </a>
                    </li>

                    {
                      kycStatus === 'START' &&
                      <li className="nav-item me-2">
                        <button className="btn btn-sm btn-warning"
                          onClick={() => handleOnclickKyc()}
                          id="bnt-kyc-start">
                          <i className="mdi mdi-file-edit-outline me-1"></i>
                          <span>KYC</span>
                        </button>
                      </li>
                    }
                    {
                      kycStatus === 'PENDING' &&
                      <li className="nav-item me-2">
                        <button className="btn btn-sm btn-primary"
                          onClick={() => handleOnclickKyc()}>
                          <i className="mdi mdi-clock-outline"></i>
                          <span> KYC</span>
                        </button>
                      </li>
                    }
                    {
                      kycStatus === 'APPROVED' &&
                      <li className="nav-item me-2">
                        <button className="btn btn-sm btn-success"
                          // onClick={() => handleOnclickKyc()}
                          >
                          <i className="mdi mdi-check"></i>
                          <span> KYC</span>
                        </button>
                      </li>
                    }
                    {
                      kycStatus === 'ERROR' &&
                      <li className="nav-item me-2">
                        <button className="btn btn-sm btn-danger"
                          onClick={() => handleOnclickKyc()}
                        >
                          <i className="mdi mdi-close me-1"></i>
                          <span> KYC</span>
                        </button>

                      </li>
                    }

                  </>

              }
              <li className="nav-item me-2">
                <Link className="btn btn-sm btn-outline-primary btn-projects" aria-current="page" to={ROUTES.PROJECTS} onClick={handGotToProject}>
                  <i className="mdi mdi-fire me-1"></i>
                  <span>{t('Projects')}</span>
                </Link>
              </li>

              <li className="nav-item me-2">
                <Link className="btn btn-sm btn-outline-primary btn-staking" to={ROUTES.STACKING}>

                  <i className="mdi mdi-blender-software me-1"></i><span>{t('Staking')}</span>
                </Link>

              </li>
              <li className="nav-item me-2">
                <a href={BRIDGE_URI[MODE]} target="blank" className="btn btn-sm btn-outline-primary btn-staking">
                  <i className="mdi mdi-bridge me-1"></i><span>{t('Bridge')}</span>
                </a>
              </li>
          
              <li className="nav-item">
                <div className="dropdown">
                  <button className="btn btn-lang btn-sm btn-outline-primary dropdown-toggle text-uppercase" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    {language}
                  </button>
                  <ul className="dropdown-menu dropdown-menu-language dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a className={`dropdown-item ${language === 'en' ? 'active' : ''}`} href="#" onClick={(e) => handleChangeLanguage(e, 'en')}>
                        <img className="me-2" src="/images/united-states-of-america.png" width="20" />
                        <span>English</span>
                      </a>
                    </li>
                    <li>
                      <a className={`dropdown-item ${language === 'cn' ? 'active' : ''}`} href="#" onClick={(e) => handleChangeLanguage(e, 'cn')}>
                        <img className="me-2" src="/images/china.png" width="20" />
                        <span>Chinese</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
