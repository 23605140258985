export const extensionName = {
    tronLink: "TRON_LINK"
};

export const STATUS = {
    APPROVING: 'APPROVING',
    APPROVED: 'APPROVED',
    APPROVE_FAILS: 'APPROVE_FAILS',

    BUY_IDO_SUBMITTING: 'BUY_IDO_SUBMITTING',
    BUY_IDO_FAIL: 'BUY_IDO_FAIL',
    BUY_IDO_SUCCESS: 'BUY_IDO_SUCCESS',

    SWAP_SUBMITTING: 'SWAP_SUBMITTING',
    SWAP_SUCCESS: 'SWAP_SUCCESS',
    SWAP_FAILS: 'SWAP_FAILS',

    WITHDRAW_STAKING_REWARDS_SUBMIT: "WITHDRAW_STAKING_REWARDS_SUBMIT",
    WITHDRAW_STAKING_REWARDS_SUCCESS: 'STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS',
    WITHDRAW_STAKING_REWARDS_FAIL: 'STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL',

    STAKING_REWARDS_SUBMIT: 'STAKING_REWARDS_SUBMIT',
    STAKING_REWARDS_SUCCESS: 'STAKING_REWARDS_SUCCESS',
    STAKING_REWARDS_FAIL: 'STAKING_REWARDS_FAIL',

    STAKING_DEPOSIT_SUBMIT: 'STAKING_DEPOSIT_SUBMIT',
    STAKING_DEPOSIT_SUCCESS: 'STAKING_DEPOSIT_SUCCESS',
    STAKING_DEPOSIT_FAIL: 'STAKING_DEPOSIT_FAIL',

    STAKING_INITIATE_WITHDRAWAL_SUBMIT: 'STAKING_INITIATE_WITHDRAWAL_SUBMIT',
    STAKING_INITIATE_WITHDRAWAL_SUCCESS: 'STAKING_INITIATE_WITHDRAWAL_SUCCESS',
    STAKING_INITIATE_WITHDRAWAL_FAIL: 'STAKING_INITIATE_WITHDRAWAL_FAIL',

    STAKING_EXECUTE_WITHDRAWAL_SUBMIT: 'STAKING_EXECUTE_WITHDRAWAL_SUBMIT',
    STAKING_EXECUTE_WITHDRAWAL_SUCCESS: 'STAKING_EXECUTE_WITHDRAWAL_SUCCESS',
    STAKING_EXECUTE_WITHDRAWAL_FAIL: 'STAKING_EXECUTE_WITHDRAWAL_FAIL',

    CLAIM_SUCCESS: 'CLAIM_SUCCESS',
    CLAIM_FAIL: 'CLAIM_FAIL',

    // SWAP_SUBMIT:"SWAP_SUBMIT",
    // SWAP_SUCCESS:"SWAP_SUCCESS",
    // SWAP_FAIL: 'SWAP_FAIL'
}

/// TIERED SYSTEM
export const TIERED_SYSTEM = {
    BRONZE: {
        level: 'Bronze',
        amountStakingRequire: `1,000`,
        stakingLengthRequire: `3 hours before Allocation Round opens`,
        whitelistRequirementTwitter: `Like, Comment & Retweet`,
        guaranteedAllocation: `Yes`,
        poolWeight: `10`,
        uri: `#`,
    },
    SILVER: {
        level: 'Silver',
        amountStakingRequire: `2,500`,
        stakingLengthRequire: `3 hours before Allocation Round opens`,
        whitelistRequirementTwitter: `Like, Comment & Retweet`,
        guaranteedAllocation: `Yes`,
        poolWeight: `30`,
        uri: `#`,
    },
    GOLD: {
        level: `Gold`,
        amountStakingRequire: `5,000`,
        stakingLengthRequire: `3 hours before Allocation Round opens`,
        whitelistRequirementTwitter: `None`,
        guaranteedAllocation: `Yes`,
        poolWeight: `65`,
        uri: `#`,
    },

    PLATINUM: {
        level: `Platinum`,
        amountStakingRequire: `10,000`,
        stakingLengthRequire: `3 hours before Allocation Round opens`,
        whitelistRequirementTwitter: `None`,
        guaranteedAllocation: `Yes`,
        poolWeight: `145`,
        uri: `#`,
    },
    DIAMOND: {
        level: `Diamond`,
        amountStakingRequire: `25,000`,
        stakingLengthRequire: `3 hours before Allocation Round opens`,
        whitelistRequirementTwitter: `None`,
        guaranteedAllocation: `Yes`,
        poolWeight: `400`,
        uri: `#`,
    },
    BLUE_DIAMOND: {
        level: `Blue Diamond`,
        amountStakingRequire: `75,000`,
        stakingLengthRequire: `3 hours before Allocation Round opens`,
        whitelistRequirementTwitter: `None`,
        guaranteedAllocation: `Yes`,
        poolWeight: `700 + Private allocations`,
        uri: `#`,
    },
};


//ADVISORS
export const ADVISORS = {
    JASPER_BYUN: {
        name: 'JASPER BYUN',
        image: 'images/JASPER_BYUN.jpeg',
        position: 'Founder \n \r at Blocksync Ventures',
        modal: `#team-popup-1`,
        telegram: null,
        twitter: null,
        linked: null,
    },
    LESTER_LIM: {
        name: 'LESTER LIM',
        image: 'images/LESTER_LIM.jpeg',
        position: 'Founder at X21 Digital',
        modal: `#team-popup-2`,
        telegram: null,
        twitter: null,
        linked: null
    },
    IAN_FRIEND: {
        name: 'IAN FRIEND',
        image: 'images/Ian_Friend.jpeg',
        position: 'Co-Founder and COO at Ferrum Network',
        modal: `#team-popup-3`,
        telegram: null,
        twitter: null,
        linked: null
    },
    DANISH_CHAUDHRY: {
        name: 'DANISH CHAUDHRY',
        image: 'images/Danish_Chaudhry.jpg',
        position: 'CEO at Bitcoin.com Exchange – Entrepreneur, Startup Advisor, Mentor and Investor',
        modal: `#team-popup-4`,
        telegram: null,
        twitter: null,
        linked: null
    },
    EXNETWORK_CAPITAL: {
        name: 'EXNETWORK CAPITAL',
        image: 'images/exntc.png',
        position: 'Exnetwork Capital is an investment firm focused on funding and incubating blockchain projects.',
        modal: `#team-popup-5`,
        telegram: null,
        twitter: null,
        linked: null
    },
    TIM_FROST: {
        name: 'TIM FROST',
        image: 'images/TIM_FROST.jpg',
        position: 'CEO and Co-founder \r of YIELD App',
        modal: `#team-popup-6`,
        telegram: null,
        twitter: null,
        linked: null
    }

}

export const LEGAL_PARTNER = {
    image: 'images/SilkLegal_main_horizontal.png',
    description: `Silk Legal is a boutique law firm specializing in FinTech and Cryptocurrency. We combine a deep understanding of blockchain technology with an expert knowledge of international regulations to analyze issues, risks and opportunities. Silk Legal is a proud member of Global Digital Finance, the leading global association of digital asset companies advocating for and accelerating the adoption of best practices for digital assets.`
}