import axios from "axios";
import { ENDPOINTS } from "../../constants";
import {  KYC_BACKEND_URL, MODE } from "../../_configs";
import {convertTronToBscAddress} from '../../utils/tronHelpers'
export const getKYC = async (address, type) => {
  // address = '0xB4baa008B051960E6D13DA39C22af60404197938'
  try {
    let bscAddress = convertTronToBscAddress(address)
    const result = await axios.get(
      `${KYC_BACKEND_URL[MODE]}${ENDPOINTS.GET_KYC}?address=${bscAddress}&type=${type}`
    );
    // console.log(result);
    if (result.status == 200) {
      return result.data;
    }

    return null;
  } catch (error) {
    const response = error.response;

    console.log(response);

    return null;
  }
};
