import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";
import './style.css';

const CookiePolicyPage = (props) => {
  return (
    <>
      <div className="container py-5">
        <div className="d-flex align-items-start tab-term">
          <div className="nav nav-pills-policy flex-column nav-pills me-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <Link to={ROUTES.TERMS_OF_USE} className="nav-link text-start" id="v-pills-terms-tab">Terms of Use </Link>
            <Link to={ROUTES.PRIVACY_POLICY} className="nav-link text-start" id="v-pills-privacy-policy-tab">Privacy Policy</Link>
            <Link to={ROUTES.COOKIE_POLICY} className="nav-link text-start active" id="v-pills-cookies-policy-tab">Cookies Policy</Link>
          </div>
          <div className="tab-content" id="v-pills-policy-tabContent">
            <div className="tab-pane fade show active" id="v-pills-cookies-policy" role="tabpanel" aria-labelledby="v-pills-cookies-policy-tab">
              <div className="cookies-container">
                <p className="c19">
                  <span className="c1 c2">TRONPAD DIGITAL LIMITED</span>
                </p>
                <p className="c10">
                  <span className="c1">Cookies Policy of <a
                      className="c51"
                      href="https://www.google.com/url?q=http://tronpad.network/&sa=D&source=editors&ust=1632073591369000&usg=AOvVaw3U6LGRvo2QfyO0LTiqiLAL"
                    >
                      http://tronpad.network/
                    </a></span>
                </p>
                <p className="c19">
                  <span className="c1">Last revised Date 9</span>
                  <span className="c1 c14">th</span>
                  <span className="c2 c1">&nbsp;September 2021</span>
                </p>
                <p className="c17">
                  <span className="c2 c9 c13" />
                </p>
                <p className="c18">
                  <span className="c2 c9 c13">Cookies Information </span>
                </p>
                <p className="c6">
                  <span className="c15">These Cookies Policy (herein referred as “</span>
                  <span className="c15 c9">Cookies</span>
                  <span className="c15">”) govern the use and the utilization of </span>
                  <span className="c3 c11">
                    <a
                      className="c4"
                      href="https://www.google.com/url?q=https://tronpad.network&sa=D&source=editors&ust=1632072629674000&usg=AOvVaw36zYsA8yU4WDZRmjzDsFQT"
                    >
                      https://tronpad.network
                    </a>
                  </span>
                  <span>&nbsp;</span>
                  <span className="c15">website (herein referred as “</span>
                  <span className="c15 c9">website</span>
                  <span className="c15">”),</span>
                  <span className="c7 c12">&nbsp;</span>
                  <span className="c15">provided by </span>
                  <span>TRXPAD Digital Limited (herein referred to as “</span>
                  <span className="c9">TRXPAD</span>
                  <span>”, “</span>
                  <span className="c9">We</span>
                  <span>”, “</span>
                  <span className="c9">Our</span>
                  <span>”, and “</span>
                  <span className="c9">Us</span>
                  <span>
                    ”), Cookies are small text files that are placed on your computer by the
                    websites that you visit. They are widely used in order to make websites
                    work, or work more efficiently, as well as to provide information to the
                    owners of the site. The use of cookies is now standard for most websites.
                    If you are uncomfortable with the use of cookies, you can manage and
                    control them through your browser, including removing cookies by deleting
                    them from your ‘browser history’ when you leave the site.{" "}
                  </span>
                  <span className="c15">
                    If you do not comfortable to the cookies utilization, please do not access
                    or use the website and the Services.{" "}
                  </span>
                </p>
                <p className="c6">
                  <span className="c2 c7">
                    In order to improve our Service to you, we will occasionally use a
                    “cookie” and/or other similar files or programs which may place certain
                    information on your computer’s hard drive when you visit our website. A
                    cookie is a small amount of data that our web server sends to your web
                    browser when you visit certain parts of our site. We may use cookies to:
                  </span>
                </p>
                <p className="c6 c8">
                  <span className="c2 c7">
                    (i)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;allow us to recognise
                    the PC you are using when you return to our website so that we can
                    understand your interest in our website and tailor its content to match
                    your interests (This type of cookie may be stored permanently on your PC
                    but does not contain any information that can identify you personally.);
                  </span>
                </p>
                <p className="c6 c8">
                  <span className="c2 c7">
                    (ii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;identify you after you
                    have logged in by storing a temporary reference number in the cookie so
                    that our web server can conduct a dialogue with you while simultaneously
                    dealing with other User (Your browser keeps this type of cookie until you
                    log off or close down your browser when these types of cookie are normally
                    deleted. No other information is stored in this type of cookie.);
                  </span>
                </p>
                <p className="c6 c8">
                  <span className="c2 c7">
                    (iii)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;allow you to carry
                    information across pages of our site and avoid having to re-enter that
                    information;
                  </span>
                </p>
                <p className="c6 c8">
                  <span className="c2 c7">
                    (iv)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;allow you access to
                    stored information if you register for any of our on-line services;
                  </span>
                </p>
                <p className="c6 c8">
                  <span className="c2 c7">
                    (v)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;enable us to produce
                    statistical information (anonymous) which helps us to improve the
                    structure and content of our website, to improve our Service;{" "}
                  </span>
                </p>
                <p className="c6 c8">
                  <span className="c2 c7">
                    (vi)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;enable us to evaluate
                    the effectiveness of our advertising and promotions.
                  </span>
                </p>
                <p className="c0">
                  <span className="c2 c7" />
                </p>
                <p className="c6">
                  <span className="c2 c7">
                    By using TRXPAD website or application you consent to the deployment of
                    cookies. You can control and manage cookies using your browser (see
                    below). Please note that removing or blocking cookies can impact your user
                    experience and some functionality may no longer be available. Cookies do
                    not enable us to gather personal information about you unless you give the
                    information to our Service. Most Internet browser software allows the
                    blocking of all cookies or enables you to receive a warning before a
                    cookie is stored. For further information, please refer to your Internet
                    browser software instructions or help screen.{" "}
                  </span>
                </p>
                <p className="c6">
                  <span className="c2 c9 c13">Controlling Cookies by your browser</span>
                </p>
                <p className="c6">
                  <span className="c2 c7">
                    Most browsers allow you to view, manage, delete and block cookies for a
                    website. Be aware that if you delete all cookies then any preferences you
                    have set will be lost, including the ability to opt-out from cookies as
                    this function itself requires placement of an opt out cookie on your
                    device. Guidance on how to control cookies for common browsers is linked
                    below.
                  </span>
                </p>
                <p className="c0">
                  <span className="c2 c7" />
                </p>
                <p className="c6">
                  <span className="c2 c7">Google Chrome</span>
                </p>
                <p className="c6">
                  <span className="c3 c11">
                    <a
                      className="c4"
                      href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?co%3DGENIE.Platform%253DDesktop%26hl%3Den&sa=D&source=editors&ust=1632072629677000&usg=AOvVaw3up4SBCFsCDXLN8OZKpwLx"
                    >
                      https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=en
                    </a>
                  </span>
                  <span className="c2 c7">&nbsp;</span>
                </p>
                <p className="c6">
                  <span className="c2 c7">Mozilla Firefox </span>
                </p>
                <p className="c6">
                  <span className="c3 c11">
                    <a
                      className="c4"
                      href="https://www.google.com/url?q=https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug%3Denable-and-disable-cookies-website-preferences%26redirectlocale%3Den-US&sa=D&source=editors&ust=1632072629678000&usg=AOvVaw2kZWD8m7ZZ2R38_87Hzx17"
                    >
                      https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&amp;redirectlocale=en-US
                    </a>
                  </span>
                  <span className="c2 c7">&nbsp;</span>
                </p>
                <p className="c6">
                  <span className="c2 c7">Safari web and iOS</span>
                </p>
                <p className="c6">
                  <span className="c3 c11">
                    <a
                      className="c4"
                      href="https://www.google.com/url?q=https://support.apple.com/en-us/HT201265&sa=D&source=editors&ust=1632072629678000&usg=AOvVaw3hiiXH5aKYvm5wz6Nu4tFs"
                    >
                      https://support.apple.com/en-us/HT201265
                    </a>
                  </span>
                  <span className="c2 c7">&nbsp;</span>
                </p>
                <p className="c6">
                  <span className="c2 c7">Microsoft Internet Explorer</span>
                </p>
                <p className="c6">
                  <span className="c3 c11">
                    <a
                      className="c4"
                      href="https://www.google.com/url?q=https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d&sa=D&source=editors&ust=1632072629679000&usg=AOvVaw27wNiyfsEIfMl3-oI30hc0"
                    >
                      https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d
                    </a>
                  </span>
                  <span className="c2 c7">&nbsp;</span>
                </p>
                <p className="c18">
                  <span>
                    Alternatively, information on deleting or controlling cookies is available
                    at{" "}
                  </span>
                  <span className="c3 c11">
                    <a
                      className="c4"
                      href="https://www.google.com/url?q=http://www.allaboutcookies.org&sa=D&source=editors&ust=1632072629679000&usg=AOvVaw2sD-LfK1eELZBd8rrgWhnj"
                    >
                      http://www.allaboutcookies.org
                    </a>
                  </span>
                  <span className="c2 c7">. </span>
                </p>
                <p className="c6">
                  <span>
                    For information on additional browsers and device type please see{" "}
                  </span>
                  <span className="c3 c11">
                    <a
                      className="c4"
                      href="https://www.google.com/url?q=http://www.aboutcookies.org/&sa=D&source=editors&ust=1632072629680000&usg=AOvVaw2S26R_b6JwbaDYcQ3o7qvg"
                    >
                      http://www.aboutcookies.org/
                    </a>
                  </span>
                  <span>&nbsp;or </span>
                  <span className="c2 c7">.</span>
                </p>
                <p className="c0">
                  <span className="c2 c7" />
                </p>
                <p className="c6">
                  <span className="c2 c9 c13">Type of Cookies</span>
                </p>
                <p className="c6">
                  <span className="c3">Strictly Necessary Cookies</span>
                  <span className="c2 c7">
                    : These cookies are essential to enable you to log in, navigate a website,
                    and use its features or to provide a service requested by you. We will not
                    need to obtain your consent in order to use these cookies.
                  </span>
                </p>
                <p className="c6">
                  <span className="c3">Functionality Cookies</span>
                  <span className="c2 c7">
                    : These cookies allow the website to remember choices you make (such as
                    your username, language, or the region you reside in) and provide
                    enhanced, more personal features. The information these cookies collect
                    remains anonymous, and they cannot track your browsing activity on other
                    websites.
                  </span>
                </p>
                <p className="c6">
                  <span className="c3">Performance cookies</span>
                  <span className="c2 c7">
                    : These cookies collect information about how you use a website, for
                    example, which pages you go to most often, how much time you spend on a
                    page, record difficulties you may experience while using the website such
                    as error messages. All information collected by these cookies is
                    aggregated and therefore anonymous. It is only used to improve the
                    efficiency of the website.
                  </span>
                </p>
                <p className="c6">
                  <span className="c3">Targeting Cookies or Advertising Cookies</span>
                  <span className="c2 c7">
                    : These cookies are used to deliver advertisements tailored to you and
                    your interests specifically. They are also used to limit the number of
                    times you see an advertisement as well as help measure the effectiveness
                    of the advertising campaign. These cookies remember that you have visited
                    a website, and this information is shared with other organizations such as
                    advertisers. Quite often targeting or advertising cookies will be linked
                    to the sites’ functionality provided by the other organizations.{" "}
                  </span>
                </p>
                <p className="c0">
                  <span className="c2 c7" />
                </p>
                <p className="c6">
                  <span>
                    For any questions regarding this Cookies Policy, your Cookies management
                    or if you would like to contact is about the cookies, please email{" "}
                  </span>
                  <span className="c3 c11">
                    <a className="c4" href="https://bluezilla.jitbit.com/helpdesk/" target="_blank">
                    Contact Support
                    </a>
                  </span>
                  <span>&nbsp;and visit Our website at </span>
                  <span className="c3 c11">
                    <a
                      className="c4"
                      href="https://www.google.com/url?q=https://tronpad.network&sa=D&source=editors&ust=1632072629681000&usg=AOvVaw2ECivy1FvHDGZTy8dVWFy1"
                    >
                      https://tronpad.network
                    </a>
                  </span>
                  <span>.</span>
                  <span className="c7 c20">&nbsp;</span>
                  <span
                    style={{
                      overflow: "hidden",
                      display: "inline-block",
                      margin: "0.00px 0.00px",
                      border: "0.00px solid #000000",
                      transform: "rotate(0.00rad) translateZ(0px)",
                      WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                      width: "3.33px",
                      height: "1.33px"
                    }}
                  >
                    <img
                      alt
                      src="images/image1.png"
                      style={{
                        width: "3.33px",
                        height: "1.33px",
                        marginLeft: "0.00px",
                        marginTop: "0.00px",
                        transform: "rotate(0.00rad) translateZ(0px)",
                        WebkitTransform: "rotate(0.00rad) translateZ(0px)"
                      }}
                      title
                    />
                  </span>
                </p>
                <p className="c0">
                  <span className="c2 c7" />
                </p>
                <p className="c6">
                  <span>
                    TRXPAD reserves the right to amend its prevailing Cookies Policy at any
                    time and will place any such amendments at{" "}
                  </span>
                  <span className="c3 c11">
                    <a
                      className="c4"
                      href="https://www.google.com/url?q=https://tronpad.network&sa=D&source=editors&ust=1632072629682000&usg=AOvVaw3mvTPIaz7xoN1Y5iAiuaR1"
                    >
                      https://tronpad.network
                    </a>
                  </span>
                  <span>
                    . This Cookies Policy is not intended to, nor does it, create any
                    contractual rights whatsoever or any other legal rights, nor does it
                    create any obligations on TRXPAD in respect of any other party or on
                    behalf of any party.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookiePolicyPage;
