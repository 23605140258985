import React from 'react';
import TierRow from '../TierRow';
import { useTranslation } from 'react-i18next';

export default function TierContainer({ TIERED }) {
    const { t } = useTranslation();
    return (
        <>
            <div className="pp-home-how-it-work-content pp-section-body">
                <div className="container">
                    {/* <div className="text-center mb-5">
                        <h3 className="text-uppercase" data-aos="fade-up" data-aos-delay="300"><b>Round 1 - Allocation Round</b></h3>
                    </div>
                    <div className="row mb-3">
                        <TierRow tiered={TIERED["BRONZE"]} />
                        <TierRow tiered={TIERED["SILVER"]} />
                        <TierRow tiered={TIERED["GOLD"]} />
                        <TierRow tiered={TIERED["PLATINUM"]} />
                        <TierRow tiered={TIERED["DIAMOND"]} />
                        <TierRow tiered={TIERED["BLUE_DIAMOND"]} />
                    </div> */}

                    {/* <div className="text-center mb-5 mt-5">
                        <h3 className="text-uppercase">< b>{t('PUBLIC WHITE LIST LOTTERY')}</b></h3>
                    </div>
                    <p className="font-20 text-center">{t('5% of the IDO will be allocated to the public!, this is open to anyone who completes tasks, with no requirement to stake TRONPAD tokens.')}</p>
                    <p className="font-20 text-center">{t('Follow the announcements channel to learn what to do for each project.')}</p> */}

                    <div className="text-center mb-5 mt-5">
                        <h3 className="text-uppercase">< b>{t('LOTTERY TIER')}</b></h3>
                    </div>
                    <div className="row mb-3 justify-content-center">
                        <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
                            <div className="pricing-table text-center">
                                <p className="bold price-header"><b className="text-warning">{t('Earth')}</b></p>
                                <hr />
                                <p className="light thin mb-0">{t('Staking Requirement')}</p>
                                <p className="price" style={{lineHeight:'1.3'}}><span className="bold">5,000</span><br/><i className="font-16">1 {t('lottery ticket')}</i></p>
                                <p className="price" style={{lineHeight:'1.3'}}><span className="bold">10,000</span><br/><i className="font-16">2 {t('lottery ticket')}</i></p>
                                <p className="price" style={{lineHeight:'1.3'}}><span className="bold">15,000</span><br/><i className="font-16">3 {t('lottery ticket')}</i></p>
                                <p className="light thin mb-0">{t('Staking Length Required')}</p>
                                <p className="value price"><span className="font-18">3 {t('hours before allocation round opens')}</span></p>
                                <hr />
                                <a href='https://bscpad.medium.com/tronpad-tronpad-the-official-launchpad-of-the-tron-blockchain-8e0c7654e0ae' target="blank" className="btn btn-primary btn-lg">{t('Learn more')}</a>
                            </div>
                        </div>

                    </div>

                    <div className="text-center mb-5 mt-5">
                        <h3 className="text-uppercase">< b>{t('GUARANTEED ALLOCATION TIERS')}</b></h3>
                    </div>
                    <div className="row mb-3 justify-content-center">

                        <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
                            <div className="pricing-table text-center">
                                <p className="bold price-header"><b className="text-warning">{t('Moon')}</b></p>
                                <hr />
                                <p className="light thin mb-0">{t('Staking Requirement')}</p>
                                <p className="bold price"><span>50,000</span></p>
                                <p className="value price"><span className="font-18">1 {t('Guaranteed Allocation')}</span></p>
                                {/* <p className="value price"><span className="font-18">10 {t('Pool Weight')}</span></p> */}
                                <p className="light thin mb-0">{t('Staking Length Required')}</p>
                                <p className="value price"><span className="font-18">3 {t('hours before allocation round opens')}</span></p>
                                <p className="light thin mb-0">{t('Pool Weight')}</p>
                                <p className="value price"><span className="font-18">10</span></p>
                                <hr />
                                <a href='https://bscpad.medium.com/tronpad-tronpad-the-official-launchpad-of-the-tron-blockchain-8e0c7654e0ae' target="blank" className="btn btn-primary btn-lg">{t('Learn more')}</a>
                            </div>
                        </div>

                        <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
                            <div className="pricing-table text-center">
                                <p className="bold price-header"><b className="text-warning">{t('Mars')}</b></p>
                                <hr />
                                <p className="light thin mb-0">{t('Staking Requirement')}</p>
                                <p className="bold price"><span>200,000</span></p>
                                <p className="value price"><span className="font-18">1  {t('Guaranteed Allocation')}</span></p>
                                
                                <p className="light thin mb-0">{t('Staking Length Required')}</p>
                                <p className="value price"><span className="font-18">3 {t('hours before allocation round opens')}</span></p>
                                <p className="light thin mb-0">{t('Pool Weight')}</p>
                                <p className="value price"><span className="font-18">45</span></p>
                                <hr />
                                <a href='https://bscpad.medium.com/tronpad-tronpad-the-official-launchpad-of-the-tron-blockchain-8e0c7654e0ae' target="blank" className="btn btn-primary btn-lg">{t('Learn more')}</a>
                            </div>


                        </div>

                        <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
                            <div className="pricing-table text-center">
                                <p className="bold price-header"><b className="text-warning">{t('Sun')}</b></p>
                                <hr />
                                <p className="light thin mb-0">{t('Staking Requirement')}</p>
                                <p className="bold price"><span>500,000</span></p>
                                <p className="value price"><span className="font-18">1  {t('Guaranteed Allocation')}</span></p>
                                
                                <p className="light thin mb-0">{t('Staking Length Required')}</p>
                                <p className="value price"><span className="font-18">3 {t('hours before allocation round opens')}</span></p>
                                <p className="light thin mb-0">{t('Pool Weight')}</p>
                                <p className="value price"><span className="font-18">70 + Private Allocations</span></p>
                                <hr />
                                <a href='https://bscpad.medium.com/tronpad-tronpad-the-official-launchpad-of-the-tron-blockchain-8e0c7654e0ae' target="blank" className="btn btn-primary btn-lg">{t('Learn more')}</a>
                            </div>

                            
                        </div>
                        {/* <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
                            <div className="pricing-table text-center">
                                <p className="bold price-header"><b className="text-warning">Sun</b></p>
                                <hr />
                                <p className="light thin mb-0">Sun tier details will be unveiled at a later date!</p>
                            </div>
                        </div> */}
                    </div>

                    <p className="text-center mb-5">{t('Lottery winners will be announced prior to the IDO and can purchase their allocation when IDO opens. All other guaranteed tiers users can purchase their guaranteed allocation when the IDO opens too.All allocations will need to be purchased in the allotted time or it will be forfeited and offered as first come first served.')}</p>

                    <div className="text-center mb-5 mt-5"><h3 className="text-uppercase"><b>{t('Round 2 - FCFS ROUND')}</b></h3></div>
                    <div className="row mb-5 align-items-center"><div className="col-md-6" data-aos="fade-up" data-aos-delay="100"><img src="/images/gfx-e.png" alt="" className="w-100" /></div><div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
                        <p>{t('In round 2, the unsold tokens from the first round are made available on a FCFS basis, only to guaranteed tiers (Moon and above). These members can purchase an additional amount that is determined by a tier-based formula. This round is open until all tokens are sold, typically lasting for only a few minutes. After all the tokens are sold, the IDO is concluded.')}</p>
                        {/* <b>{t('The second round buying window opens at the same time for all members, regardless of tier level.')}</b> */}
                        {/* {t('This round is open until all tokens are sold, typically lasting for only a few minutes.  After all the tokens are sold, the IDO is concluded.')}</p> */}
                        <p className="mt-3"><b className="how-it-work-heading">{t('We will be collecting both data and feedback on the IDO structure in order to optimize the system over time as well as taking into consideration community feedback and potential DAO proposals.')}</b></p>
                        <p>{t('Our system is a predictable and provably fair system giving our users the proper incentives to accumulate and hold tokens and support each and every project launched.  Over time, we will tweak weights, add new tiers and change other parameters as necessary to keep the system functional, competitive and rewarding for all community members.')}</p>
                        <p>{t('$TRONPAD is the next evolution of blockchain launchpads solving the fundamental flaws that plague existing launchpads. This platform benefits all holders of the token and allows for fair launches giving traders of all sizes the opportunity to invest in the best upcoming  projects launching on the Tron network.')}</p></div></div>
                </div>
            </div>

        </>
    );
}
