import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OpeningProjectsComponent = (props) => {
  const { t } = useTranslation();
  // const [openProjects, setOpenProjects] = useState([]);
  // const [waitingProject, setWaitingProject] = useState([]);
  const openingProjects = useSelector((state) =>
    get(state, "project.openingProjects", [])
  );

  return (
    <div className="pp-investment pp-open">
      <div className="container text-center">
        <div className="row d-flex">
          <div className="col-lg-12 text-center">
            <div className="section-text">
              <h2 className="section-title">{t('Projects Open Now')}</h2>
              <p className="section-description"></p>
            </div>
          </div>
        </div>

        <div className="investment-section-items">
          <div className="row d-flex ">

            {openingProjects.length > 0 ?

              openingProjects.map((item, key) => {
                // if (item["contract"]) {
                //   getCountDown(`idFcfsOpenTime-${item["contract"]}-${item["fcfsOpenTime"]}`, item.fcfsOpenTime * 1000, function start(job) {

                //   }, function end(job) {

                //   })
                // }

                return (
                  <div className="col-lg-6 text-center" key={key}>
                    <div className="single-item">
                      {
                        item.athMultiplier &&
                        <div className="pp-top-tag">
                          <div><small>ATH</small></div>
                          <div><b>{item.athMultiplier}x</b></div>
                        </div>

                      }
                      <div className="pp-card-body">
                        <div className="pp-card-top">
                          <Link to={`project/${item.contract}`} >
                            <div className="icon-box">
                              <span><img src={item.logo} alt="#" /></span>
                            </div>
                          </Link>
                          <div className="title-box">
                            <h3 className="d-flex align-items-center">
                              <Link to={`project/${item.contract}`} >
                                <div>{item.name}</div>
                              </Link>
                            </h3>
                            <div className="item-social">
                              {
                                item.pancakeswap &&
                                <a style={{ backgroundColor: '#47d4dc' }} href={item.pancakeswap} target="_blank"><img height="18" src="/images/pancake-swap.png" /></a>
                              }
                              {
                                item.website &&
                                <a href={item.website} target="_blank"><i className="fas fa-globe"></i></a>
                              }
                              {
                                item.twitter &&
                                <a href={item.twitter} target="_blank"><i className="fab fa-twitter"></i></a>
                              }
                              {
                                item.medium &&
                                <a href={item.medium} target="_blank"><i className="fab fa-medium-m"></i></a>
                              }
                              {
                                item.telegram &&
                                <a href={item.telegram} target="_blank"><i className="fab fa-telegram"></i></a>
                              }
                            </div>
                            <Link className="items-morex" to={`project/${item.contract}`} >
                              {(item.state === "O" || item.state === "F") &&
                                <span className="pp-status-open">
                                  <i className="mdi mdi-circle"></i> {t('Open')}
                                {/* <b id={`idFcfsOpenTime-${item["contract"]}-${item["fcfsOpenTime"]}`}>{remainTime(item.fcfsOpenTime * 1000)}</b> */}
                                </span>
                              }
                              {/* {item.state === "F" &&
                                <span className="pp-status-open"><i className="mdi mdi-circle"></i> FCFS Round Open
                                </span>
                              } */}
                              <div><span className="">{item.symbol}</span></div>
                            </Link>
                          </div>
                        </div>
                        <div className="item-desc mb-1">
                          <div className="item-short-desc">{item.description}</div>
                          {
                            <div className="item-learn-more">{item.detail && <a target="blank" href={item.detail}>{t('Learn more')}</a>}</div>
                          }
                        </div>
                        <Link to={`project/${item.contract}`} >
                          <div className="part-prize">
                            <div className="pp-card-info mb-3">
                              <div className="pp-card-col">{t('Swap Rate')}<br /><b className="nowrap">1 {item.symbol} = {(item["rate"]/10**item.decimal)} {item["projectTokenSymbol"]}</b></div>
                              <div className="pp-card-col text-end ps-286">{t('Cap')}<br /><b>{`${item.maxTotalParticipationAllocated/10**item.decimal|| 0} ${item.symbol}`}</b></div>
                              {/* <div className="pp-card-col text-end">{t('Access')}<br /><b>{item.isPrivate ? t('Private') : t('Public')}</b></div> */}
                            </div>

                            {
                              item.state == 'O' ?
                                <div className={item.state == 'O' ? 'pp-card-progress-wrap light-progress disabled' : 'pp-card-progress-wrap'}>
                                  <div className="mb-2 d-flex justify-content-between align-items-center pp-card-progress-top">
                                    <div className="pp-card-col">{t('Progress')}</div>
                                    {item.state != 'O' && <div className="pp-card-col text-end">{t('Participants')} <b className="text-participants font-12">{item['totalCountUserParticipated']}</b></div>}
                                  </div>
                                  <div className='pp-card-progress'>
                                    <div className="pp-card-progress-percent" style={{ width: `${(item.totalFundParticipated / (item.maxTotalParticipationAllocated/10**item.decimal) || 0) * 100}%` }}></div>
                                    <div className="pp-card-progress-label">

                                      <span className="participants-center" >{t('Allocation round')}</span>
                                      <span className="participants-center" style={{ top: "8px" }}><b>{item['totalCountUserParticipated']}</b> {t('Participants')}</span>
                                    </div>
                                  </div>
                                </div>
                                :
                                <>
                                  <div className={'pp-card-progress-wrap'}>
                                    <div className="mb-2 d-flex justify-content-between align-items-center pp-card-progress-top">
                                      <div className="pp-card-col">{t('Progress')}</div>
                                      <div className="pp-card-col text-end">{t('Participants')} <b className="text-participants font-12">{item['totalCountUserParticipated']}</b></div>
                                    </div>
                                    <div className='pp-card-progress'>
                                      <div className="pp-card-progress-percent" style={{ width: `${(item.totalFundParticipated / (item.maxTotalParticipationAllocated/10**item.decimal) || 0) * 100}%` }}></div>
                                      <div className="pp-card-progress-label">
                                        <span><b>{((item.totalFundParticipated / (item.maxTotalParticipationAllocated/10**item.decimal) || 0) * 100).toFixed(2)}%</b></span>
                                        {item.state == 'O' && <span className="participants-center" style={{ top: "8px" }}><b>{item['totalCountUserParticipated']}</b> {t('Participants')}</span>}
                                        <span className="text-allocation"><b>{item.totalFundParticipated.toFixed(4)}/{item.maxTotalParticipationAllocated/10**item.decimal}</b></span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                            }

                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                )

              })

              :
              <>
                <div>
                  <span>
                    No projects currently open
                    </span>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div >
  );
};

export default OpeningProjectsComponent;
