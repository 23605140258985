import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { history } from "../../utils/history";
import { ACTION_CONST, ROUTES } from "../../constants";
import moment from 'moment'
import { BigNumber } from "bignumber.js";
import "react-block-ui/style.css";
import InvestmentModal from "./InvestModal";
import { actGetListProjects, actSelectedProject, actSetCurrentContract, actSetListContractSelected } from "../../redux/action/user";
import { getCountDown } from "../../utils/helper";
import ApproveModal from "./ApproveModal";
import { helpers } from "../../utils/helpers";
import ScheduleTabComponent from "./ScheduleTabComponent";
import YourAllocationComponent from "./YourAllocationComponent";
import { useParams } from "react-router";
import { calculateBalanceBigNumberTron } from "../../utils/utils";
import { useTranslation } from "react-i18next";
// import BlockUi from "react-block-ui";

const ProjectDetailPage = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const { params } = props.match;
  let params = useParams();
  // const showBlockUI = useSelector((state) =>
  //   get(state, "utils.blocking", false)
  // );
  const selectedProject = useSelector((state) =>
    get(state, "project.selectedProject", null)
  );
  const showModalHelp = useSelector((state) =>
    get(state, "utils.showModalHelp", false)
  );

  const isConnectWallet = useSelector((state) =>
    get(state, "utils.isConnectWallet", false)
  );

  const walletUtils = useSelector((state) =>
    get(state, "utils.walletUtils", null)
  );
  const roundInfo = useSelector((state) =>
    get(state, "project.selectedProject.infoRound", [])
  );


  const [decimal, setDecimal] = useState(6);
  const [decimals, setDecimals] = useState(18)
  // const [init, setInit] = useState(true);
  const [contractAddress, setContractAddress] = useState("");
  const [tokenBalance, setTokenBalance] = useState(0);
  const [trxBalance, setTrxBalance] = useState(0);
  const [remainingAllocation, setMaxTokenAllocation] = useState(0);
  const [userParticipation, setUserParticipation] = useState(0);
  const [amountPurchased, setAmountPurchased] = useState(0);

  const [countBuy, setCountBuy] = useState(0);
  const [enableJoinProject, setEnableJoinProject] = useState(false);
  const [enableApprove, setEnableApprove] = useState(false);
  // const []

  const [projectState, setProjectState] = useState(null);
  const [tier, setTier] = useState("");
  const [roundState, setRoundState] = useState(0);
  const [textRoundState, setTextRoundState] = useState('')
  // const [roundStateString, setRoundStateString] = useState("Allocation round closes in");
  const [roundTime, setRoundTime] = useState(0);

  const [allocationNumber, setAllocationNumber] = useState(0);
  const [allocationInfo, setAllocationInfo] = useState([])

  const [yourAllocationVisible, setYourAllocationVisible] = useState(false)

  const [init, setInit] = useState(true)
  


  const [walletInfo, setWalletInfo] = useState({
    remainingAllocation: 0,
    tokenBalance: 0,
  })

  useEffect(() => {

    const { contract } = params

    if (contract && typeof contract === "string") {

      setContractAddress(contract);
      dispatch(actSelectedProject({contractAddress:contract, walletUtils}));
      dispatch(actSetCurrentContract(contract));

    } else {
      history.push(ROUTES.HOMEPAGE);
      return;
    }
  }, [])

  useEffect(() => {

    if (contractAddress) {


      const jobGetProjectInfo =
        setInterval(() => {
          // console.log("get jobGetProjectInfo");
          dispatch(actSelectedProject({contractAddress:contractAddress, walletUtils}))
          // getRemainTime("idOpenTime", selectedProject.openTime * 1000)
        }, 30 * 1000)

      //save job in producer
      dispatch({
        type: ACTION_CONST.SET_JOB_PROJECT_SELECTED,
        data: jobGetProjectInfo
      })
    }

  }, [contractAddress])






  const [closeTime, setCloseTime] = useState(0);
  const [openTime, setOpenTime] = useState(0)
  const [fcfsOpenTime, setFcfsOpenTime] = useState(0)

  useEffect(() => {
    if (selectedProject) {
      setDecimal(selectedProject.decimal)
      console.log("selectedProject==>", selectedProject);
      if (selectedProject.closeTime !== closeTime) {

        setCloseTime(selectedProject.closeTime);
      }
      if (selectedProject.openTime != openTime) {

        setOpenTime(selectedProject.openTime)
      }
      if (selectedProject.fcfsOpenTime != fcfsOpenTime) {

        setFcfsOpenTime(selectedProject.fcfsOpenTime)
      }

      setYourAllocationVisible(selectedProject.yourAllocationVisible)
      // console.log(selectedProject);
      setProjectState(selectedProject)
    }

  }, [selectedProject]);

  useEffect(() => {

    if (openTime > 0) {
      // console.log("call here")
      getCountDown(`idOpenTime-${selectedProject["contract"]}`, openTime * 1000, (job) => {

        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_OPEN,
          data: job
        })

      }, (job) => {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject({contractAddress:contractAddress, walletUtils}))

      });
    }

  }, [openTime])

  useEffect(() => {
    if (closeTime > 0) {
      console.log("idTimeClose==>", closeTime);

      getCountDown(`idTimeClose-${selectedProject["contract"]}`, closeTime * 1000, (job) => {

        //do smt when start new count down
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_CLOSE,
          data: job
        })
      }, (job) => {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject({contractAddress:contractAddress, walletUtils}))

      });
    }
  }, [closeTime])

  useEffect(() => {
    if (fcfsOpenTime > 0) {
      console.log("fcfsOpenTime==>", fcfsOpenTime);
      getCountDown(`idFcfsOpenTime-${selectedProject["contract"]}`, fcfsOpenTime * 1000, (job) => {

        //do smt when start new count down
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_FCFS_TIME,
          data: job
        })
      }, (job) => {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject({contractAddress:contractAddress, walletUtils}))

      });
    }
  }, [fcfsOpenTime])



  //check enable disable approve button
  useEffect(() => {
    if (remainingAllocation > allocationNumber) {
      
      setEnableApprove(true)
    } else {
      setEnableApprove(false)
    }
   
  }, [allocationNumber, remainingAllocation])


  useEffect(() => {

    //round time dc chay lai khi call wallet ==> sinh ra nhieu rountime nay
    if (roundTime > 0 && selectedProject) {
      getCountDown(`idRoundTime-${selectedProject["contract"]}`, roundTime * 1000, function start(job) {
        //do smt when countdown expired
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_ROUND,
          data: job
        })

      }, function end(job) {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject({contractAddress:contractAddress, walletUtils}))
        //do smt when countdown expired
      });
    }

  }, [roundTime])


  let count = 0;
  useEffect(() => {
    setInit(false)
    if (isConnectWallet && walletUtils && projectState) {

      // console.log("count===>", count++);

      fetchData()

    }
    async function fetchData() {
      const { contract } = params

      await getWalletInfo(contract);
      await getAllowance();
      await getYourAllocation(contract)

      if (init) {

        const jobGetWalletInfo =
          setInterval(async () => {
            await getWalletInfo(contract);
            await getAllowance();
            await getYourAllocation(contract)
          }, 13 * 1000);

        dispatch({
          type: ACTION_CONST.SET_JOB_GET_WALLET_INFO,
          data: jobGetWalletInfo
        })
      }
    }

  }, [isConnectWallet, walletUtils, countBuy, contractAddress, projectState]);



  const getWalletInfo = async (contract) => {
    if (walletUtils) {
      walletUtils.getInfoWallet(contract).then(
     
        data => {
        
          console.log(projectState);
        
          setWalletInfo({
            remainingAllocation: BigNumber(data.remainingAllocation).dividedBy(10 ** projectState.decimal).toString(),
            tokenBalance: data.tokenBalance/10 ** projectState.decimal,
            trxBalance: data.trxBalance
          })

          // const temP = calculateBalanceBigNumberTron(data.tokenBalance, 6)
     
          setTrxBalance(data.trxBalance);
          setTokenBalance(data.tokenBalance / 10 ** projectState.decimal);

          setUserParticipation(BigNumber(data.userParticipation).dividedBy(10 ** projectState.decimal).toString());
          setMaxTokenAllocation(BigNumber(data.remainingAllocation).dividedBy(10 ** projectState.decimal).toString());
          setTier(data.tier);
          setRoundState(data.roundState);
          setTextRoundState(data.roundStateText)

          if (document.getElementById('idTextRoundState')) {
            document.getElementById('idTextRoundState').innerHTML = data.roundStateText;
          }


          //check 
          // setYourAllocationVisible(false)


          if (roundTime != data.roundTimestamp) {
            setRoundTime(data.roundTimestamp);
          }


          //check approve
          console.log(data.remainingAllocation);
          if (data.remainingAllocation == 0) {
            setEnableApprove(false)
          } else {
            setEnableApprove(true)
          }

          
          if (!(data.roundState == 1 || data.roundState == 3)) {
            console.log('call here state ')
            setEnableJoinProject(false);
            return;
          }


          //enable disable join button


          // console.log("projectState.state", projectState.state);
          if (projectState.state === "C" || projectState.state === "P" || projectState.address === 'TBA') {
            setEnableJoinProject(false)
            return;
          }
          else {
            if (selectedProject.isPrivate) {
              // debugger
              console.log("data.remainingAllocation ==>", data.remainingAllocation);
              if (data.remainingAllocation == 0) {

                setEnableJoinProject(false)
                return;
              } else {
                setEnableJoinProject(true)
              }
            } else {
              setEnableJoinProject(true)
              setMaxTokenAllocation(projectState.maxSingleParticipationAllocated)
              // remainingAllocation = maxSingleParticipationAllocated
            }
          }

        }
      ).catch(err => {

        console.log(err);
      })
    }

  }

  const getAllowance = async () => {
    walletUtils.getAllowance(selectedProject.tokenAddress, selectedProject.contract).then(data => {
      const allocation =  new BigNumber(data.toString())
      .dividedBy(10 ** selectedProject.decimal)
      .toString();
  
      setAllocationNumber(allocation)
      // console.log("allocationNumber==>", allocationNumber)
    }).catch(err => {
      console.log(err);
    })
  }

  const getYourAllocation = (contract) => {
    walletUtils.getInfoAllocations(contract).then(data => {
      // debugger
      setAllocationInfo(data.infoAllocation)
      setDecimals(data.decimals)
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <div className="pp-detail-page">
      {selectedProject ?
        <>

          <div className="pp-detail-banner">
            <div className="container">

              <div className="row align-items-center" >
                <div className="col-lg-6 col-md-5">
                  <div className="pp-card-top animation">
                    <div className="icon-box">
                      <span><img src={selectedProject.logo} alt={selectedProject.name} /></span>
                    </div>
                  </div>
                  <div className="ms-0">
                    <div className="pp-card-top">
                      <div className="title-box">
                        <h2>
                          {selectedProject["name"]}
                          {
                            selectedProject.pancakeswap &&
                            (<a href={selectedProject.pancakeswap} target="_blank">
                              <img height="20" src="/images/pancake-swap.png" />
                            </a>)
                          }
                          {
                            selectedProject.website &&
                            (<a href={selectedProject.website} target="_blank">
                              <i className="fas fa-globe-americas"></i>
                            </a>)
                          }

                          {
                            selectedProject.twitter &&
                            (<a href={selectedProject.twitter} target="_blank">
                              <i className="fab fa-twitter"></i>
                            </a>)
                          }
                          {
                            selectedProject.medium &&
                            (<a href={selectedProject.medium} target="_blank">
                              <i className="fab fa-medium-m"></i>
                            </a>)
                          }
                          {
                            selectedProject.telegram &&
                            (<a href={selectedProject.telegram} target="_blank">
                              <i className="fab fa-telegram-plane"></i>
                            </a>)
                          }
                        </h2>
                        <div className="items-morex">

                          {(selectedProject.state === "O" || selectedProject.state === "F") &&
                            <>
                              <span className="pp-status-open"><i className="mdi mdi-circle"></i> {t('Open')}</span>
                            </>
                          }
                          {selectedProject.state === "C" &&
                            <>
                              <span className="pp-status-closed"><i className="mdi mdi-circle"></i> {t('Closed')}</span>
                            </>

                          }
                          {
                            selectedProject.state === "P" &&
                            <>
                              {
                                selectedProject.openTime !== undefined ?
                                  <span className="pp-status-opening"><i className="mdi mdi-circle"></i> {t('Opens in')} <b id={`idOpenTime-${selectedProject["contract"]}`}>0d 0h 0m 0s</b></span>
                                  : <span className="pp-status-opening"><i className="mdi mdi-circle"></i>{t('TBA')}</span>
                              }
                            </>
                          }
                          <div><span className="">{selectedProject.symbol}</span></div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <p className="text-muted">
                        {selectedProject.description}
                      </p>
                      {/* <h6>{selectedProject["contract"]}</h6> */}
                    </div>
                    <div>
                      {
                        !isConnectWallet ?
                          <button type="button" className="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target={showModalHelp ? "#helpModal" : "#connectWalletModal"}><span>{t('Connect Wallet')}</span></button>
                          :
                          <>
                            <button type="button" className="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#approveModal" disabled={!enableApprove || projectState.state=="C"}><span>{t('Approve')}</span></button>
                            <button type="button" className="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#buyModal" disabled={!enableJoinProject}><span>{t('Join Pool')}</span></button>
                          </>
                      }


                      {/* <a href={`${TRON_EXPLORER}/address/${selectedProject.contract}`} target="blank" className="btn btn-outline-primary">View BSC</a> */}
                    </div>
                    <div>

                    </div>
                  </div>
                </div>


                {selectedProject.state !== "P" &&
                  <div className="col-lg-6 col-md-7">
                    <div className="card card-img-holder bg-gradient-danger mx-auto mx-lg-0">
                      <div className="card-body px-3 py-2">

                        {isConnectWallet &&
                          <>
                            <div className="pp-card-info mt-2">
                              <div className="pp-card-col">
                                {t('Your balance')}<br />
                                <div className="d-flex justify-content-between align-items-center">
                                  <h4 className="mb-0"><strong id="idBusdBalance">{helpers.formatNumberDownRoundWithExtractMax(Number(tokenBalance), 4)}&nbsp;{selectedProject.symbol}</strong></h4>
                                  {/* <h6 id="idBusdConvert">1 {selectedProject.symbol} = {selectedProject.rate} {selectedProject.projectTokenSymbol}</h6> */}
                                </div>
                                {selectedProject.symbol !== "TRX" &&
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="mb-0"><strong id="idtrxBalance">{helpers.formatNumberDownRoundWithExtractMax(Number(trxBalance), 4)}&nbsp;TRX</strong></h4>
                                  </div>
                                }
                                <div className="pp-card-col">
                                  {t('Your approved amount')}:<br />
                                  <b >{helpers.formatNumberDownRoundWithExtractMax(Number(allocationNumber), 4)}&nbsp;{selectedProject.symbol}</b>
                                </div>


                              </div>
                              <div className="pp-card-col">
                                {t('Your tier')}<br />
                                <h4 className="mb-0">{tier}</h4>
                              </div>
                            </div>
                            <hr className="mb-2" />
                          </>
                        }

                        {/* If not connect show open time  else show round time.(if state = 4 ==> show closes)*/}

                        {
                          (selectedProject.state == "O") &&
                          <div className="pp-card-info mt-2">
                            {isConnectWallet ?
                              roundState !== 4 ?
                                <div className="pp-card-col w-200px">
                                  <span id="idTextRoundState"> {textRoundState}</span>

                                  <br />
                                  <b id={`idRoundTime-${selectedProject["contract"]}`}> 0d 0h 0m 0s</b>
                                </div> :
                                <div>{t('ROUND CLOSED')}</div>
                              :
                              <div className="pp-card-col w-200px">{t('First Come First Serve')} <u>{t('Opens')}</u> in:<br />
                                <b id={`idFcfsOpenTime-${selectedProject["contract"]}`}> 0d 0h 0m 0s</b>
                              </div>
                            }
                          </div>
                        }

                        {
                          selectedProject.state == "F" &&
                          <div className="pp-card-info mt-2">
                            {isConnectWallet ?
                              roundState !== 4 ?
                                <div className="pp-card-col w-200px">
                                  <span id="idTextRoundState"> {textRoundState}</span>

                                  <br />
                                  <b id={`idRoundTime-${selectedProject["contract"]}`}> 0d 0h 0m 0s</b>
                                </div> :
                                <div>{t('ROUND CLOSED')}</div>
                              :
                              <div className="pp-card-col w-200px">{t('Closing in')}:<br />
                                <b id={`idTimeClose-${selectedProject["contract"]}`}> 0d 0h 0m 0s</b>
                              </div>
                            }
                          </div>
                        }

                        {
                          selectedProject.state == "C" &&
                          <div className="pp-card-info mt-2">
                            <div>{t('CLOSED')}</div>
                          </div>
                        }


                        {
                          isConnectWallet &&
                          <>
                            <hr className="mb-2 mt-2" />
                            <div className="pp-card-info">
                              <div className="d-flex justify-content-between w-100">
                                <div className="pp-card-col">
                                  {t('Swapped')}<br />
                                  <b id="idUseParticipation">{helpers.formatNumberDownRoundWithExtractMax(Number(userParticipation), 4)}&nbsp;{selectedProject.symbol}</b>
                                  <div>
                                    <span> {`
                                    ${helpers.formatNumberDownRoundWithExtractMax(Number(userParticipation * selectedProject.rate)/10**decimal, 4)} ${selectedProject.projectTokenSymbol}`}
                                    </span>
                                  </div>
                                </div>
                                <div className="pp-card-col w-200px">
                                  {t('Remaining Allocation')}:<br />
                                  <b id="idBusdMaxBuy">{helpers.formatNumberDownRoundWithExtractMax(Number(remainingAllocation), 4)}&nbsp;{selectedProject.symbol}</b>
                                </div>
                              </div>
                            </div>
                          </>
                        }
                        <hr className="mb-2 mt-2" />
                        <div className="pp-card-info">

                          <div className="d-flex justify-content-between w-100">

                            {/* Progress bar */}

                            {selectedProject.state !== "O" ?
                              <div className="pp-card-col w-200px">
                                {t('Swap progress')}<br />
                                <div className="pp-card-progress">
                                  <div className="pp-card-progress-percent" style={{ width: `${(selectedProject.totalFundParticipated / (selectedProject.maxTotalParticipationAllocated/10**decimal) || 0) * 100}%` }}></div>
                                  <div className="pp-card-progress-label">
                                    <span><b>{((selectedProject.totalFundParticipated / (selectedProject.maxTotalParticipationAllocated / 10**decimal)|| 0) * 100).toFixed(2)}%</b></span>
                                    <span>{selectedProject.totalFundParticipated.toFixed(4)}/{selectedProject.maxTotalParticipationAllocated/ 10**decimal} {selectedProject.symbol}</span>
                                  </div>
                                </div>
                              </div>
                              :
                              <div className="pp-card-col w-200px pp-card-progress-wrap light-progress disabled">
                                <div className="pp-card-progress text-center">
                                  <div className="pp-card-progress-percent pp-card-progress-percent-card"></div>
                                  <div className="pp-card-progress-label">
                                    <span className="participants-center" >{t('Allocation round')}</span>
                                    <span className="participants-center" style={{ top: "8px" }}>{selectedProject.totalCountUserParticipated} {t('Participants')}</span>
                                    {/* <span className="text-allocation">{selectedProject.totalFundParticipated.toFixed(4)}/{selectedProject.maxTotalParticipationAllocated} {selectedProject.symbol}</span> */}
                                  </div>

                                </div>
                              </div>
                            }

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                }



                {selectedProject.state === "P" && isConnectWallet &&
                  <div className="col-lg-6 col-md-7">
                    <div className="card card-img-holder bg-gradient-danger mx-auto mx-lg-0">
                      <div className="card-body py-3 px-3 px-md-4">

                        {isConnectWallet &&
                          <div className="pp-card-info mt-2">
                            <div className="pp-card-col">
                              {t('Your balance:')}<br />
                              {
                                selectedProject.openTime !== undefined ?
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h4 className="mb-0"><strong id="idBusdBalance">
                                      {helpers.formatNumberDownRoundWithExtractMax(Number(tokenBalance), 4) || 0}
                                      {selectedProject.symbol}
                                    </strong></h4>

                                    {
                                      selectedProject.state !== "P" &&
                                      <h6 id="idBusdConvert">1 {selectedProject.symbol} = {selectedProject.rate/10**selectedProject.decimal} {selectedProject.projectTokenSymbol}</h6>
                                    }
                                  </div>
                                  : <div></div>
                              }

                              {selectedProject.symbol !== "BNB" &&
                                <div className="d-flex justify-content-between align-items-center">
                                  <h4 className="mb-0"><strong id="idtrxBalance">{helpers.formatNumberDownRoundWithExtractMax(Number(trxBalance), 4) || 0} TRX</strong></h4>
                                </div>
                              }

                            </div>
                          </div>
                        }

                      </div>
                    </div>
                  </div>

                }

              </div>
            </div>
          </div>

          <div className="pp-detail-content pt-1 pb-5">
            <div className="container">
              <ul className="nav nav-tabs text-end" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="home-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="home" aria-selected="true"
                    data-bs-target="#home"
                  >
                    {t('Project details')}
                  </button>
                </li>
                <li className="">
                  <button className="nav-link" id="schedule-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="schedule"
                    data-bs-target="#schedule"
                    aria-selected="true">
                    {t('Schedule')}
                  </button>
                </li>
                {yourAllocationVisible &&
                  <li className="">
                    <button className="nav-link" id="allocation-tab" data-bs-toggle="tab" type="button" role="tab" aria-controls="allocation"
                      data-bs-target="#allocation"
                      aria-selected="true">
                      {t('Your Allocation')}
                  </button>
                  </li>
                }

              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div className="py-3">

                    <div className="row">
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-header">{t('Pool information')}</div>
                          <div className="card-body p-0">
                            <div className="table-responsive">
                              <table className="table mb-0 pp-table-info">
                                <tbody>

                                  <tr>
                                    <td>{t('Opens')}</td>
                                    {
                                      selectedProject.openTime == undefined ?
                                        <td className="text-right">{t('TBA')}</td>
                                        : <td className="text-right">{moment(selectedProject.openTime * 1000 || 0).utc().format('YYYY-MM-DD HH:mm:ss')} {t('UTC')} </td>
                                    }
                                  </tr>
                                  <tr>
                                    <td>{t('FCFS Opens')}</td>
                                    {
                                      selectedProject.fcfsOpenTime == undefined ?
                                        <td className="text-right">{t('TBA')}</td>
                                        : <td className="text-right">{moment(selectedProject.fcfsOpenTime * 1000 || 0).utc().format('YYYY-MM-DD HH:mm:ss')} {t('UTC')} </td>
                                    }
                                  </tr>
                                  <tr>
                                    <td>{t('Closes')}</td>
                                    {
                                      selectedProject.closeTime == undefined ?
                                        <td className="text-right">{t('TBA')}</td>
                                        : <td className="text-right">{moment(selectedProject.closeTime * 1000 || 0).utc().format('YYYY-MM-DD HH:mm:ss')} {t('UTC')}</td>
                                    }
                                  </tr>
                                  <tr>
                                    <td>{t('Swap Rate')}</td>
                                    {
                                      selectedProject.openTime !== undefined ?
                                        <td><span style={{ textTransform: 'uppercase' }} id="idBusdConvert">1 {selectedProject.symbol} = {selectedProject.rate.toFixed(4)/10**decimal} {selectedProject.projectTokenSymbol}</span></td>
                                        : <td><span style={{ textTransform: 'uppercase' }} id="idBusdConvert">{t('TBA')}</span></td>
                                    }
                                  </tr>

                                  <tr>
                                    <td>{t('Cap')}</td>
                                    {
                                      selectedProject.openTime !== undefined ?
                                        <td><span style={{ textTransform: 'uppercase' }}>{selectedProject.maxTotalParticipationAllocated/10**decimal} {selectedProject.symbol}</span></td>
                                        : <td><span style={{ textTransform: 'uppercase' }}>{t('TBA')}</span></td>
                                    }
                                  </tr>
                                  <tr>
                                    <td>{t('Total Users Participated')}</td>
                                    <td className="text-right">{selectedProject.totalCountUserParticipated || 0} </td>
                                  </tr>
                                  <tr>
                                    <td>{t('Total Funds Swapped')}</td>
                                    {
                                      selectedProject.openTime !== undefined ?
                                        <td className="text-right">{selectedProject.totalFundParticipated.toFixed(4) || 0} {selectedProject.symbol || ""}</td>
                                        : <td className="text-right">0</td>
                                    }

                                  </tr>
                                  <tr>
                                    <td>{t('Access Type')}</td>
                                    <td className="text-right">{selectedProject.isPrivate ? t('Private') : t('Public')}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mt-4 mt-md-0">
                        <div className="card">
                          <div className="card-header">{t('Token information')}</div>
                          <div className="card-body p-0">
                            <div className="table-responsive">
                              <table className="table mb-0 pp-table-info">
                                <tbody>
                                  <tr>
                                    <td>{t('Name')}</td>
                                    <td className="text-right">{selectedProject.name}</td>
                                  </tr>
                                  <tr>
                                    <td>{t('Token Symbol')}</td>
                                    <td className="text-right">{selectedProject.projectTokenSymbol}</td>
                                  </tr>
                                  {/* <tr>
                                    <td>{t('Total Supply')}</td>
                                    <td className="text-right">{selectedProject.totalSupply || 0}</td>
                                  </tr> */}
                                  <tr>
                                    <td><br /></td>
                                    <td></td>
                                    
                                  </tr>
                                  <tr>
                                    <td><br /></td>
                                    <td></td>
                                    
                                  </tr>
                                  <tr>
                                    <td>
                                      {/* Token Distribution */}
                                      <br />
                                    </td>
                                    <td className="text-right">
                                      {/* {moment(new Date()).utc().format('YYYY-MM-DD HH:mm:ss')} UTC */}
                                    </td>
                                    {/* <td className="text-right">{new Date(selectedProject.openTime * 1000).toISOString()}</td> */}
                                  </tr>
                                  <tr>
                                    <td><br /></td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td><br /></td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td><br /></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <ScheduleTabComponent roundInfo={roundInfo} />

                {yourAllocationVisible &&

                  <YourAllocationComponent
                    allocationInfo={allocationInfo}
                    tokenSymbol={selectedProject.projectTokenSymbol}
                    decimals={projectState.decimals || 18}
                    contractAddress={contractAddress}
                    tokenAddress={projectState.projectTokenContract}
                    handleBuyClick={() => setCountBuy(countBuy + 1)}

                  />
                }

              </div>
            </div>
          </div>

        </> :
        <>

          <div className="pp-detail-banner">
            <div className="container">

              <div className="row align-items-center" >

                <div className="col-lg-12 col-md-12">
                  <div className="card card-img-holder bg-gradient-danger mx-auto mx-lg-0">
                    <div className="card-body py-3 px-3 px-md-4 text-center">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </>
      }

      <InvestmentModal walletInfo={walletInfo}
        allocationNumber={allocationNumber} handleBuyClick={() => setCountBuy(countBuy + 1)}
        countClick={countBuy}
        tokenBalance={tokenBalance}
        symbol={selectedProject?.symbol}
        handleInputAmount={(n) => setAmountPurchased(n)} />

      <ApproveModal walletInfo={walletInfo}
        handleBuyClick={() => setCountBuy(countBuy + 1)}
        handleInputAmount={(n) => setAmountPurchased(n)}
        tokenBalance={tokenBalance}
        symbol={selectedProject?.symbol} />


    </div>
  );
};

export default ProjectDetailPage;
