import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery';
import { get } from "lodash";
import { Link } from "react-router-dom";
import { ACTION_CONST, MESSAGES, ROUTES, STATUS } from "../../constants";
import { EXPLORER,MODE } from "../../_configs";
import { Button } from "react-bootstrap";
import { BigNumber } from "bignumber.js";
import { helpers } from "../../utils";
import { useTranslation } from "react-i18next";
// import AOS from 'aos';
// import 'aos/dist/aos.css';

const UnStakingTab = (props) => {
  const { t } = useTranslation();
    const dispatch = useDispatch();
    const [unStakeCurrentStep, setUnStakeCurrentStep] = useState(1);
    const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));
    const walletUtils = useSelector((state) => get(state, "utils.walletUtils", null));
    const stakingInfo = useSelector((state) => get(state, "wallet.stakingInfo", {}));
    const stakingWalletInfo = useSelector((state) => get(state, "wallet.stakingWalletInfo", {}));
    const trxBalance = useSelector((state) => get(state, "wallet.trxBalance", 0));


    const [acceptTerm, setAcceptTerm] = useState(false);
    const [enableUnStakeBtn, setEnableUnStakeBtn] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    const [unStakeAmount, setUnStakeAmount] = useState('0');
    const [isMaxAmount, setIsMaxAmount] = useState(false);

    const [isSubmitOK, setIsSubmitOK] = useState(false);

    useEffect(() => {

        setUnStakeStepActive();

    }, [unStakeCurrentStep])



    //enable unstake button
    useEffect(() => {
        if (unStakeCurrentStep === 2) {
            if (acceptTerm &&
                isConnectWallet &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(stakingWalletInfo["stakedAmount"]) > 0 &&
                trxBalance > 0 &&
                stakingWalletInfo["withdrawTimestamp"] === 0
            ) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
        if (unStakeCurrentStep === 1) setEnableUnStakeBtn(true);
    }, [acceptTerm, isConnectWallet, trxBalance, stakingInfo, stakingWalletInfo, unStakeCurrentStep])


    const unStakeBack = () => {
        if (unStakeCurrentStep === 1) {
            return;
        } else {
            setUnStakeCurrentStep(unStakeCurrentStep - 1);
        }
    }

    const unStakeNext = () => {
        if (unStakeCurrentStep === 1) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 2 && parseFloat(unStakeAmount) === 0) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 4) {
            if (walletUtils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                walletUtils.stakingInitiateWithdrawal({ amount: isMaxAmount ? stakingWalletInfo["stakedAmount"] : unStakeAmount }, data => {
                    // console.log(data);
                    if (data.status ===STATUS.STAKING_INITIATE_WITHDRAWAL_SUCCESS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true);
                        setTransactionHash(data.txID);

                        setUnStakeCurrentStep(5);

                    }
                    if (data.status === STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })

                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: t(MESSAGES.INIT_UN_STAKING_FAIL)
                        })
                    }
                })
            }
        } else {
            if (unStakeCurrentStep === 5) {
                return;
            } else {
                setUnStakeCurrentStep(unStakeCurrentStep + 1);
            }
        }
    }

    const handleInputUnStake = (e) => {
        setIsMaxAmount(false);

        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setUnStakeAmount(e.target.value);

            //check input
            if (BigNumber(e.target.value).lte(BigNumber(stakingWalletInfo["stakedAmount"])) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
    }

    const handleMaxButtonClick = () => {
        setUnStakeAmount(helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 4));
        setEnableUnStakeBtn(true);
        setIsMaxAmount(true);
    }


    const submitDone = () => {
        setUnStakeAmount('0');
        setIsMaxAmount(false);
        setUnStakeCurrentStep(1);
        setIsSubmitOK(false);
    }


    function setUnStakeStepActive() {
        $('#unStake .bs-stepper-header .step').removeClass('active');
        $('#unStake .bs-stepper-header .line').removeClass('active');
        for (var i = 1; i <= 5; i++) {
            if (i <= unStakeCurrentStep) {
                $('#unStake #unStakeButtonStep' + i).addClass('active');
                $('#unStake #unStakeLineStep' + (i - 1)).addClass('active');
            }
        }
        $('#unStake .bs-stepper-content').hide();
        $('#unStake #UnStakeStep' + unStakeCurrentStep).show();
    }

    return (

        <div className="tab-pane fade" id="unStake" role="tabpanel" aria-labelledby="unStake-tab">
            <div className="mt-3 mb-4 d-flex justify-content-center align-items-center">
                <h3 className="p-tab-title mb-0">{t('Unstake your TRONPAD')}</h3>
            </div>
            <div className="row justify-content-center mb-4">
                <div className="col-lg-6">
                    <div className="bs-stepper w-100">
                        <div className="bs-stepper-header" role="tablist">
                            {/* your steps here */}
                            <div className="step active" id="unStakeButtonStep1">
                                <button type="button" className="step-trigger">
                                    <span className="bs-stepper-circle"><i className="mdi mdi-exclamation" /></span>
                                    <span className="bs-stepper-label">{t('Warning')}</span>
                                </button>
                            </div>
                            <div className="line" id="unStakeLineStep1" />
                            <div className="step" id="unStakeButtonStep2">
                                <button type="button" className="step-trigger">
                                    <span className="bs-stepper-circle"><i className="mdi mdi-format-list-checkbox" /></span>
                                    <span className="bs-stepper-label">{t('Checkpoints')}</span>
                                </button>
                            </div>
                            <div className="line" id="unStakeLineStep2" />
                            <div className="step" id="unStakeButtonStep3">
                                <button type="button" className="step-trigger">
                                    <span className="bs-stepper-circle"><i className="mdi mdi-currency-usd" /></span>
                                    <span className="bs-stepper-label">{t('Amount to Unstake')}</span>
                                </button>
                            </div>
                            <div className="line" id="unStakeLineStep3" />
                            <div className="step" id="unStakeButtonStep4">
                                <button type="button" className="step-trigger">
                                    <span className="bs-stepper-circle"><i className="mdi mdi-currency-usd" /></span>
                                    <span className="bs-stepper-label">{t('Initialize Unstake')}</span>
                                </button>
                            </div>
                            <div className="line" id="unStakeLineStep4" />
                            <div className="step" id="unStakeButtonStep5">
                                <button type="button" className="step-trigger">
                                    <span className="bs-stepper-circle"><i className="mdi mdi-check" /></span>
                                    <span className="bs-stepper-label">{t('Confirmation')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <div className="p-sidebar-card p-sidebar-card-steps">
                        <div className="p-sidebar-card-body">
                            <div className="bs-stepper-content" id="UnStakeStep1">
                                <div className="warning d-flex justify-content-center">
                                    <div><i className="fas fa-exclamation-triangle fa-4x"></i></div>
                                    <div>
                                        <p>{t('After Unstaking, you must wait 7 days before you can withdraw your TRONPAD and rewards.')}</p>
                                        <p>{t('The amount of tokens you Unstake will not count towards your tier level for upcoming')} <Link target="_blank" className="text-danger" to={ROUTES.PROJECTS} >{t('Projects')}</Link>.</p>
                                    </div>

                                </div>
                            </div>
                            <div className="bs-stepper-content" id="UnStakeStep2">
                                <div className="text-center">
                                    <h4>{t('Checkpoints')}</h4>
                                    <p>{t('The following conditions must be met to proceed:')}</p>
                                </div>
                                <div className="row mt-4 d-flex justify-content-center">
                                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                                        <div className={isConnectWallet ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                            <div className="p-select-card-title"><b>{t('Connected with TronLink')}</b></div>
                                            <div className="p-select-card-description">{t('If not connected, click the "Connect Wallet" button in the top right corner')}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                                        <div className={parseFloat(stakingWalletInfo["stakedAmount"]) > 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                            <div className="p-select-card-title"><b>{t('Have an active TRONPAD stake')}</b></div>
                                            <div className="p-select-card-description">{t('You currently have')} {helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 2)} {t('TRONPAD staked')}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                                        <div className={trxBalance > 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                            <div className="p-select-card-title"><b>{t('TRX available in wallet')}</b></div>
                                            <div className="p-select-card-description">
                                                {t('TRX is required to pay transaction fees on the Tron network.')}<br />
                                                {t('TRX Balance:')} {helpers.formatNumberDownRoundWithExtractMax(trxBalance, 4)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                                        <div className={stakingWalletInfo["withdrawTimestamp"] === 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                            <div className="p-select-card-title"><b>{t('Eligible to initiate unstake')}</b></div>
                                            <div className="p-select-card-description">{t('You cannot unstake if you already have an active TRONPAD unstake/withdrawal request')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 text-center">
                                    <div className="form-check">
                                        <input className="form-check-input float-none me-1"

                                            type="checkbox" defaultValue id="flexCheckDefault"
                                            onChange={() => setAcceptTerm(!acceptTerm)} />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            {t('I have read the')}&nbsp;
                                            <Link target="_blank" className="text-danger" to={ROUTES.TERMS_OF_USE}>{t('Terms and Conditions')} </Link>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="bs-stepper-content" id="UnStakeStep3">
                                <div className="text-center">
                                    <h4>{t('Please enter the amount of TRONPAD you want to unstake')}</h4>
                                    <div className="mx-auto text-start mt-5" style={{ maxWidth: '370px' }}>
                                        <div className="p-form-group mb-1">
                                            <label className="form-label p-main-text">{t('Amount')}</label>
                                            <div className="p-input-group">
                                                <input type="number" className="form-control px-0" placeholder={0.0} value={unStakeAmount} onChange={(e) => handleInputUnStake(e)} />
                                                <Button
                                                    onClick={handleMaxButtonClick}
                                                >
                                                    {t('MAX')}
                                                </Button>
                                            </div>
                                        </div>
                                        {/* <div className="d-flex align-items-start justify-content-between">
                                            <div className="font-14">Balance: <b className="text-danger">{(bsbPadBalance || 0).toFixed(4)}</b></div>
                                            <div />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="bs-stepper-content" id="UnStakeStep4">
                                <div className="text-center">
                                    <h4>{t('Confirm Unstaking Process')}</h4>
                                    <p>{t('In this step, you initiate the unstaking process. After a 7 day waiting period, you will be allowed to withdraw your TRONPAD')}</p>
                                </div>
                            </div>
                            <div className="bs-stepper-content" id="UnStakeStep5">
                                <div className="text-center">
                                    <div className="text-success">
                                        <i className="mdi mdi-check" style={{ fontSize: '50px' }} />
                                    </div>
                                    <h4>{t('Confirmed')}</h4>
                                    <p>
                                        {t('You have initiated the unstaking process and started the 7 day timer.')}<br />
                                        {t('If desired, you may check Tron to confirm the transaction.')}
                                    </p>
                                    <p><a className="p-address" href={`${EXPLORER[MODE]}/transaction/${transactionHash}`} target="blank">{transactionHash}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-3 mt-4 text-center step-buttons">
                {!isSubmitOK ?
                    <>
                        <button onClick={() => unStakeBack()} type="button" disabled={!enableUnStakeBtn || unStakeCurrentStep === 1} className="btn btn-primary btn-round btn-back-step me-3 px-5"><i className="mdi mdi-arrow-left me-2" /><span>{t('Previous')}</span></button>
                        <button onClick={() => unStakeNext()} type="button" disabled={!enableUnStakeBtn || unStakeCurrentStep === 5} className="btn btn-primary btn-round btn-next-step px-5"><span>{t('Next')}</span><i className="mdi mdi-arrow-right ms-2" /></button>
                    </>
                    :
                    <>
                        <button onClick={() => submitDone()} type="button" className="btn btn-primary btn-round  me-3 px-5"><span>{t('Done')}</span></button>
                    </>
                }

            </div>
        </div>


    );
};

export default UnStakingTab;
