import React from "react";
import { ROUTES } from "../../constants";
import { Link } from "react-router-dom";
import './style.css';

const PrivacyPolicyPage = (props) => {
  return (
    <>
      <div className="container py-5">
        <div className="d-flex align-items-start tab-term">
          <div className="nav nav-pills-policy flex-column nav-pills me-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <Link to={ROUTES.TERMS_OF_USE} className="nav-link text-start" id="v-pills-terms-tab">Terms of Use </Link>
            <Link to={ROUTES.PRIVACY_POLICY} className="nav-link text-start active" id="v-pills-privacy-policy-tab">Privacy Policy</Link>
            <Link to={ROUTES.COOKIE_POLICY} className="nav-link text-start" id="v-pills-cookies-policy-tab">Cookies Policy</Link>
            {/* <Link to={ROUTES.STAKING_POLICY} className="nav-link text-start" id="v-pills-staking-policy-tab">Staking Policy</Link>
            <Link to={ROUTES.CLICKWRAP_ACCEPTANCE} className="nav-link text-start" id="v-pills-clickwrap-acceptance-tab">Clickwrap Acceptance</Link> */}
          </div>
          <div className="tab-content" id="v-pills-policy-tabContent">
            <div className="tab-pane fade show active text-start" id="v-pills-cookies-policy" role="tabpanel" aria-labelledby="v-pills-cookies-policy-tab">
              <div className="policy-container">
                <p className="c42" id="h.gjdgxs">
                  <span className="c78 c18 c61 c28">TRONPAD DIGITAL LIMITED</span>
                </p>
                <p className="c82" id="h.30j0zll">
                  <span className="c18 c28">Privacy Policy of </span>
                  <span className="c8 c18 c28">
                    <a
                      className="c51"
                      href="https://www.google.com/url?q=http://tronpad.network/&sa=D&source=editors&ust=1632073591369000&usg=AOvVaw3U6LGRvo2QfyO0LTiqiLAL"
                    >
                      http://tronpad.network/
                    </a>
                  </span>
                  <span className="c18 c61 c28 c78">&nbsp;</span>
                </p>
                <p className="c32">
                  <span className="c28">Last revised Date 4</span>
                  <span className="c6">th</span>
                  <span className="c28">&nbsp;September 2021</span>
                  <sup className="c6">
                    <a href="#ftnt1" id="ftnt_ref1" onClick={(e) => e.preventDefault()}>
                      [1]
                    </a>
                  </sup>
                </p>
                <p className="c25 c62">
                  <span className="c18 c49" />
                </p>
                <h1 className="c2">
                  <span className="c12">Introduction</span>
                </h1>
                <p className="c36">
                  <span>
                    This Privacy Policy describes how TRXPad Digital Limited (herein referred
                    to as “
                  </span>
                  <span className="c18">TRXPAD</span>
                  <span>”, “</span>
                  <span className="c18">We</span>
                  <span>”, “</span>
                  <span className="c18">Our</span>
                  <span>”, and “</span>
                  <span className="c18">Us</span>
                  <span>
                    ”) collects, uses, processes, discloses, shares, transfers, and protects
                    the information You provide on{" "}
                  </span>
                  <span className="c8">
                    <a
                      className="c51"
                      href="https://www.google.com/url?q=http://tronpad.network/&sa=D&source=editors&ust=1632073591371000&usg=AOvVaw0CUBXolfHCL7N6whUp4xB7"
                    >
                      http://tronpad.network/
                    </a>
                  </span>
                  <span>&nbsp;(“</span>
                  <span className="c18">TRXPAD website</span>
                  <span>
                    ”). This Privacy Policy constitutes a part of the T&amp;Cs of the TRXPAD
                    website ("
                  </span>
                  <span className="c18">T&amp;Cs</span>
                  <span className="c1">
                    "). The terms used in this Privacy Policy will share the same definition
                    as those stipulated in the T&amp;Cs except for others indicated herein.
                  </span>
                </p>
                <p className="c66">
                  <span className="c1">
                    We respect and value your privacy as well as the integrity of the
                    information You have provided and/or permitted Us to collect. Thus, We
                    endeavour to follow industry standards to ensure the security and
                    confidentiality of your Personal Information when You use Our Services or
                    access TRXPAD Platform.
                  </span>
                </p>
                <p className="c36">
                  <span className="c1">
                    When accessing, connecting, using, clicking on, or engaging in any
                    Services provided on TRXPAD Platform, You are strongly advised to
                    carefully read, acknowledge, understand, and accept all terms stipulated
                    in this Privacy Policy. We will not use your information, in any shape or
                    form, for any other purpose that has not been indicated in this Privacy
                    Policy or the T&amp;Cs without providing You prior notification or
                    obtaining consent from You.
                  </span>
                </p>
                <p className="c48">
                  <span>
                    This Privacy Policy has incorporated several provisions from the General
                    Data Protection Regulation ("
                  </span>
                  <span className="c18">GDPR</span>
                  <span>
                    "), specifically following its Personal Information Processing rules
                    within the European Economic Area (“
                  </span>
                  <span className="c18">EEA</span>
                  <span className="c1">”).</span>
                </p>
                <p className="c83">
                  <span className="c1">
                    This Privacy Policy will inform You about the following matters among
                    several others:
                  </span>
                </p>
                <ul className="c10 lst-kix_list_1-1 start">
                  <li className="c3">
                    <span className="c1">Acceptance of the Privacy Policy</span>
                  </li>
                  <li className="c3">
                    <span className="c1">Our Relationship with You</span>
                  </li>
                  <li className="c3">
                    <span className="c1">Personal Information that We collect</span>
                  </li>
                  <li className="c3">
                    <span className="c1">How We collect Personal Information</span>
                  </li>
                  <li className="c3">
                    <span className="c1">How We use Personal Information</span>
                  </li>
                  <li className="c3">
                    <span className="c1">How We protect Personal Information</span>
                  </li>
                  <li className="c3">
                    <span className="c1">How long We retain Personal Information</span>
                  </li>
                  <li className="c3">
                    <span className="c1">Cookies</span>
                  </li>
                  <li className="c3">
                    <span className="c1">Sharing your Personal Information</span>
                  </li>
                  <li className="c3">
                    <span className="c1">Third-Party Collection of Personal Information</span>
                  </li>
                  <li className="c3">
                    <span className="c1">Designated Countries and Your Legal Rights</span>
                  </li>
                  <li className="c3">
                    <span className="c1">Rights of the residents of the EEA</span>
                  </li>
                  <li className="c3">
                    <span className="c1">Cross-Border Transfer of Personal Information </span>
                  </li>
                  <li className="c3">
                    <span className="c1">Limitations</span>
                  </li>
                  <li className="c3">
                    <span className="c1">Updates or amendments to the Privacy Policy</span>
                  </li>
                  <li className="c3">
                    <span className="c1">How to contact TRXPAD</span>
                  </li>
                </ul>
                <h1 className="c2">
                  <span className="c12">1. Acceptance of the Privacy Policy</span>
                </h1>
                <p className="c44">
                  <span className="c1">
                    By accessing, connecting, clicking on, using, or engaging in Our Services,
                    You indicate and confirm that You have consented and agreed to the terms
                    of this Privacy Policy. We will not use your Personal Information for any
                    other purpose that has not covered in this Privacy Policy or the T&amp;Cs
                    without providing You prior notification or obtaining your consent.
                  </span>
                </p>
                <p className="c4">
                  <span className="c1">
                    If You do not agree, either partially or wholly, to this Privacy Policy,
                    You must immediately discontinue accessing, connecting, using, or engaging
                    in Our Services and TRXPAD Platform. This Privacy Policy does not apply to
                    services offered by other companies or service providers or other websites
                    linked from Our Platform.{" "}
                  </span>
                </p>
                <h1 className="c43">
                  <span className="c12">2. Our Relationship with You</span>
                </h1>
                <p className="c77">
                  <span className="c1">
                    TRXPAD has the relationship with individuals on its Services as described
                    below:
                  </span>
                </p>
                <p className="c27">
                  <span className="c1">
                    “User” is an individual providing personal information to Us via Our
                    TRXPAD website, Medium pages, social media accounts, or other means. For
                    example, User can engage and complete additional TRXPAD social media
                    engagement tasks, to entitle for add up to the whitelist for each IDO
                    fundraising event, or interact with Us on Our social media accounts.{" "}
                  </span>
                </p>
                <p className="c27">
                  <span>Hereinafter, We may refer to User as “</span>
                  <span className="c18">You</span>
                  <span className="c1">” in this Privacy Policy. </span>
                </p>
                <h1 className="c2">
                  <span className="c12">3. Personal Information that We collect</span>
                </h1>
                <p className="c4">
                  <span className="c1">
                    For the purposes of this Privacy Policy, Personal Information is
                    information that can be used to identify, describe, relate, or associate
                    with a particular individual. The following are the types of information
                    We collect, store, and process when You use Our services:
                  </span>
                </p>
                <h2 className="c38">
                  <span className="c1">
                    3.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information provided by
                    You when connecting with TRXPAD and for KYC purpose
                  </span>
                </h2>
                <p className="c20">
                  <span>
                    Prior to connecting or participating in TRXPAD Platform to use Our
                    Services, You must provide Us with your full name, birthday date,
                    nationality, home address, location, government identification number
                    (Identification Card/Passport Number and Date of the Issuance of
                    Identification Card/Passport), telegram username, digital wallet address,
                    email address,
                  </span>
                  <span className="c34">&nbsp;</span>
                  <span>
                    IP address, documentation for proof of address and other information as We
                    required which can be used to distinguish your identity, to access the
                    TRXPAD Platform and for the Know Your Customer (“
                  </span>
                  <span className="c18">KYC</span>
                  <span>
                    ”) Verification purpose. Additionally, You will use single sign – on (also
                    known as “
                  </span>
                  <span className="c18">OAuth</span>
                  <span className="c1">
                    ”) by logging in to Our Services using Metamask. These services will
                    authenticate your identity and provide You the option to share certain
                    information with Us such as your username and email address.{" "}
                  </span>
                </p>
                <p className="c20">
                  <span className="c1">
                    Furthermore, You must be required to upload facial image data, namely
                    photo image of your face, provide additional information when firstly
                    connecting with TRXPAD such as linking to your Facebook account, Telegram,
                    LinkedIn, GitHub, Google, or Twitter of other social media accounts, and
                    more.{" "}
                  </span>
                </p>
                <h2 className="c38">
                  <span className="c1">
                    3.2&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information collected
                    by Us as You use Our services
                  </span>
                </h2>
                <h3 className="c52">
                  <span className="c1">3.2.1 &nbsp; &nbsp; &nbsp; Usage Information</span>
                </h3>
                <p className="c46">
                  <span className="c1">
                    When You engage or use Our Services, We will monitor your information in
                    relation to your usage of TRXPAD Services through your device such as your
                    IP address, phone number, the type of device You use to access Our
                    Platform, device information, which websites You visited before accessing
                    Our Services or Platform, browser type, Information about your browser,
                    Date and Time You visit Our Platform, Volume of data transmitted and
                    network operator. This information will either be directly obtained by
                    TRXPAD or through third party service providers.
                  </span>
                </p>
                <p className="c46">
                  <span className="c1">
                    We collect this type of information to ensure that Our interface and/or
                    Platform is seamlessly accessible for all users around the world, except
                    users in the prohibited jurisdictions.
                  </span>
                </p>
                <h3 className="c52">
                  <span className="c1">
                    3.2.2 &nbsp; &nbsp; &nbsp; &nbsp;Communication Information
                  </span>
                </h3>
                <p className="c46">
                  <span className="c1">
                    You hereby agree that We are entitled to collect and use or process the
                    Personal Information You have provided on Our Platform or generated
                    through your usage of TRXPAD Services for the purpose of being able to
                    communicate with You through messages, requests, emails, live chats, file
                    attachments in connection with your transactions on TRXPAD, or any other
                    information related to your contact with TRXPAD. If You contact Us, We
                    will keep a record of the information shared during the correspondence.
                  </span>
                </p>
                <h3 className="c81">
                  <span className="c1">3.2.3 &nbsp; &nbsp; &nbsp;Financial Information</span>
                </h3>
                <p className="c46">
                  <span className="c1">
                    You hereby agree that, for the purposes stipulated in this Privacy Policy,
                    We are entitled to collect and use the information contained in or related
                    to your financial information when You use Our services. This includes,
                    without limitation, your transaction history such as your initial
                    cryptocurrency or token amount, your TRXPAD Token amount, your staking
                    instructions, your wallet address etc.
                  </span>
                </p>
                <p className="c79">
                  <span className="c1">
                    We collect this financial information to monitor suspicious financial
                    activities to protect You from fraud, resolution of legal cases, as well
                    as any other purposes disclosed in this Privacy Policy.
                  </span>
                </p>
                <p className="c7">
                  <span className="c1">
                    Parts of TRXPAD Services are public, including any information disclosed
                    on Our social media accounts, thus You acknowledge that such parts of
                    TRXPAD Services may appear on search engines or other publicly available
                    platforms, and may be “crawled”, searched and used by the third parties or
                    other Users and Customers of the Services. Please do not post any
                    information that You do not wish to reveal publicly. &nbsp;
                  </span>
                </p>
                <p className="c65">
                  <span className="c1">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </p>
                <h1 className="c2">
                  <span className="c12">4. How We collect Personal Information</span>
                </h1>
                <h2 className="c13">
                  <span className="c1">4.1 Information automatically collected by Us</span>
                </h2>
                <p className="c29">
                  <span>
                    We will collect and process Personal Information automatically provided by You under the following situations
                  </span>
                  <span className="c14">. </span>
                </p>
                <h3 className="c52">
                  <span className="c1">
                    4.1.1 &nbsp;&nbsp;&nbsp;when You visit and/or firstly connect with TRXPAD website;
                  </span>
                </h3>
                <h3 className="c21">
                  <span className="c1">
                    4.1.2 &nbsp;&nbsp;&nbsp;when You voluntarily
                    complete any user survey or provide feedback to Us via emails or any other
                    electronic channels including on Our social media accounts;
                  </span>
                </h3>
                <h3 className="c52">
                  <span className="c1">
                    4.1.3&nbsp;&nbsp;&nbsp;when You use browser
                    cookies or any other relevant software upon visiting TRXPAD website; and
                  </span>
                </h3>
                <h3 className="c52">
                  <span className="c1">
                    4.1.4
                    &nbsp;&nbsp;&nbsp;other
                    situations where We may automatically collect your information as
                    described in this
                    Privacy Policy, Our T&amp;Cs, or other relevant agreements with You.
                  </span>
                </h3>
                <h2 className="c38">
                  <span className="c1">
                    4.2 &nbsp; Information collected by the third-party
                  </span>
                </h2>
                <p className="c20">
                  <span className="c1">
                    We will collect your Personal Information from the third parties as
                    required or permitted to the extent of applicable laws. Information from
                    the third-party sources will include, but will not limited to, public
                    databases, ID verification partners, KYC Verification partners, blockchain
                    data, marketing partners and resellers, advertising partners, and
                    analytics providers.
                  </span>
                </p>
                <h2 className="c38">
                  <span className="c1">4.3 &nbsp; Anonymized and Aggregated Data </span>
                </h2>
                <p className="c84 c80">
                  <span className="c1">
                    We will also collect other Information in the form of anonymized and
                    aggregate information where all information will be combined and then
                    removed of personally identifiable information, making it unusable for the
                    identification of specific individuals. Aggregated data could be derived
                    from your personal data, but it is not considered as personal data under
                    applicable laws. For example, We may aggregate your usage data to
                    calculate the percentage of users accessing a specific features of TRXPAD
                    Services. However, if we combine or connect aggregated data with your
                    personal data so that it can directly or indirectly identify You, You
                    acknowledge that we treat the combined data as personal data which will be
                    used in accordance with this Privacy Policy.
                  </span>
                </p>
                <p className="c80 c84">
                  <span className="c1">
                    We use anonymized or aggregate users' information for the purposes
                    stipulated in this Privacy Policy at any time, including gaining better
                    understanding of users' needs and behaviours, conducting business
                    intelligence and marketing initiatives, and detecting security threats. In
                    this regard, We reserve the right to implement innovative technologies as
                    they become available to be used for strategic planning and operational
                    processes.
                  </span>
                </p>
                <p className="c25 c67">
                  <span className="c14" />
                </p>
                <h1 className="c2">
                  <span className="c12">5. How We use Personal Information</span>
                </h1>
                <p className="c4">
                  <span className="c1">
                    Your Personal Information will be made available to need-to-know basis to
                    TRXPAD, Our Affiliates, Our Partners and/or within Our group of companies.
                    This will include, but not be limited to, the employees and/or any persons
                    or third parties working for TRXPAD who are responsible for using and
                    protecting your Personal Information.
                  </span>
                </p>
                <p className="c35">
                  <span className="c1">
                    We use and process your Personal Information for the following purposes or
                    in the following ways:
                  </span>
                </p>
                <p className="c25 c60">
                  <span className="c33 c45" />
                </p>
                <h2 className="c38">
                  <span className="c1">5.1 &nbsp; To provide Our Services</span>
                </h2>
                <p className="c20">
                  <span className="c1">
                    We respect data protection principles, and process personal data only for
                    specified, explicit, and legitimate purpose for which such personal data
                    were provided. We primarily use your Personal information, either
                    collected or delivered, in order to enable your use of TRXPAD Services
                    (including, but not limited to, processing transactions), to improve the
                    efficiency of the Services, the process to participate in the IDO
                    fundraising project event, and to verify your identity.{" "}
                  </span>
                </p>
                <h2 className="c13">
                  <span className="c1">5.2 &nbsp; To protect users</span>
                </h2>
                <p className="c9">
                  <span className="c1">
                    We use the information collected to protect Our Platforms, Users'
                    information, and archives. We also use IP addresses and cookies to detect
                    suspicious activities and to protect against automated abuse such as spam,
                    phishing, and Distributed Denial of Service (“DDoS”) attacks, and other
                    security risks.
                  </span>
                </p>
                <h2 className="c13">
                  <span className="c1">
                    5.3 &nbsp; &nbsp;To comply with regulations and compliance
                  </span>
                </h2>
                <p className="c9">
                  <span className="c1">
                    For Our legitimate interests, We will use the information in compliance
                    with Our legal obligations, global and local industry standards, and Our
                    AML/KYC/CTF procedures. In the case where it is strictly necessary (i.e.,
                    to protect the vital interests of the users or other natural persons, to
                    prevent or mitigate fraud, to fulfil the purpose of public interest, or to
                    pursue Our reasonable interests), We may use and process your Personal
                    Information without obtaining your consent. You hereby expressly authorise
                    TRXPAD to disclose any and all information relating to You in TRXPAD’s
                    possession to any law enforcement or government officials upon a valid
                    request.{" "}
                  </span>
                </p>
                <h2 className="c13">
                  <span className="c1">
                    5.4 &nbsp; For measurement, research and development purposes-
                  </span>
                </h2>
                <p className="c24 c70">
                  <span className="c1">
                    We actively measure and analyse your information to understand the way You
                    use and interact with Our Services, and Our Platform, and to provide You
                    with the unique and personalized experience. For example, We allow You to
                    use social media plugins on Our Services (e.g., Telegram, Twitter, etc.),
                    We keep track of your preferences such as display name, time zone, and so
                    on. This review is continuously conducted by Our operation teams to
                    continuously improve Our Platforms' performance and to resolve issues with
                    user experience.
                  </span>
                </p>
                <p className="c9">
                  <span className="c1">
                    In addition, We use such information to monitor trends, and improve Our
                    Services, Our administration, the content and layout of TRXPAD website,
                    and to develop new Services for You.
                  </span>
                </p>
                <h2 className="c13">
                  <span className="c1">
                    5.5 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For communication
                    purposes
                  </span>
                </h2>
                <p className="c24 c75">
                  <span className="c1">
                    We use your Personal Information, collected or derived from your
                    communication devices or your email address, to interact with You
                    directly, to provide You with necessary support, and/or to keep You
                    informed of wallets connected, transactions, staking instructions,
                    security details, as well as other aspects. All direct communications will
                    be appropriately maintained at TRXPAD, or the service providers designated
                    by TRXPAD, to be reviewed for accuracy, retained as evidence, or used to
                    perform other statutory requirements or other obligations as stipulated in
                    this Privacy Policy and the T&amp;Cs.
                  </span>
                </p>
                <h2 className="c13">
                  <span className="c1">
                    5.6 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To enforce Our
                    T&amp;Cs, and other relevant agreements
                  </span>
                </h2>
                <p className="c9">
                  <span>
                    Personal Information is also used to enforce Our T&amp;Cs continuously and
                    actively among, and other agreements relating to the Services on TRXPAD
                    Platform . Activities in this regard include, but are not limited to,
                    reviewing, processing, verifying, investigating, mitigating and preventing
                    any potentially prohibited or illegal activities that may violate
                    preceding provisions, or disclose relevant information to third parties in
                    accordance therewith. In light of this,{" "}
                  </span>
                  <span className="c61">
                    TRXPAD shall be entitled to freeze/close/delete accounts or any user
                    activity on the TRXPAD website as necessary
                  </span>
                  <span className="c1">
                    &nbsp;to any User found to be engaged in any activities that violate Our
                    T&amp;Cs, and other agreements.
                  </span>
                </p>
                <h2 className="c13">
                  <span className="c1">
                    5.7 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For marketing and
                    advertising &nbsp;
                  </span>
                </h2>
                <p className="c24 c80">
                  <span className="c1">
                    We will share your Personal Information with Our marketing partners for
                    the purposes of targeting, modelling, and/or identifying analytics as well
                    as marketing and advertising. We may send You marketing communications to
                    alert You about new Services, yet You can opt-out of Our marketing
                    activities at any time.
                  </span>
                </p>
                <h2 className="c13">
                  <span className="c1">
                    5.8 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For other purposes
                  </span>
                </h2>
                <p className="c24 c70">
                  <span className="c1">
                    Provided that we need to process or use your Personal Information for
                    other purposes, not stipulated in this Privacy Policy, We will notify or
                    request your consent to use such information. We will not use your
                    Personal Information for purposes other than the purposes stipulated in
                    this Privacy Policy without your prior consent except where it is
                    necessary for our legitimate interests (or for Our Affiliates, Our
                    Partners and/or Our group of companies) and your interests and fundamental
                    rights do not override those interests.
                  </span>
                </p>
                <h1 className="c2">
                  <span className="c12">6. How We protect Personal Information</span>
                </h1>
                <p className="c26">
                  <span className="c1">
                    TRXPAD takes reasonable care to protect the TRXPAD’s security as well as
                    your Personal Information from loss, misuse, disclosure, alteration, and
                    destruction. We take reasonable steps to maintain physical, technical, and
                    employ procedural safeguard to ensure the confidentiality, integrity of
                    your Personal Information. The safeguards include the use of firewalls,
                    and data encryption, enforcing physical access controls to Our premise and
                    files, and limiting access to Personal Information only to those
                    employees, agents or third parties who need access to that information to
                    process it for Us.{" "}
                  </span>
                </p>
                <p className="c26">
                  <span className="c1">
                    We also implement certain reasonable security measures to protect your
                    Personal Information from unauthorized access, and such security measures
                    are in compliance with the security practices and procedures as prescribed
                    under the applicable laws.{" "}
                  </span>
                </p>
                <p className="c44">
                  <span className="c1">
                    However, You agree and acknowledge that it is impossible to fully
                    guarantee the security of your Personal Information by implementing the
                    above-mentioned safeguard and measures. It is not absolute protection to
                    your Personal Information and by accessing the Services.{" "}
                  </span>
                </p>
                <p className="c44">
                  <span className="c1">
                    You agree that We will not hold any responsibility for the acts committed
                    by those who gain unauthorized access or abuse your information and
                    services. In addition, We will not hold any responsibility for any act
                    committed by the third-party service providers who are contractually
                    engaged with Us to maintain an appropriate security measure for protecting
                    your Personal Information. Thus, You are recommended to understand this
                    responsibility and to independently take safety precautions to protect
                    your Personal Information, particularly your credential information such
                    your wallet address. You hereby agree that We will not be liable for any
                    information leakage and other damage or loss not caused by Our intention
                    or gross negligence, including, but not limited to, hacker attacks, power
                    interruptions, or unavoidable technical failures.{" "}
                  </span>
                </p>
                <h1 className="c2">
                  <span className="c12">7. How long We retain Personal Information</span>
                </h1>
                <p className="c4">
                  <span className="c1">
                    You are aware that your Personal Information will continue to be stored
                    and retained by Us or by a third-party processor engaged by US for KYC
                    Verification while You have been using or accessing TRXPAD Platform and
                    the Services, and after your use of the Services for a reasonable period
                    of time stipulated under the applicable law.{" "}
                  </span>
                </p>
                <p className="c4">
                  <span className="c1">
                    In addition, We may be required by applicable laws and regulatory
                    requirements to retain certain information, including your Personal
                    Information, your identification verification materials, information
                    relevant to AML/KYC/CTF procedures, information related to staking
                    instructions, account agreements, and other agreements between Us and
                    third-parties, account statements, and other records after You stop
                    accessing or participating in TRXPAD.
                  </span>
                </p>
                <p className="c70 c74">
                  <span className="c1">&nbsp;</span>
                </p>
                <h1 className="c2">
                  <span className="c12">8. Cookies</span>
                </h1>
                <p className="c26">
                  <span className="c1">
                    We will use cookies and other technologies or methods of web and analytic
                    tools to gather, store, and analyse certain information related with your
                    access to and activities through the Services, including when You visit
                    TRXPAD website.
                  </span>
                </p>
                <p className="c4">
                  <span className="c1">
                    A "cookie" is a small piece of information that a website assigns to your
                    device while You are viewing a website. Cookies are beneficial and may be
                    used for various purposes. These purposes include, among other things,
                    allowing You to navigate between pages efficiently, enabling automatic
                    activation of certain features, remembering your preferences and making
                    the interaction between You and the Services quicker, easier and smoother.
                    Our TRXPAD website will use the following types of cookies:
                  </span>
                </p>
                <h2 className="c13">
                  <span className="c1">8.1 &nbsp; Strictly Necessary Cookies</span>
                </h2>
                <p className="c9">
                  <span className="c1">
                    These cookies are essential to enable You to log in, navigate a website,
                    and use its features or to provide a service requested by You. We will not
                    need to obtain your consent in order to use these cookies.
                  </span>
                </p>
                <h2 className="c13">
                  <span className="c1">8.2 &nbsp; Functionality Cookies</span>
                </h2>
                <p className="c24">
                  <span className="c1">
                    These cookies allow the website to remember choices You make (such as your
                    username, language, or the region You reside in) and provide enhanced,
                    more personal features. The information these cookies collect remains
                    anonymous, and they cannot track your browsing activity on other websites.
                  </span>
                </p>
                <h2 className="c13">
                  <span className="c1">
                    8.3&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Performance cookies
                  </span>
                </h2>
                <p className="c9">
                  <span className="c1">
                    These cookies collect information about how You use a website, for
                    example, which pages You go to most often, how much time You spend on a
                    page, record difficulties You may experience while using the website such
                    as error messages. All information collected by these cookies is
                    aggregated and therefore anonymous. It is only used to improve the
                    efficiency of the website.
                  </span>
                </p>
                <h2 className="c13">
                  <span className="c1">
                    8.4 &nbsp; Targeting Cookies or Advertising Cookies
                  </span>
                </h2>
                <p className="c23">
                  <span className="c1">
                    These cookies are used to deliver advertisements tailored to You and your
                    interests specifically. They are also used to limit the number of times
                    You see an advertisement as well as help measure the effectiveness of the
                    advertising campaign. These cookies remember that You have visited a
                    website, and this information is shared with other organizations such as
                    advertisers. Quite often targeting or advertising cookies will be linked
                    to the sites’ functionality provided by the other organizations.{" "}
                  </span>
                </p>
                <p className="c26">
                  <span className="c1">
                    You may remove these cookies by following the instructions of your device
                    preferences. However, if You choose to disable cookies, some features of
                    TRXPAD Services may not operate properly or become inaccessible, and your
                    online experience may be limited. For Further information please visit our
                    Cookies Policy.
                  </span>
                </p>
                <h1 className="c2">
                  <span className="c12">9. Sharing your Personal Information</span>
                </h1>
                <p className="c26">
                  <span className="c1">
                    We will not rent, sell, or disclose your Personal Information to any third
                    parties, except to those who require access to the information to perform
                    their tasks and duties under the binding agreements executed with Us and
                    to share with third parties who have a legitimate purpose for accessing
                    it.
                  </span>
                </p>
                <p className="c31">
                  <span className="c1">
                    We may share, transfer, disclose, or allow access to your Personal
                    Information to the following third parties for the purposes described
                    below:
                  </span>
                </p>
                <ol className="c10 lst-kix_list_4-1 start" start={1}>
                  <li className="c70 c73 li-bullet-0">
                    <span className="c1">
                      We may disclose Personal Information to the third parties in order to
                      administer or process a transaction, or services You have authorized or
                      requested, or in the context of facilitating the execution of a
                      transaction, or We may disclose Personal Information to third parties
                      that provide supporting services, or analytical information for the
                      purpose of the Service improvement, only where we have a lawful basis to
                      do so or under an agreement or legal requirements for the transfer of
                      Personal Information.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c1">
                      We will be required to verify your identities by applicable laws and
                      regulatory requirements and rely on third-party services to perform
                      these verifications. Personal Information that You provide to Us during
                      the initial connecting process is passed to these services at KYC
                      Verification process and on an ongoing basis thereafter.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c1">
                      We may disclose or transfer a part of your Personal Information We
                      collect if We are involved in a business transition or any merger and/or
                      acquisition (M&amp;A) projects. In such M&amp;A event, your Personal
                      Information might be among the assets transferred, but it will be
                      protected and secure in accordance with this Privacy Policy.{" "}
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c1">
                      We may share your Personal Information with law enforcement, and
                      government officials when We are compelled to do so by a subpoena, a
                      valid court order to prevent financial loss, to report suspected illegal
                      activity or to investigate violations of any of Our T&amp;Cs or any
                      other applicable policies.
                    </span>
                  </li>
                  <li className="c5 li-bullet-0">
                    <span className="c1">
                      We may share your Personal Information with third-party processors
                      acting on behalf of TRXPad or engaged by TRXPAD to process your Personal
                      Information for TRXPad, Our Affiliates and/or Our Partners.{" "}
                    </span>
                  </li>
                </ol>
                <p className="c16">
                  <span className="c1">
                    All Affiliates and third parties with whom We share your Personal
                    Information have their privacy policies. However, they will be expected to
                    protect this information in a manner that aligns with the provisions
                    described in this Privacy Policy.
                  </span>
                </p>
                <h1 className="c2">
                  <span className="c12">
                    10. Third-Party Collection of Personal Information
                  </span>
                </h1>
                <p className="c16">
                  <span className="c1">
                    This Privacy Policy only addresses the use and disclosure of the Personal
                    Information We collect from You. To the extent that You disclose your
                    information to other parties through the use of Our Services such as by
                    clicking on a link to any other websites of our partner networks,
                    different rules regarding Privacy Policies may apply to their use or
                    disclosure of the Personal Information You disclose to them.
                  </span>
                </p>
                <p className="c22">
                  <span className="c1">
                    You hereby acknowledge that We will not be responsible for the products,
                    services, or descriptions of products or services that You receive from
                    the third-party websites or to the content or privacy practices of the
                    third-party websites. Also, this Privacy Policy will not be applied to any
                    such third-party products and services that You access through TRXPAD
                    Platform. You are knowingly and voluntarily assuming all risks of using
                    third-party websites to purchase products or services, and You agree that
                    We will have no liability whatsoever concerning such third-party websites
                    and your usage of them.
                  </span>
                </p>
                <p className="c22">
                  <span className="c1">
                    Your relationship with these third parties and their services and tools is
                    independent of your relationship with Us. These third parties may allow
                    You to permit/restrict the information that is collected. It may be in
                    your interest to individually restrict or enable such data collections.
                  </span>
                </p>
                <h1 className="c2">
                  <span className="c12">
                    11. Designated Countries Privacy Right and Your Legal Rights
                  </span>
                </h1>
                <p className="c17">
                  <span>
                    This provision applies to Users of the Services that are located in the
                    EEA, United Kingdom and/or Switzerland (herein collectively referred as “
                  </span>
                  <span className="c18">Designated Countries</span>
                  <span>
                    ”) at the time of Data or Personal Information being collected. We may ask
                    your information regarding your nationality, your location, which country
                    You are in when You use Our Services or Platform, or We may rely on your
                    IP address. We cannot apply this provision to Users who do not provide
                    information about the location or obfuscates location information so as
                    not to appear located in the Designated Countries.{" "}
                  </span>
                </p>
                <p className="c17">
                  <span>
                    We use and process your Personal Information based on a valid lawful basis
                    of the GDPR and any equivalent regulations (collectively referred as “
                  </span>
                  <span className="c18">Data Protection Laws</span>
                  <span className="c1">
                    ”). The lawful basis for processing your Personal Information includes (i)
                    your consent, (ii) performance of a contract, (iii) a legitimate interest,
                    (iv) a compliance with legal obligations, (v) protection your vital
                    interest, and (vi) public task.
                  </span>
                </p>
                <p className="c17">
                  <span className="c1">
                    In the event of any conflict or inconsistency between any term in this
                    provision and other terms contained in this Privacy Policy, the term in
                    this provision will govern and control over Users in the Designated
                    Countries.{" "}
                  </span>
                </p>
                <p className="c17">
                  <span className="c1">
                    Under certain circumstances when You use Our Services, You have the rights
                    under the Data Protection Laws in relation to your Personal Information.
                    These rights include the followings:
                  </span>
                </p>
                <ol className="c10 lst-kix_list_19-1 start" start={1}>
                  <li className="c56 li-bullet-0">
                    <h2 style={{ display: "inline" }}>
                      <span className="c1">Right to be informed</span>
                    </h2>
                  </li>
                </ol>
                <p className="c53">
                  <span className="c1">
                    You reserve the right to be informed about the collection, use, and
                    process of your Personal Information.
                  </span>
                </p>
                <h2 className="c13">
                  <span className="c1">
                    11.2 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Right of access
                  </span>
                </h2>
                <p className="c20">
                  <span className="c1">
                    You reserve the right to make a request for a copy of the Personal
                    Information We hold about You and specific information regarding Our
                    processing of this information.
                  </span>
                </p>
                <p className="c41">
                  <span className="c1">
                    11.3 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Right to rectify
                  </span>
                </p>
                <p className="c7">
                  <span className="c1">
                    You reserve the right to request Us to update, correct, or complete your
                    Personal Information that You believe to be outdated, inaccurate, or
                    incomplete. You will rectify at any time by informing Us via our contact
                    channels.
                  </span>
                </p>
                <p className="c41">
                  <span className="c1">
                    11.4 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Right to erasure
                    (“Right to be Forgotten”)
                  </span>
                </p>
                <p className="c20">
                  <span className="c1">
                    You may request to have your Personal Information deleted from Our record
                    where (i) your Personal Information is no longer necessary to be used for
                    the Services or on the Platform, (ii) your Personal Information was
                    collected in relation to processing that You previously consented to, but
                    later withdrew such consent, or (iii) your Personal Information was
                    collected in relation to processing activities to which You object, and
                    there are no overriding legitimate grounds for Our processing.
                  </span>
                </p>
                <p className="c20">
                  <span className="c1">
                    Please be informed that the Right to be Forgotten mentioned above is not
                    an absolute right. We are compelled to fulfil your request only where the
                    retention of your Personal Information might cause an infraction of the
                    GDPR or applicable law to which We are subject. We shall exercise
                    reasonable efforts in having your Personal Information erased to the
                    extent required by the GDPR or applicable law including in communicating
                    erasure to Our recipients of your Personal Information unless that proves
                    to be impossible or a disproportionate effort.
                  </span>
                </p>
                <h2 className="c13">
                  <span className="c1">
                    11.5 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Right to data
                    portability
                  </span>
                </h2>
                <p className="c7">
                  <span className="c1">
                    You reserve the right to request to transfer a machine-readable copy of
                    your Personal Information to You or the third-party of your choice. We
                    will provide You, or third-party, your Personal Information in a
                    machine-readable format. This right only applies to Personal Information
                    You have consented Us to use.
                  </span>
                </p>
                <h2 className="c13">
                  <span className="c1">
                    11.6 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Right to restrict
                    processing
                  </span>
                </h2>
                <p className="c59">
                  <span className="c1">
                    You may request Us to restrict or suppress the processing of your Personal
                    Information under certain circumstances as follows:
                  </span>
                </p>
                <ol className="c10 lst-kix_list_25-0 start" start={1}>
                  <li className="c85 li-bullet-1">
                    <span className="c1">
                      to contests the accuracy of the Personal Information;
                    </span>
                  </li>
                  <li className="c15 li-bullet-1">
                    <span className="c1">
                      when the processing is considered unlawful, but You do not wish to have
                      your Personal Information erased;
                    </span>
                  </li>
                  <li className="c69 li-bullet-1">
                    <span className="c1">
                      where We no longer need to process your Personal Information, but the
                      information must be retained for the establishment, exercise or defence
                      of legal claims; and
                    </span>
                  </li>
                  <li className="c39 li-bullet-1">
                    <span className="c1">
                      where You have objected to Our processing your Personal Information, but
                      We need to determine whether Our legitimate interest overrides your
                      objection.
                    </span>
                  </li>
                </ol>
                <h2 className="c13">
                  <span className="c1">
                    11.7 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Right to object
                  </span>
                </h2>
                <p className="c7">
                  <span className="c1">
                    You may object to Our reliance on Our legitimate interests as the basis of
                    Our processing of your Personal Information that impacts your rights. You
                    may also object to Our process of your Personal Information for direct
                    marketing purposes.
                  </span>
                </p>
                <h2 className="c13">
                  <span className="c1">
                    11.8 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Right to withdraw
                    consent ("Opt-out")
                  </span>
                </h2>
                <p className="c7">
                  <span className="c1">
                    You reserve the right to withdraw your consent at any time where We are
                    relying on it to process your Personal Information. Withdrawing your
                    consent does not affect the lawfulness of Our processing of your Personal
                    Information prior to withdrawing.
                  </span>
                </p>
                <p className="c48">
                  <span>
                    If You wish to exercise these rights as aforementioned, please inform and
                    contact Us via Our Platform, including TRXPAD
                  </span>
                  <span className="c34">&nbsp;</span>
                  <span>website or send a request to </span>
                  <span className="c8">
                    <a className="c51" href="https://bluezilla.jitbit.com/helpdesk/" target="_blank">
                    Contact Support
                    </a>
                  </span>
                  <span className="c1">&nbsp;. </span>
                </p>
                <p className="c48">
                  <span className="c1">
                    Please also be noted that there are some limitations when You wish to
                    exercise any one of these individual rights. We may limit your individual
                    rights in the following situations:
                  </span>
                </p>
                <ol className="c10 lst-kix_list_15-0 start" start={1}>
                  <li className="c55 li-bullet-1">
                    <span className="c1">Where denial of access is required by laws;</span>
                  </li>
                  <li className="c55 li-bullet-1">
                    <span className="c1">
                      When granting access would have a negative impact on others’ privacy;
                    </span>
                  </li>
                  <li className="c55 li-bullet-2">
                    <span className="c1">In order to protect Our rights and properties;</span>
                  </li>
                  <li className="c55 li-bullet-1">
                    <span className="c1">Where the request burdensome.</span>
                  </li>
                </ol>
                <h1 className="c2">
                  <span className="c12">
                    12. Cross Border Transfer of Personal Information
                  </span>
                </h1>
                <p className="c16">
                  <span className="c1">
                    As a part of your use of and to ensure better and seamless delivery of the
                    Services to You, We may store, process, analyse, transfer your Personal
                    Information in location globally, including countries other than your home
                    jurisdiction, and/or locations outside the Designated Countries where
                    there may have data protections with less protection than the EU GDPR or
                    the equivalent data protection laws.{" "}
                  </span>
                </p>
                <p className="c16">
                  <span className="c1">
                    Our Service and Platform contain the transmission of your Personal
                    Information to or to be transferred to or to be processed outside of the
                    country where You are located or outside the Designated Countries, it is
                    not suggestible if your Personal Information is restrictive.{" "}
                  </span>
                </p>
                <p className="c16">
                  <span className="c1">
                    However, if You wish to continue using Our Services outside the Designated
                    Countries and your home locations, We will ensure that We will transfer
                    your Personal Information only to country that has the required
                    contractual provisions for transferring Personal Information in place with
                    the third parties to which your Information is transferred, or to country
                    where is approved by the EU authorities as providing an adequate level of
                    data protection or enter into legal agreements ensuring an adequate level
                    of data protection in the jurisdiction of the party receiving the
                    information. In this manner, You hereby expressly consent to this
                    transfer, and agree that We will not be responsible for any additional
                    T&amp;Cs, policies or any other guidelines implemented by any party
                    receiving the information.{" "}
                  </span>
                </p>
                <h1 className="c2">
                  <span className="c12">13. Limitation</span>
                </h1>
                <h2 className="c30">
                  <span className="c1">
                    13.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Our Services are not
                    designed for the individuals who are under the age of 18 or under the
                    legal age to provide consent under the applicable laws of country or
                    jurisdiction where You are using Our Services or Platform. If You are not
                    over 18 or of the legal age of your country, You will not provide any
                    Personal Information to Us, or to engage in Our services. We reserve the
                    right to access and verify any Personal Information collected from You. If
                    We are aware that You are under 18 or under the legal age who already
                    shared your Personal Information with Us, We will then discard such
                    information and block your access to TRXPAD Platform immediately.
                  </span>
                </h2>
                <h2 className="c30">
                  <span className="c1">
                    13.2 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We cannot guarantee
                    that any losses, misuses, unauthorized accession, or alteration of your
                    Personal Information will not occur. You hereby agree that You play a
                    vital role in protecting your Personal Information, including your
                    credentials.{" "}
                  </span>
                </h2>
                <h1 className="c2">
                  <span className="c12">14. Updates or amendments to the Privacy Policy</span>
                </h1>
                <p className="c68">
                  <span className="c1">
                    We will revise and update this Privacy Policy periodically, at Our sole
                    discretion, and the most current version will be published on TRXPAD
                    website or TRXPAD Platform (as reflected in the "Last Revised" heading).
                  </span>
                </p>
                <p className="c17">
                  <span className="c1">
                    In the event of any material change which may affect your usage on Our
                    Services and Platform, We will notify You by means of a prominent notice
                    in advance of such change coming into effect. A prominent notice may
                    include sending through your email address or other communication channels
                    You provide to Us or providing You with an in-application notice such as a
                    banner or pop-up alerting You of the change on the TRXPAD website. We
                    strongly encourage You to review this Privacy Policy actively. If You do
                    not agree, either partially or wholly, the revised Privacy Policy, You
                    should discontinue accessing or using Our Services. Your continued access
                    to and use of Our Services after any changes to this Privacy Policy
                    constitutes your consent to any changes and agree to continue using the
                    Services.
                  </span>
                </p>
                <p className="c17">
                  <span className="c1">
                    Furthermore, We may provide You with the “just-in-time” disclosures or
                    additional information about Our data collection process, purposes for
                    processing, or other information with respect to collection of your
                    personal information. The purpose of the just-in-time notification is to
                    supplement or clarify Our privacy practice or provide You with additional
                    choices about how We process your personal information.
                  </span>
                </p>
                <h1 className="c2">
                  <span className="c12">15. How to contact TRXPAD</span>
                </h1>
                <p className="c60 c64">
                  <span>
                    For any questions regarding this Privacy Policy, your Personal Information
                    collected or proceeded by Us, or in case You would like to exercise one of
                    your legal privacy rights as stipulated in Clause 11, please submit your
                    requests to{" "}
                  </span>
                  <span className="c8">
                    <a className="c51" href="https://bluezilla.jitbit.com/helpdesk/" target="_blank">
                    Contact Support
                    </a>
                  </span>
                  <span>&nbsp;and visit Our website at </span>
                  <span className="c8">
                    <a
                      className="c51"
                      href="https://www.google.com/url?q=http://tronpad.network/&sa=D&source=editors&ust=1632073591403000&usg=AOvVaw2G_rECGBMv5I-THSW-nPRl"
                    >
                      http://tronpad.network/
                    </a>
                  </span>
                  <span>.</span>
                </p>
                <li className="c54">
                  <a href="#ftnt_ref1" id="ftnt1" onClick={(e) => e.preventDefault()}>
                    [1]
                  </a>
                  <span className="c33 c71">&nbsp;Version 2.1</span>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyPage;
