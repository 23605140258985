import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";



import { actGetListProjects } from "../../redux/action/user";
import WaitingProjectsComponent from "./WaitingProjectsComponent";
import OpeningProjectsComponent from "./OpeningProjectsComponent";
import ClosedProjectsComponent from "./ClosedProjectsComponent";
import { ACTION_CONST } from "../../constants";
import { get } from "lodash";

const ProjectsPage = (props) => {

  const walletUtils = useSelector((state) =>get(state, "utils.walletUtils", null));
  const dispatch = useDispatch();
  const [init, seInit] = useState(true);


  useEffect(() => {
    dispatch(actGetListProjects(walletUtils))

    if (init) {
      // seInit(false)
      const job = setInterval(() => {
        dispatch(actGetListProjects(walletUtils))
      }, 30 * 1000);
     
      dispatch({
        type: ACTION_CONST.SET_JOB_GET_PROJECTS,
        data: job
      })
    }


  }, [walletUtils])
  // useEffect(() => {
  //   dispatch(actGetListProjects())
  // }, [])





  return (
    <>
      {/* <BannerComponent /> */}
      <div className="pp-projects-page mt-5 pt-3">

      <OpeningProjectsComponent />
        <WaitingProjectsComponent />

        {/* <OpeningProjectsComponent /> */}

        <ClosedProjectsComponent />
      </div>

    </>
  );
};

export default ProjectsPage;
