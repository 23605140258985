import React from "react";
import { useTranslation } from "react-i18next";
import AdvisorsContainer from "../../component/AdvisorContainer";
import { ADVISORS, LEGAL_PARTNER } from '../../constants/values'


const AdvisorComponent = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="pp-home-advisors pp-section py-5">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-12">
                            <div className="section-head">
                                <h2 className="title title-xl" data-aos="fade-up" data-aos-delay="100" title="PARTNER">{t('STRATEGIC PARTNER')}</h2>
                            </div>
                        </div>
                        <div className="col-lg-12 mb-5">
                            <div className="pp-card-legal-partner">
                                <div className="mb-4">
                                    <img width="130" className="dark-show mx-auto" src='/images/tron-logo-d.png' alt='' />
                                    <img width="130" className="light-show mx-auto" src='/images/tron-logo.png' alt='' />
                                </div>
                                <p className="mb-0">{t('"The BSCPad team is very strong and knowledgeable, they consist of some of the top players in the space. We are looking forward to taking this collaboration to new heights", Justin Sun, Founder of TRON and CEO of BitTorrent.')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pp-home-advisors pp-section py-3" id="advisor">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-12">
                            <div className="section-head">
                                <h2 className="title title-xl" data-aos="fade-up" data-aos-delay="100" title="PARTNER">{t('INCUBATOR AND INVESTMENT PARTNER')}</h2>
                            </div>
                        </div>
                        <div className="col-lg-12 mb-5 mt-4">
                            <div className="pp-card-legal-partner">
                                <div className="mb-4">
                                    <img width="230" className="dark-show mx-auto" src='/images/bluzilla_logo.png' alt='' />
                                    <img width="230" className="light-show mx-auto" src='/images/bluzilla_logo-df.png' alt='' />
                                </div>
                                <div>
                                    <p>{t('We specialize in taking your innovative and new idea from concept to completion through our market leading advisory, investment, development, influencer marketing and legal support services.')}</p>
                                    <p>{t('We are the only VC with in house developers, designers, marketers, influencers, traders, legal and launch pads.')}</p>
                                    <p>{t('We help by not only bringing capital and partners, but every aspect of your project from tokenomics to post launch marking.')}</p>
                                    <div className="pt-3">
                                        <a href="https://bluezilla.vc/" target="_blank" className="btn btn-outline-primary">Learn more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AdvisorsContainer advisors={ADVISORS} legalPartner={LEGAL_PARTNER} />
            </div>
        </>
    );
};

export default AdvisorComponent;
