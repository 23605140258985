import { takeLatest, put, delay, call } from "redux-saga/effects";

import { ROUTES, MESSAGES, ACTION_CONST } from "../../constants";
import { getProject, getProjects } from "../services/project";
import { getInfoContract } from "../../utils/tronHelpers";

function* handleGetProjects(data) {
  try {
    const walletUtils = data.data
   
    const resProjects = yield call(getProjects);

    let listContractAddress = [];

    resProjects.forEach((item) => {
      if (typeof item["contract"] === "string" && item["state"] !== "C" ) {
        listContractAddress.push(item["contract"]);
      }
    });
    // // debugger
    // const projects = yield call(getProjects);

    if (listContractAddress.length > 0) {
      let arr = [];
      let resContracts = []
      try {
         resContracts = yield call(getInfoContract, {addresses: listContractAddress, walletUtil:walletUtils});
        //  debugger
        //  debugger
      } catch (error) {
        console.log(error);
        resContracts = yield call(getInfoContract, {addresses: listContractAddress, walletUtil: walletUtils})
        
      }

      // debugger
      resProjects.forEach((element) => {
        const target = element;
        const source = resContracts[element.contract];
        const obj = Object.assign(target, source)
        //add logic
        const item = resProjects.find(e=>e.symbol === element.projectTokenSymbol);

        if(item){
          obj["athMultiplier"] = item["ath_multiplier"]
        }else{
          obj["athMultiplier"] = null
        }
        arr.push(obj);
      });

      // console.log(resProjects)
      yield put({
        type: ACTION_CONST.GET_PROJECTS_SUCCESS,
        data: {
          projects: arr,
          openingProjects: arr.filter((e) => e.state == "O"||e.state == "F"),
          waitingProjects: arr.filter((e) => e.state == "P"),
          closedProjects: arr.filter((e) => e.state == "C"),
        },
      });
    } else {
      yield put({
        type: ACTION_CONST.GET_PROJECTS_SUCCESS,
        data: {
          projects: resProjects,
          openingProjects: resProjects.filter((e) => e.state == "O"),
          waitingProjects: resProjects.filter((e) => e.state == "P"),
          closedProjects: resProjects.filter((e) => e.state == "C"),
        },
      });
    }

  } catch (error) {

    console.log("==============Get project error===========");
    console.log(error);
    return null;
  }
}

function* handleGetSelectedProjects(data) {

  const {contractAddress, walletUtils} = data.data

  const resProjects = yield call(getProject, contractAddress);
  // debugger
  if (resProjects.status == 200) {
    const resContracts = yield call(getInfoContract, {addresses: [contractAddress], walletUtil: walletUtils} );
    const object = Object.assign(resProjects.data, resContracts[contractAddress]);
    // console.log("get project selected ==>", object);
    yield put({
      type: ACTION_CONST.GET_PROJECT_SELECTED,
      data: object,
    });
  }



}


export function* watchSubmitGetProjects() {
  yield takeLatest(
    ACTION_CONST.SUBMIT_GET_PROJECTS,
    handleGetProjects
  );
}

export function* watchSubmitSelectedProjects() {
  yield takeLatest(
    ACTION_CONST.SUBMIT_PROJECT_SELECTED,
    handleGetSelectedProjects
  );
}