import React from "react";
import { useTranslation } from "react-i18next";
import TierContainer from "../../component/TierContainer";
import { TIERED_SYSTEM } from '../../constants/values'

const TieredSystemComponent = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="pp-home-how-it-work pp-section pt-3" id="how-it-work">
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-12">
                            <div className="section-head">
                                <h2 className="title title-xl" data-aos="fade-up" data-aos-delay="100" title={t('Tiered System')}>{t('THE TRONPAD TIERED SYSTEM')}</h2>
                                <p data-aos="fade-up" data-aos-delay="200" className="mt-3">{t('TronPad offers a weighted lottery tier and guaranteed allocation tiers.')}</p>
                                <div className="row align-items-center mt-5">
                                    <div className="col-md-4">
                                        <img src="/images/antier_expertise_ido_development.png" className="w-100" />
                                    </div>
                                    <div className="col-md-8 ps-md-5 font-18 text-start">
                                        <p>{t("Each project's overall IDO offering is split into these allocations:")}</p>
                                        {/* <p><b className="white-d">5%</b> {t('for public white list lotteries (open to anyone who completes tasks, no tokens required)')}</p> */}
                                        <p><b className="white-d">10%</b> {t('for lottery Tiers (Earth tiers)')}</p>
                                        <p><b className="white-d">90%</b> {t('for guaranteed allocation Tiers (higher tiers)')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TierContainer TIERED={TIERED_SYSTEM} />
            </div>
        </>
    )

}


export default TieredSystemComponent;