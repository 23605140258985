export const MESSAGES = {


  NORMAL_ERROR: "Oop! Have an error",
  // NUMBER_EXPIRED: 'Number verify expired',


  APPROVE_TOKEN_SUCCESS: 'Approve Success',
  APPROVE_TOKEN_FAILS: 'Failed to Approve Tokens',

  BUY_TOKEN_SUCCESS: 'Successfully Joined Pool',
  BUY_TOKEN_FAIL: 'Failed to Join Pool',


  DEPOSIT_STAKING_SUCCESS: 'Deposit stake successfully',
  DEPOSIT_STAKING_FAIL:'Deposit stake fail!',

  INIT_UN_STAKING_SUCCESS: 'Init unstake successfully',

  INIT_UN_STAKING_FAIL: 'Init unstake fails'

};
