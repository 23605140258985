import React, { useEffect, useState, } from "react";
import { get } from "lodash";
import { ACTION_CONST, MESSAGES, STATUS } from "../../constants";
// import { actGetListProjects } from "../../redux/action/user";
import { useDispatch, useSelector } from "react-redux";
import { helpers } from "../../utils/helpers";
import { useTranslation } from "react-i18next";


const ApproveModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [showBlockUI, setShowBlockUI] = useState(false);
  const selectedProject = useSelector((state) =>
    get(state, "project.selectedProject", null)
  );
  const walletUtils = useSelector((state) =>
    get(state, "utils.walletUtils", null)
  );
  const [projectName, setProjectName] = useState("")
  const [symbol, setSymbol] = useState("")
  const [amount, setAmount] = useState(0);


  const [enableAprBtn, setEnableAprBtn] = useState(false);

  useEffect(() => {
    if (selectedProject) {
      setProjectName(selectedProject.name)
      setSymbol(selectedProject.symbol);

    }



  }, [selectedProject]);


  const handleOnchangeAmount = (e) => {
    const amountInputValue = Number(e.target.value);
    if (0 < amountInputValue
      && amountInputValue <= props.walletInfo.remainingAllocation
      && amountInputValue <= props.walletInfo.tokenBalance) {
        
      setEnableAprBtn(true);

    } else {
      setEnableAprBtn(false)
    }


    setAmount(amountInputValue)
    props.handleInputAmount(amountInputValue)
    return;
  }

  const handleClickMax = () => {

    if (props.walletInfo.tokenBalance > 0 && props.walletInfo.remainingAllocation <= props.walletInfo.tokenBalance) {
      setAmount(props.walletInfo.remainingAllocation)
      document.getElementById('inputAmountApprove').value = helpers.formatNumberDownRound(props.walletInfo.remainingAllocation, 4);
      setEnableAprBtn(true);
      return;
    }
    if (props.walletInfo.remainingAllocation > 0 && props.walletInfo.remainingAllocation > props.walletInfo.tokenBalance) {
      setAmount(props.walletInfo.tokenBalance)
      document.getElementById('inputAmountApprove').value = helpers.formatNumberDownRound(props.walletInfo.tokenBalance, 4)
      setEnableAprBtn(true);
      return;
    }
    setEnableAprBtn(false);

  }

  const handleApprove = () => {
    if (walletUtils && selectedProject) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      // debugger
      // console.log("amount=>", amount);
      walletUtils.approve({
        spenderAddress: selectedProject.contract,
        tokenContractAddress: selectedProject.tokenAddress,
        amount: Number(amount),
        decimals: selectedProject.decimal
      }, (data) => {

        if (data.status == STATUS.APPROVED) {
          // setShowBuyBtn(true)
          // setShowAprBtn(false)
          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          props.handleBuyClick()

          document.getElementById("inputAmountApprove").value = 0;
          setEnableAprBtn(false);
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: t(MESSAGES.APPROVE_TOKEN_SUCCESS)
          })
        }
        if (data.status == STATUS.APPROVE_FAILS) {
          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: t(MESSAGES.APPROVE_TOKEN_FAILS)
          })
        }

      }).catch(err => {
        dispatch({
          type: ACTION_CONST.ALERT_SUCCESS,
          message: t(MESSAGES.APPROVE_TOKEN_FAILS)
        })
        dispatch({ type: ACTION_CONST.REQUEST_DONE })
      })
    }
  }

  return (
    <div
      className="modal fade"
      id="approveModal"
      tabIndex="-1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="approveModalLabel">{t('Join')} {projectName} {t('Pool')}</h5>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn-close me-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div className="modal-body">
            {/* <BlockUi tag="div" blocking={showBlockUI}> */}
            <div className="mb-3 d-flex align-items-center">
              <label className="form-label mb-0 me-4">{t('Your balance:')} </label><h4 className="text-danger mb-0">{props?.tokenBalance} {props?.symbol}</h4>
            </div>
            <div className="mb-2 d-flex align-items-center justify-content-between" style={{ columnGap: '10px' }}>
              <span className="ps-0" style={{ border: 0, whiteSpace: 'nowrap' }}>{symbol} {t('amount:')}</span>
              <input type="number" id="inputAmountApprove" className="form-control ms-2 me-1"
                style={{ width: 'calc(100% - 12px)' }}
                autoFocus onChange={(e) => handleOnchangeAmount(e)} />

              <button className="btn btn-outline-primary" onClick={() => handleClickMax()}> <span>{t('Max')}</span></button>
            </div>
            <div className="get-start mt-3 text-center mb-2 d-flex" style={{ columnGap: '10px' }}  >
              <button className="btn btn-primary w-100" disabled={!enableAprBtn} onClick={() => handleApprove()} data-bs-dismiss="modal"><span>{t('APPROVE')}</span></button>
            </div>
            {/* </BlockUi> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveModal;
