import { ACTION_CONST } from "../../constants";

export function actGetListProjects(walletUtil){
    return {
        type: ACTION_CONST.SUBMIT_GET_PROJECTS,
        data: walletUtil
    }
}
export function actSelectedProject(data){
    return {
        type: ACTION_CONST.SUBMIT_PROJECT_SELECTED,
        data: data

    }
}

export function actSetCurrentContract (contract){
    return {
        type: ACTION_CONST.SET_CURRENT_CONTRACT_SELECTED,
        data: contract
    } 
}

export function actGetWalletInfo(wallet){
    return {
        type: ACTION_CONST.GET_INFO_WALLET,
        data: wallet
    } 
}
