import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";
import './style.css';

const TermsOfUsePage = (props) => {
  return (
    <>
      <div className="container py-5">
        <div className="d-flex align-items-start tab-term">
          <div className="nav nav-pills-policy flex-column nav-pills me-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <Link to={ROUTES.TERMS_OF_USE} className="nav-link text-start active" id="v-pills-terms-tab">Terms of Use </Link>
            <Link to={ROUTES.PRIVACY_POLICY} className="nav-link text-start" id="v-pills-privacy-policy-tab">Privacy Policy</Link>
            <Link to={ROUTES.COOKIE_POLICY} className="nav-link text-start" id="v-pills-cookies-policy-tab">Cookies Policy</Link>
            {/* <Link to={ROUTES.STAKING_POLICY} className="nav-link text-start" id="v-pills-staking-policy-tab">Staking Policy</Link>
            <Link to={ROUTES.CLICKWRAP_ACCEPTANCE} className="nav-link text-start" id="v-pills-clickwrap-acceptance-tab">Clickwrap Acceptance</Link> */}
          </div>
          <div className="tab-content" id="v-pills-policy-tabContent">
            <div className="tab-pane fade show active text-start" id="v-pills-cookies-policy" role="tabpanel" aria-labelledby="v-pills-cookies-policy-tab">
              <div className="term-container">
                <div>
                  <p className="c13 c72">
                    <span className="c5 c0" />
                  </p>
                </div>
                <p className="c62" id="h.gjdgxs">
                  <span className="c73 c0 c31 c49">TRONPAD DIGITAL LIMITED</span>
                </p>
                <p className="c52">
                  <span className="c0 c31 c49 c73">
                    Terms and Conditions of <a
                      className="c51"
                      href="https://www.google.com/url?q=http://tronpad.network/&sa=D&source=editors&ust=1632073591369000&usg=AOvVaw3U6LGRvo2QfyO0LTiqiLAL"
                    >
                      http://tronpad.network/
                    </a>
                  </span>
                </p>
                <p className="c62">
                  <span className="c49">Last revised Date 14</span>
                  <span className="c49 c61">th</span>
                  <span className="c49">&nbsp;September 2021</span>
                  <sup className="c49 c61">
                    <a href="#ftnt1" id="ftnt_ref1" onClick={(e)=>e.preventDefault()}>
                      [1]
                    </a>
                  </sup>
                </p>
                <p className="c22 c13">
                  <span className="c1 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-0 start" start={1}>
                  <li className="c22 c44 li-bullet-0">
                    <h1 style={{ display: "inline" }}>
                      <span className="c1 c0">Introduction</span>
                    </h1>
                  </li>
                </ol>
                <p className="c22 c3 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0">These Terms and Conditions (herein referred as “</span>
                  <span className="c0 c31">Terms</span>
                  <span className="c0">”) govern the use and the conditions of </span>
                  <span className="c25">
                    <a
                      className="c17"
                      href="https://www.google.com/url?q=https://tronpad.network/&sa=D&source=editors&ust=1632074331111000&usg=AOvVaw1jtZ8yQ_IQrnSk2aFk-5Hj"
                    >
                      https://tronpad.network/
                    </a>
                  </span>
                  <span className="c25">&nbsp;</span>
                  <span className="c0">(herein referred as “</span>
                  <span className="c0 c31">Website</span>
                  <span className="c0">
                    ”), and the Services provided by TRXPAD Digital Limited
                  </span>
                  <span className="c0 c71">&nbsp;</span>
                  <span className="c0">(herein referred as “</span>
                  <span className="c0 c31">Company</span>
                  <span className="c0">” or “</span>
                  <span className="c0 c31">We</span>
                  <span className="c0">” or “</span>
                  <span className="c0 c31">Us</span>
                  <span className="c0">”)</span>
                  <span>, a </span>
                  <span className="c0">
                    company incorporated and registered under the laws of British Virgin
                    Islands. These Terms constitute a binding and enforceable legal contract
                    between the Company and its Affiliates and subsidiaries worldwide and you,
                    an end user of the Services (herein referred as “
                  </span>
                  <span className="c0 c31">You</span>
                  <span className="c0">” or “</span>
                  <span className="c0 c31">User</span>
                  <span className="c0">
                    ”) in relation to the Services. You and the Company are referred to
                    separately as “
                  </span>
                  <span className="c0 c31">Party</span>
                  <span className="c0">” and collectively as “</span>
                  <span className="c0 c31">Parties</span>
                  <span className="c5 c0">”.</span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0">By accessing, registering, unlocking, using,</span>
                  <span className="c19 c0">&nbsp;</span>
                  <span className="c5 c0">
                    or clicking on the Services, and information made available by the Company
                    via the Website you hereby accept and agree to all the Terms set forth
                    herein.
                  </span>
                </p>
                <p className="c22 c3 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c38">
                  <span className="c5 c0">
                    You are strongly advised to carefully read these Terms as well as the
                    provisions detailed in our Privacy Policy prior to using the Website and
                    our Services. By using the Website and the Services in any capacity, you
                    agree that: (i) You have read and familiarized yourself with these Terms;
                    (ii) You understand these Terms; and (iii) You agree to be bound by these
                    Terms when using the Website. If You do not agree to these Terms, please
                    do not access or use the Website and the Services.{" "}
                  </span>
                </p>
                <p className="c9 c38">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c29">
                  <span className="c5 c0">
                    We reserve the right to modify or amend these Terms, the Website, or any
                    content on either one of the platforms from time to time, including for
                    security, legal, or regulatory reasons, as well as to reflect updates or
                    changes to the Services or functionality of the Website. You are advised
                    to check these Terms periodically to ensure that you are cognizant of the
                    current versions and comply with them. Users of the Website and the
                    Services are bound by these changes which will take immediate effect after
                    the revised versions of these Terms have been published on the Website or
                    mobile application. Through your continued use of or interaction with the
                    Website, the Services, tools, and information made available on these
                    platforms, you hereby agree to be bound by the provisions highlighted in
                    the subsequent versions.
                  </span>
                </p>
                <p className="c9 c29">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c29">
                  <span className="c5 c0">
                    We will provide a notification on the Website specifying that changes have
                    been made to these Terms whenever they occur. By accepting the
                    notification, You accept that we have provided you with sufficient notice
                    of any changes. You should seek professional advice regarding any possible
                    legal requirements you must comply with in relation to use of the Website,
                    and tools that related to participating in the Service provided by the
                    Company.
                  </span>
                </p>
                <hr style={{ pageBreakBefore: "always", display: "none" }} />
                <p className="c55 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-0" start={2}>
                  <li className="c22 c44 li-bullet-0">
                    <h1 style={{ display: "inline" }}>
                      <span>Definitions</span>
                    </h1>
                  </li>
                </ol>
                <p className="c16 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c20">
                  <span className="c0 c31">“ADAPAD” </span>
                  <span className="c0">
                    refers to the platform for Cardano Smart Chain Network, which is
                  </span>
                  <span className="c0 c31 c42">&nbsp;</span>
                  <span className="c0">
                    a platform for retail investment into tokens offered via IDO. Please find
                    further details at
                  </span>
                  <span className="c0 c31">&nbsp;</span>
                  <span className="c25">
                    <a
                      className="c17"
                      href="https://www.google.com/url?q=https://adapad.io&sa=D&source=editors&ust=1632074331113000&usg=AOvVaw2LjuhBfVnOd75fBYi-9OX5"
                    >
                      https://adapad.io
                    </a>
                  </span>
                  <span className="c5 c0">&nbsp;</span>
                </p>
                <p className="c9">
                  <span className="c1 c0" />
                </p>
                <p className="c20">
                  <span className="c0 c31">"Affiliates" </span>
                  <span className="c5 c0">
                    refers to in relation in any party, any other company which, directly or
                    indirectly, (i) is controlled by that party, (ii) controls that party, or
                    (iii) is under common control with that party, and in respect of the
                    Company, shall also include any fund, limited partnership or other
                    collective investment vehicle or other person which is managed or advised
                    by the Company’s team.{" "}
                  </span>
                </p>
                <p className="c9">
                  <span className="c1 c0" />
                </p>
                <p className="c20">
                  <span className="c0 c31">"AML" </span>
                  <span className="c5 c0">refers to Anti-Money Laundering.</span>
                </p>
                <p className="c22 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c29">
                  <span className="c0 c31">"Applicable Laws" </span>
                  <span className="c5 c0">
                    refers to acts, statutes, regulations, ordinances, treaties, guidelines,
                    and policies issued by governmental organizations or regulatory bodies,
                    including, but not limited to, the governing law stipulated under Laws of
                    the British Virgin Islands.
                  </span>
                </p>
                <p className="c9 c29">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c29">
                  <span className="c0 c31">“BRIDGE”</span>
                  <span className="c5 c0">
                    &nbsp;refers to the Services provided by the Company and its Affiliates in
                    Clause 6.
                  </span>
                </p>
                <p className="c22 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c20">
                  <span className="c0 c31">“BSCPAD” </span>
                  <span className="c0">
                    refers to the first platform for Binance Smart Chain Network, which is a
                    platform for retail investment into tokens offered via IDO. Please find
                    further details at{" "}
                  </span>
                  <span className="c25">
                    <a
                      className="c17"
                      href="https://www.google.com/url?q=https://bscpad.com/&sa=D&source=editors&ust=1632074331115000&usg=AOvVaw3HaqYgecxBnIAZ-_IcRIKO"
                    >
                      https://bscpad.com/
                    </a>
                  </span>
                  <span className="c5 c0">. </span>
                </p>
                <p className="c9">
                  <span className="c5 c0" />
                </p>
                <p className="c20">
                  <span className="c0 c31">"CFT" </span>
                  <span className="c5 c0">
                    refers to Combating the Financing of Terrorism.
                  </span>
                </p>
                <p className="c9">
                  <span className="c5 c0" />
                </p>
                <p className="c20">
                  <span className="c0 c31">"Confidential Information" </span>
                  <span className="c5 c0">
                    refers to any non-public, proprietary information or documents of or
                    related to the User or the Company (whether in writing, orally or by any
                    other means) by or on behalf of the User to the Company and which if
                    disclose in tangible or intangible form is marked confidential (including
                    in visual, oral, or electronic form) relating to Us or any other User that
                    was previously a User, which is provided or disclosed through the Company
                    (or to any employees or agents) in connection with the use or participate
                    in the Company Services.
                  </span>
                </p>
                <p className="c9">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0 c31">"Content"</span>
                  <span className="c0">
                    &nbsp;refers to all content generated by the Company, including logos,
                    identifying marks, images, illustrations, designs, icons, photographs,
                    videos, text, any written or multimedia materials, Services,
                    advertisements, software, code, data, files, archives, folders, or
                    available downloads on the TRONPAD Platform.
                  </span>
                  <span className="c1 c0">&nbsp;</span>
                </p>
                <p className="c3 c9">
                  <span className="c1 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0 c31">“Cookie” </span>
                  <span className="c5 c0">
                    refers to the small text files that are placed on your computer by the
                    website that you visit. They are widely used in order to make websites
                    work, or work more efficiently, as well as to provide information to the
                    owner of the site.
                  </span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0 c31">“CrossSwap”</span>
                  <span className="c0">
                    &nbsp;refers to the cross chain swap designed to unify the trading service
                    on one platform. Please find further details at{" "}
                  </span>
                  <span className="c25">
                    <a
                      className="c17"
                      href="https://www.google.com/url?q=https://crossswap.com/&sa=D&source=editors&ust=1632074331117000&usg=AOvVaw1kgU4CtvvLe5aHcLx8-fK2"
                    >
                      https://crossswap.com/
                    </a>
                  </span>
                  <span className="c5 c0">. &nbsp;</span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0 c31">“CrossWallet”</span>
                  <span className="c0">
                    &nbsp;refers to the non-custodian wallet service that holds any digital
                    asset you prefer to hold, trade, or send, from tokens to NFTs, which it
                    works seamlessly move between different blockchain and works on any
                    device, either mobile or web. Please find further details at{" "}
                  </span>
                  <span className="c25">
                    <a
                      className="c17"
                      href="https://www.google.com/url?q=https://crosswallet.app/&sa=D&source=editors&ust=1632074331117000&usg=AOvVaw0Aj4Jp3xbf4t1LWoFDtGvc"
                    >
                      https://crosswallet.app/
                    </a>
                  </span>
                  <span className="c5 c0">.</span>
                </p>
                <p className="c9 c3">
                  <span className="c1 c0" />
                </p>
                <p className="c20">
                  <span className="c0 c31">“ETHPAD” </span>
                  <span className="c0">
                    refers to the platform for Ethereum Smart Chain Network, which is a
                    platform for retail investment into tokens offered via IDO. Please find
                    further details at{" "}
                  </span>
                  <span className="c25">
                    <a
                      className="c17"
                      href="https://www.google.com/url?q=https://ethpad.network/&sa=D&source=editors&ust=1632074331118000&usg=AOvVaw1gTRGjiKz0a5EQw-pJd4A5"
                    >
                      https://ethpad.network/
                    </a>
                  </span>
                  <span className="c5 c0">.</span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0">“</span>
                  <span className="c0 c31">FCFS Round</span>
                  <span className="c5 c0">
                    ” refers to First Come First Serve Round of the the Company fundraising
                    project token allocation for the unsold IDO project tokens left from the
                    TRONPAD Tiered System.
                  </span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0 c31">“IDO” </span>
                  <span className="c5 c0">
                    refers to Initial DEX Offerings or Initial Decentralized Exchange
                    Offerings.
                  </span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20">
                  <span className="c0 c31">“KCCPAD” </span>
                  <span className="c0">
                    refers to the platform for KuCoin Community Chain Network, which is a
                    platform for retail investment into tokens offered via IDO. Please find
                    further details at{" "}
                  </span>
                  <span className="c25">
                    <a
                      className="c17"
                      href="https://www.google.com/url?q=https://kccpad.com/&sa=D&source=editors&ust=1632074331119000&usg=AOvVaw2QftBzfS_ErFVoma3te3o-"
                    >
                      https://kccpad.com/
                    </a>
                  </span>
                  <span className="c0">. </span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0 c31">“Lottery Ticket” </span>
                  <span className="c5 c0">
                    refers to an allocation granted to the user by participating in either of
                    the lottery based allocation rounds for an access to an IDO.
                  </span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0 c31">“Partners’’</span>
                  <span className="c5 c0">
                    &nbsp;refers to the Company, BSCPAD, ETHPAD, KCCPAD, ADAPAD, NFTLaunch,
                    CrossWallet, CrossSwap, KCCSwap, and WagyuSwap
                  </span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0 c31">"Prohibited Jurisdictions" </span>
                  <span className="c0">specifically refer </span>
                  <span className="c5 c0">
                    to the United States of America (including its territories, and
                    dependencies, and any state of the United States), Albania, Barbados,
                    Botswana, Burkina Faso, Cambodia, Democratic People’s Republic of Korea,
                    Haiti, Iran, Jamaica, Morocco, Myanmar (Burma), Nicaragua, Pakistan,
                    Panama, Philippines, Senegal, South Sudan, Syria, Thailand, Uganda, and
                    Yemen, Zimbabwe.
                  </span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span>“</span>
                  <span className="c31">Project</span>
                  <span className="c5 c0">
                    ” refers to the Company’s project being launched for IDO event on TRONPAD
                    Platform.
                  </span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c3 c20">
                  <span className="c0">“</span>
                  <span className="c0 c31">Services</span>
                  <span className="c0">
                    ” refer to the services provided to the Users through the Website, which
                    include the accessibility to the new IDO token offered from each IDO
                    fundraising project, Staking service and other relevant services available
                    through the Platform and all free trials and beta services made available
                    by{" "}
                  </span>
                  <span>the Company, </span>
                  <span className="c5 c0">which may be subject to periodic revision.</span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0">“</span>
                  <span className="c0 c31">Staking</span>
                  <span className="c0">&nbsp;</span>
                  <span className="c0 c31">or Stake</span>
                  <span className="c0">
                    ” refers to the staking or deposit service in which the Users can delegate
                    or deposit the User’s $
                  </span>
                  <span>TRONPAD</span>
                  <span className="c5 c0">
                    &nbsp;Token in exchange for an opportunity to participate in the Services,
                    and IDO Project.
                  </span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0">“</span>
                  <span className="c0 c31">$TRONPAD Tokens</span>
                  <span className="c5 c0">
                    ” refers to a blockchain - based token which is issued, stored,
                    transferred, transacted on the TRON Network. TRONPAD Token will be issued
                    as a TRC-20. To gain access to Lottery Tiers, and/or Guaranteed Allocation
                    rounds for the IDO project, User needs to stake $TRONPAD Token on TRONPAD
                    Platform.{" "}
                  </span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0">“</span>
                  <span className="c0 c31">TRC-20 Token</span>
                  <span className="c5 c0">
                    ” refers to a blockchain-based token which is issued, stored, transferred,
                    and transacted on the TRON Network.
                  </span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0 c31">“TRON” </span>
                  <span className="c5 c0">refers to the Tron Blockchain Protocol. </span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0">“</span>
                  <span className="c0 c31">TRONPAD Tiered System</span>
                  <span className="c5 c0">
                    ” refers to a tiered system which TRONPAD Platform offers to the User who
                    wishes to participate in the IDO fundraising projects. Each IDO project
                    token offering will be split into three allocations consisting of (i)
                    Public White list Lottery, (ii) Lottery Tiers, and (iii) Guaranteed
                    Allocation Tiers. Please find further details regarding the tiers in
                    Clause 5.3.
                  </span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0 c31">“TRONPAD Platform” </span>
                  <span className="c0">
                    refers to the Website or platform that facilitates token swaps, and where
                    the Project is officially launched, and makes available its native Tokens
                    (“
                  </span>
                  <span className="c0 c31">Pool</span>
                  <span className="c5 c0">
                    ”) to be distributed to Users in the allocation rounds.{" "}
                  </span>
                </p>
                <p className="c9 c3">
                  <span className="c1 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0 c31">“NFTLaunch”</span>
                  <span className="c0">
                    &nbsp;refers to the platform for deflationary NFT Launchpad which is the
                    first platform to offer zero gas wars, fair distribution and free NFT
                    airdrops of new and upcoming NFT projects. Please find further details at{" "}
                  </span>
                  <span className="c25">
                    <a
                      className="c17"
                      href="https://www.google.com/url?q=https://nftlaunch.network/&sa=D&source=editors&ust=1632074331123000&usg=AOvVaw0zZtGSdTy99QVKQoKE_YbS"
                    >
                      https://nftlaunch.network/
                    </a>
                  </span>
                  <span className="c5 c0">. </span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0 c31">“WagyuSwap” </span>
                  <span className="c0">
                    refers to the Decentralized Exchange build on Velas Blockchain, an
                    entirely new blockchain adapted from Solana which guaranteed full EVM
                    compatibility.
                  </span>
                  <span className="c42 c0 c31">&nbsp;</span>
                  <span className="c0">Please find further details at </span>
                  <span className="c25">
                    <a
                      className="c17"
                      href="https://www.google.com/url?q=https://wagyuswap.app/&sa=D&source=editors&ust=1632074331124000&usg=AOvVaw2EconjmbGCzgcWinvljjUh"
                    >
                      https://wagyuswap.app/
                    </a>
                  </span>
                  <span className="c5 c0">&nbsp;.</span>
                </p>
                <p className="c9 c3">
                  <span className="c5 c0" />
                </p>
                <p className="c20 c3">
                  <span className="c0 c31">"Wallet(s)" </span>
                  <span className="c5 c0">
                    refers to any digital wallet which is compatible with TRON Network, such
                    as TronLink.
                  </span>
                </p>
                <p className="c16 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-0" start={3}>
                  <li className="c22 c44 li-bullet-0">
                    <h1 style={{ display: "inline" }}>
                      <span className="c1 c0">General Provisions</span>
                    </h1>
                  </li>
                </ol>
                <p className="c22 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_8-1 start" start={1}>
                  <li className="c22 c69 li-bullet-1">
                    <span className="c5 c0">Contractual Relationship</span>
                  </li>
                </ol>
                <p className="c22 c38 c67">
                  <span className="c5 c0">
                    These Terms constitute a valid and binding agreement between You and the
                    Company. The binding obligations stipulated in these Terms are
                    enforceable.
                  </span>
                </p>
                <p className="c8 c13 c38">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_8-1" start={2}>
                  <li className="c22 c81 li-bullet-2">
                    <span className="c5 c0">Revision and Amendments</span>
                  </li>
                </ol>
                <p className="c22 c33 c29">
                  <span className="c0">
                    The Company reserves the right to revise, amend, or update any clauses and
                    provisions stipulated in these Terms in its sole discretion at any time.
                    The Company will notify the revision or amendment of such clauses or
                    provisions by updating these Terms and specify the{" "}
                  </span>
                  <span className="c0 c79">'Last Revised Date</span>
                  <span className="c5 c0">
                    ' displayed on a page of Terms and Conditions. Any revisions and updates
                    on these Terms will be automatically effective upon their publication on
                    the Platform, which includes the Website. Therefore, your continuity of
                    accessing or using TRONPAD Platform, and Services will be deemed that you
                    agree to have read, understood, and accepted all revised terms and
                    provisions. If you do not agree on any revised or updated terms and
                    provisions, you should immediately stop accessing or using our Services.
                    You are encouraged to frequently and carefully review these Terms to
                    ensure that you understand these Terms.
                  </span>
                </p>
                <p className="c22 c33 c13 c29">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_8-1" start={3}>
                  <li className="c22 c43 li-bullet-3">
                    <span className="c5 c0">Privacy Policy</span>
                  </li>
                </ol>
                <p className="c22 c33 c38">
                  <span className="c5 c0">
                    You acknowledge and confirm that you have read, understood, and agreed to
                    provisions stipulated in the Privacy Policy, which will explain how the
                    Company treats your information and protects your privacy when accessing
                    or using the TRONPAD Platform.{" "}
                  </span>
                </p>
                <p className="c22 c33 c13 c38">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c33 c38">
                  <span className="c5 c0">
                    By using TRONPAD Platform, you hereby agree that the Company may collect,
                    use your information, and anonymised data pertaining to your use of the
                    Services for analytics, trends’ identification, and purpose of statistics
                    to further enhance the effectiveness and efficiency of the Services.{" "}
                  </span>
                </p>
                <p className="c22 c33 c13 c38">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c33 c38">
                  <span className="c5 c0">
                    You hereby expressly authorise the Company to disclose any and all
                    information relating to you in the Company’s possession to any law
                    enforcement or government officials upon the request by the court order.
                  </span>
                </p>
                <p className="c22 c3 c13 c27">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_8-1" start={4}>
                  <li className="c22 c45 c70 li-bullet-4">
                    <span className="c5 c0">Links to and from the Website </span>
                  </li>
                </ol>
                <p className="c8 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_8-2 start" start={1}>
                  <li className="c11 li-bullet-5">
                    <span className="c0">
                      You may, through hypertext or other computer links, gain access form the
                      Website to websites operated or made available, or otherwise licensed by
                      persons other than us (“
                    </span>
                    <span className="c0 c31">Third Party Services</span>
                    <span className="c5 c0">
                      ”). Such hyperlinks are provided for your convenience.
                    </span>
                  </li>
                </ol>
                <p className="c22 c27 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_8-2" start={2}>
                  <li className="c22 c24 c3 li-bullet-5">
                    <span className="c5 c0">
                      A link from Third Party Services does not mean that the Company endorses
                      or approves the content on such website or does not mean the Company is
                      an operator of that website. You understand that you are solely
                      responsible for determining the extent to which you may use or rely upon
                      any content at any other Third-Party Services websites which you have
                      accessed from the Website. The Company has no control over the content
                      of these sites or resources and accept no reasonability for them or for
                      any loss or damage that may arise from your use of them.{" "}
                    </span>
                  </li>
                </ol>
                <p className="c22 c27 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_8-2" start={3}>
                  <li className="c22 c3 c24 li-bullet-5">
                    <span className="c5 c0">
                      The Company assumes no responsibility for the use of, or inability to
                      use, any Third Party Services’ software, other materials, or contents
                      posted and/or uploaded on such website and we will have no liability
                      whatsoever to any person or entity for any inaccuracy or incompleteness
                      of such third-party content. All intellectual property rights in and to
                      Third Party Services are property of the respective third parties.
                    </span>
                  </li>
                </ol>
                <p className="c22 c27 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_8-1" start={5}>
                  <li className="c2 li-bullet-3">
                    <span className="c5 c0">
                      Disclaimer for Accessibility of the Website and the Services{" "}
                    </span>
                  </li>
                </ol>
                <p className="c22 c27 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_3-2 start" start={1}>
                  <li className="c22 c48 c84 li-bullet-6">
                    <span className="c5 c0">
                      The Website merely facilitates the Users accessibility to the IDO
                      Project tokens and does not provide you with any warranty or
                      representation whatsoever regarding its quality, value, specification,
                      fitness for the purpose, completeness or accuracy of its technology or
                      infrastructure of the tokens.
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_3-2" start={2}>
                  <li className="c11 li-bullet-5">
                    <span className="c5 c0">
                      The Company will make all commercially reasonable attempts to facilitate
                      information about the Project on the TRONPAD Platform. However, you
                      hereby acknowledge that the Company does not guarantee the accuracy,
                      timeliness, or completeness of such information, and does not provide
                      any warranty in connection with your use or reliance on such
                      information. You agree that your use of the Project information will be
                      at all your own risk. The Company will not be liable to you in any
                      manner for the termination, interruption, delay, or inaccuracy of any
                      Project information launched on the TRONPAD Platform.
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_3-2" start={3}>
                  <li className="c11 li-bullet-7">
                    <span className="c5 c0">
                      To the extent permitted by applicable laws, the Website and the
                      Company’s Services are provided on an ‘AS IS’ and ‘AS AVAILABLE’ basis.
                      The Company does not warrant that the features, and functions contained
                      in the Website and the Services will satisfy your preferences You hereby
                      agree and acknowledge that your access and use of the Website and the
                      Services are at your own risk, and you will be liable for any
                      responsibility, consequences that may arise out of or in connection with
                      the usage or accessibility of the Website and the Services. You
                      expressly agree that the Company will have absolutely no liability in
                      this regard.
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_3-2" start={4}>
                  <li className="c11 li-bullet-7">
                    <span className="c5 c0">
                      You hereby agree and acknowledge that the Website may contain links to a
                      Third Party website or Third Party Services that are not owned or even
                      controlled by the Company, thus the Company will hold no responsibility
                      for the content or services, goods, or activities provided by such
                      third-party website. You further agree that the Company has no liability
                      directly or indirectly for any damage, loss, or consequence arising out
                      of or in connection with the usage or reliance of any content, material,
                      services available through the third-party website.
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_3-2" start={5}>
                  <li className="c11 li-bullet-5">
                    <span className="c5 c0">
                      The Company reserves the right to limit the availability of the Website
                      to any person, geographic area, or jurisdiction we so desire and/or
                      terminate your access to and use of the Website and the Services, at any
                      time and in our sole discretion.
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_3-2" start={6}>
                  <li className="c11 li-bullet-7">
                    <span className="c5 c0">
                      The Company may, at our sole discretion, impose limits or restrictions
                      on the use you make of the Website. Further, for commercial, security,
                      technical, maintenance, legal or regulatory reasons, or due to any
                      breach of these Terms, we may withdraw the Website or your access to the
                      Website and the Services at any time and without notice to You.
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_8-1" start={6}>
                  <li className="c2 li-bullet-3">
                    <span className="c5 c0">Information </span>
                  </li>
                </ol>
                <p className="c8 c29">
                  <span className="c5 c0">
                    You acknowledge that you are solely responsible for any submissions of all
                    contents, remarks, suggestions, ideas, materials, feedbacks, or other
                    information, including bug reports in relation to the Services provided
                    through the Website including any submission to our social media platforms
                    such as Twitter and Telegram, and you, not us, have full responsibility
                    for such submissions, including their accuracy, legality, reliability,
                    appropriateness, originality, and copyright. We shall reply on the
                    information you have provided and will not verify it. Notwithstanding the
                    foregoing, we have the right to refuse to post, remove, edit, or abridge
                    any submission for any reason and to freely use, copy, disclose, publish,
                    display, or exploit such submission as we deem necessary without any
                    payment of royalty, acknowledgement prior to consent, we may retain copies
                    of all information materials relevant to the Service.
                  </span>
                </p>
                <p className="c22 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-0" start={4}>
                  <li className="c22 c44 li-bullet-0">
                    <h1 style={{ display: "inline" }}>
                      <span className="c1 c0">Intellectual Property</span>
                    </h1>
                  </li>
                </ol>
                <p className="c22 c13">
                  <span className="c1 c0" />
                </p>
                <ol className="c6 lst-kix_list_6-1 start" start={1}>
                  <li className="c4 c3 li-bullet-8">
                    <span className="c5 c0">
                      All present and future copyright, title, interests in and to the
                      Services, registered and unregistered trademarks, design rights,
                      unregistered designs, database rights and all other present and future
                      intellectual property rights and rights in the nature of intellectual
                      property rights that exist in or in relation to the use and access of
                      the Website and Services are owned by or otherwise licensed to the
                      Company. Subject to your compliance with these Terms, the Company grants
                      you a non-exclusive, non-sub license, and any limited license to merely
                      use or access the Website and the Services in the permitted hereunder.
                    </span>
                  </li>
                </ol>
                <p className="c8 c13 c29">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_6-1" start={2}>
                  <li className="c2 c29 li-bullet-3">
                    <span className="c5 c0">
                      Except as expressly stated in these Terms, nothing in these Terms should
                      be construed as conferring any right in or license to the Company’s or
                      any other third party’s intellectual rights.
                    </span>
                  </li>
                </ol>
                <p className="c8 c29">
                  <span className="c5 c0">&nbsp; </span>
                </p>
                <ol className="c6 lst-kix_list_6-1" start={3}>
                  <li className="c2 c77 li-bullet-3">
                    <span className="c5 c0">
                      If and to the extent that any such intellectual property rights are
                      vested in you by operation of law or otherwise, you agree to do any and
                      all such acts and execute any and all such documents as we may
                      reasonably request in order to assign such intellectual property rights
                      back to us.
                    </span>
                  </li>
                </ol>
                <p className="c8 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_6-1" start={4}>
                  <li className="c2 c29 li-bullet-3">
                    <span className="c5 c0">
                      You agree and acknowledge that all Website content must not be copied or
                      reproduced, modified, redistributed, used, created for derivative works,
                      or otherwise dealt with for any other reason without being granted a
                      written consent from the Company.
                    </span>
                  </li>
                </ol>
                <p className="c8 c13 c29">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_6-1" start={5}>
                  <li className="c2 c48 li-bullet-3">
                    <span>Third parties participating on the Website may permit </span>
                    <span className="c0">the Company </span>
                    <span>
                      to utilise trademarks, copyrighted material, and other Intellectual
                      Property associated with their businesses.{" "}
                    </span>
                    <span className="c5 c0">
                      The Company will not warrant or represent that the content of the
                      Website does not infringe the rights of any third party.
                    </span>
                  </li>
                </ol>
                <p className="c22 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-0" start={5}>
                  <li className="c22 c44 li-bullet-9">
                    <h1 style={{ display: "inline" }}>
                      <span>Participation in the </span>
                      <span className="c0">TRONPAD</span>
                      <span className="c1 c0">&nbsp;Services</span>
                    </h1>
                  </li>
                </ol>
                <p className="c16 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_14-1 start" start={1}>
                  <li className="c4 li-bullet-10">
                    <span className="c5 c0">
                      In order to avail access to the Services or participate in the TRONPAD
                      Platform, you are required to go through KYC process as requested by
                      each IDO fundraising project. Please refer to the Identification
                      Verification through KYC/AML/CFT Process in Clause 6 for further
                      information.{" "}
                    </span>
                  </li>
                </ol>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_14-1" start={2}>
                  <li className="c2 c3 li-bullet-1">
                    <span className="c0">
                      In term of Staking, you acknowledge and agree that the Company
                    </span>
                    <span>
                      &nbsp;provides Staking Service to Users for purposes of delegating the
                      User’s{" "}
                    </span>
                    <span className="c0">$TRONPAD Token</span>
                    <span>
                      s in exchange for an opportunity to participate in the IDO Project being
                      launched on the Platform.{" "}
                    </span>
                    <span className="c5 c0">
                      You will provide the Staking instruction to the TRONPAD Platform. Once
                      the Staking instruction is received, the system will record the
                      instruction and you will not be able to cancel or edit the Staking
                      instruction. When the TRONPAD Platform connect with your wallet, you
                      will see your tier based on the numbers of $TRONPAD Tokens held in your
                      wallet. The level of tier will affect the numbers of lottery tickets,
                      and the winning chance to purchase the IDO Project’s tokens. The Company
                      has neither responsibility nor liability in relation to the allocation
                      of IDO tokens. Since it will be entirely subject to the IDO fundraising
                      project creator. &nbsp;
                    </span>
                  </li>
                </ol>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c8 c3">
                  <span className="c5 c0">
                    The Company reserves the right to reject your participation in Staking, if
                    you fail to fulfil to satisfy the identity verification requirements
                    through the KYC process or commit any other suspicious activity while
                    participating in the Services provided by the Company.
                  </span>
                </p>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_14-1" start={3}>
                  <li className="c2 c3 li-bullet-3">
                    <span className="c5 c0">
                      Tokens being stacked are limited to $TRONPAD Tokens only. Once you
                      initiate Staking, you will be put in the allocation rounds of each IDO
                      Project by using ‘TRONPAD Tiered System’ as shown below.{" "}
                    </span>
                  </li>
                </ol>
                <p className="c22 c3 c45 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c8 c3">
                  <span className="c5 c0">
                    Each IDO Project Token Offering will be split into the following
                    allocations:
                  </span>
                </p>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_9-2 start" start={1}>
                  <li className="c22 c46 li-bullet-11">
                    <span className="c5 c0">
                      A Public White List Lotteries which is open to anyone who can complete
                      tasks required by each Project creator. In this allocation round, User
                      is not required to stake any $TRONPAD Tokens on TRONPAD Platform. The
                      winning users will be contacted by the Project creator to claim their
                      IDO Project token allocation.
                    </span>
                  </li>
                </ol>
                <p className="c22 c48 c13 c54">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_9-2" start={2}>
                  <li className="c22 c46 li-bullet-12">
                    <span className="c5 c0">&nbsp;Lottery Tiers or “Earth Round”</span>
                  </li>
                </ol>
                <p className="c22 c48 c13 c54">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c48 c54">
                  <span className="c5 c0">
                    Earth Round - User needs to stake 15,000 $TRONPAD Tokens on the TRONPAD
                    Platform, to receive one (1) Lottery Ticket that will be distributed to
                    such User. User is also required to stake Tokens at least 3 hours before
                    the allocation round of each IDO Project opens.{" "}
                  </span>
                </p>
                <p className="c22 c48 c13 c54">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c48 c54">
                  <span className="c5 c0">
                    The winning Users from these rounds will be able to purchase their IDO
                    Project’s tokens allocation.{" "}
                  </span>
                </p>
                <p className="c22 c48 c13 c54">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_9-2" start={3}>
                  <li className="c22 c46 li-bullet-12">
                    <span className="c5 c0">
                      &nbsp;Guaranteed Allocation Tier is the higher round to guarantee the
                      token allocation. This Tier consists of “Moon Round”, “Mars Round” and
                      “Sun Round” as follows:
                    </span>
                  </li>
                </ol>
                <p className="c22 c48 c13 c54">
                  <span className="c5 c0" />
                </p>
                <ul className="c6 lst-kix_list_11-4 start">
                  <li className="c21 li-bullet-13">
                    <span className="c5 c0">
                      Moon Round - User needs to stake 50,000 $TRONPAD Tokens on the TRONPAD
                      Platform and the Staking length is not less than 3 hours before
                      allocation round of each IDO Project opens. Pool Weight is 10.
                    </span>
                  </li>
                  <li className="c21 li-bullet-13" id="h.30j0zll">
                    <span className="c5 c0">
                      Mars Round - User needs to stake 200,000 $TRONPAD Tokens on TRONPAD
                      Platform and the Staking length is not less than 3 hours before
                      allocation round of each IDO Project opens. Pool Weight is 45.{" "}
                    </span>
                  </li>
                  <li className="c21 li-bullet-13">
                    <span className="c5 c0">
                      Sun Round - User needs to stake 500,000 $TRONPAD Tokens on the TRONPAD
                      Platform and the Staking length is not less than 3 hours before
                      allocation round of each IDO Project opens. Pool Weight is 70.
                    </span>
                  </li>
                </ul>
                <p className="c13 c18">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c48 c54">
                  <span className="c5 c0">
                    Users Staking tokens in this Guaranteed Allocation Tier will be confirmed
                    that they can purchase their guaranteed IDO token amounts based on the
                    numbers of $TRONPAD Tokens they stake. No need to wait for a winning
                    chance to purchase the tokens.{" "}
                  </span>
                </p>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c8 c3">
                  <span className="c5 c0">
                    In the conclusion of the allocation round of IDO based on the TRONPAD
                    Tiered System, you will be able to join the FCFS Round for purchasing the
                    unsold IDO tokens.{" "}
                  </span>
                </p>
                <p className="c22 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_14-1" start={4}>
                  <li className="c2 c3 li-bullet-14">
                    <span className="c5 c0">
                      You hereby acknowledge and agree that the estimated IDO tokens or reward
                      yield displayed on the TRONPAD Platform for each IDO fundraising project
                      is an estimation. The actual amount of IDO tokens reward you will
                      receive may not match or may lower than your estimation which will be
                      subject to the reward compression or expression. You further hereby
                      acknowledge that the Company cannot promise or guarantee the certain
                      amount of the reward receiving from Staking your $TRONPAD Tokens in
                      order to engage in the IDO fundraising project event. You shall go
                      through and study the medium article of each IDO Project and fully
                      understand the significant information and conditions prior to
                      participate such IDO event such as the allocation date, the underlying
                      technology, the features and functions of IDO project tokens.
                    </span>
                  </li>
                </ol>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_14-1" start={5}>
                  <li className="c2 c3 li-bullet-15">
                    <span className="c5 c0">
                      In the event that you wish to unstake/withdraw your $TRONPAD Tokens and
                      gain the reward, you will provide the unstaking instruction to the
                      TRONPAD Platform. You acknowledge and agree that when you unstake your
                      $TRONPAD Tokens, the delivery of such $TRONPAD Tokens into your wallet
                      will take 7 days and the timing may vary based on time of day of the
                      unstake/withdraw instruction and execution. The amount of your unstaking
                      $TRONPAD Tokens will not count towards your tier level for upcoming IDO
                      fundraising projects.{" "}
                    </span>
                  </li>
                </ol>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c8 c3">
                  <span className="c5 c0">
                    The Company will levy a withdrawing fee or minimum transaction which will
                    vary and be subject to the amendment only at its sole discretion of the
                    Company periodically. The Company will not be liable for any loss caused
                    or alleged caused by timing differences and economic lost associated with
                    the actual delivery of the $TRONPAD Tokens.{" "}
                  </span>
                </p>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_14-1" start={6}>
                  <li className="c2 c3 li-bullet-15">
                    <span className="c5 c0">
                      The Company reserves the right to amend any terms related to any
                      specific Staking program at any time in its sole discretion. The Company
                      will not be liable for any losses due to your misunderstanding of the
                      Staking program terms and changes to such term’s rules.{" "}
                    </span>
                  </li>
                </ol>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c16 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-0" start={6}>
                  <li className="c22 c44 li-bullet-9">
                    <h1 style={{ display: "inline" }}>
                      <span className="c1 c0">BRIDGE Service</span>
                    </h1>
                  </li>
                </ol>
                <p className="c16 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-1 start" start={1}>
                  <li className="c4 c3 li-bullet-8">
                    <span className="c5 c0">
                      Upon completion of the registration and identity verification for your
                      Wallet, you may use the Company’s Services, including but not limited
                      to, Staking, and accessing to other information released by the Company,
                      participating in any launchpad, token swaps, bridge, etc., in accordance
                      with the provision of the bridge function.{" "}
                    </span>
                  </li>
                </ol>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c8 c3">
                  <span className="c5 c0">
                    Under the bridge function, you will be required to conduct the following
                    provisions for the purpose of double verification and convenience crossing
                    between two platforms:
                  </span>
                </p>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ul className="c6 lst-kix_list_2-0 start">
                  <li className="c14 c3 c51 li-bullet-16">
                    <span className="c5 c0">
                      Connect your Wallet with the BSCPAD wallet; and
                    </span>
                  </li>
                  <li className="c14 c51 c3 li-bullet-17">
                    <span className="c5 c0">
                      Synchronize the Company KYC with the BSCPAD;
                    </span>
                  </li>
                </ul>
                <p className="c14 c3 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c8 c3">
                  <span className="c19 c0">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span className="c5 c0">
                    You hereby acknowledge and agree that the Company will not be liable for
                    any damage, loss, consequence, or devaluation of the assets held in your
                    both wallets i.e., BSCPAD wallet and the Wallet, which may occur as a
                    result or in connection with any matter related to disruptive function,
                    broken internet system or any failure.
                  </span>
                </p>
                <p className="c3 c13 c14">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-1" start={2}>
                  <li className="c4 c3 li-bullet-8">
                    <span className="c5 c0">
                      In term of KYC synchronize, you understand and acknowledge that you
                      agree to share your KYC/AML/CFT information among our Partners for the
                      purpose of identity verification through KYC/AML/CFT process. Therefore,
                      you hereby agree that when you use the Company’s synchronize service,
                      you will inform the Company for any KYC/AML/CFT information changed (if
                      any).
                    </span>
                  </li>
                </ol>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-1" start={3}>
                  <li className="c4 c3 li-bullet-18">
                    <span className="c5 c0">
                      Upon sending an instruction of the Company’s service for KYC
                      synchronize, your account will be immediately updated to reflect the KYC
                      profile, and your KYC profile will be included in Partners’ data to
                      match the identity verification through KYC/AML/CFT requirement. Once
                      the instruction is executed, your account will be updated to reflect
                      that the instruction has been fully match and passed the KYC
                      verification and you are ready to experience the Company’s service. The
                      instruction will remain uncompleted until it is fully filled with the
                      wallet address. To provide a wallet address, you authorized our Partners
                      to confirm and temporarily control the data transfer to the Company.
                    </span>
                  </li>
                </ol>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-1" start={4}>
                  <li className="c4 c3 li-bullet-19">
                    <span className="c5 c0">
                      You are only allowed one time to synchronize KYC/AML/CFT to your BSCPAD
                      address for BSCPAD. Double check the address input in the box, whether
                      it is the BSCPAD address showing in your BSCPAD wallet. If you need to
                      synchronize again or update any information, please contact the
                      Company’s supporting team, detail provided in Clause 16.
                    </span>
                  </li>
                </ol>
                <p className="c34 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-1" start={5}>
                  <li className="c4 c3 li-bullet-20">
                    <span className="c5 c0">
                      Unless otherwise specified by the Company, to conduct BRIDGE, you must
                      complete the identification verification through KYC/AML/CFT process or
                      passed the KYC synchronized from BSCPAD for your account on TRONPAD
                      Platform. You acknowledge and agree that:
                    </span>
                  </li>
                </ol>
                <p className="c34 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_4-0 start" start={1}>
                  <li className="c12 c3 li-bullet-21">
                    <span className="c5 c0">
                      You fully understand the swapping process, including but not limited to
                      the risk or major fluctuation of Digital Asset in swapping, and the risk
                      of exacerbated adverse outcome when leverage is used;
                    </span>
                  </li>
                </ol>
                <p className="c22 c68 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_4-0" start={2}>
                  <li className="c12 c3 li-bullet-22">
                    <span className="c5 c0">
                      You have sufficient technological knowledge and experience and the
                      capacity to understand the process and agree to independently assume all
                      the risks arising from the BRIDGE service;
                    </span>
                  </li>
                </ol>
                <p className="c34 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_4-0" start={3}>
                  <li className="c3 c12 li-bullet-16">
                    <span className="c0">
                      You agree and authorized the Company to take various reasonable measures
                      in its discretion (including but not limited to blocking or freezing the
                      swapping requests under specific circumstances) in accordance with the
                      Company’s
                    </span>
                    <span className="c19 c0">&nbsp;</span>
                    <span className="c5 c0">
                      decision to protect the legitimate interests of you, the Company itself
                      and other users.
                    </span>
                  </li>
                </ol>
                <p className="c22 c3 c68">
                  <span className="c5 c0">&nbsp;</span>
                </p>
                <ol className="c6 lst-kix_list_16-1" start={6}>
                  <li className="c4 c3 li-bullet-8">
                    <span className="c0">
                      Prior to conducting the BRIDGE service for swap your asset, in
                      accordance with type of asset swapping, you shall provide the amount for
                      swapping that shall comply with the daily limit (show on the BRIDGE
                      page)
                    </span>
                    <span className="c0 c63 c65">.</span>
                    <span className="c0">
                      &nbsp;Unless otherwise specified by the Company, to conduct BRIDGE, you
                      agree to keep enough asset in your{" "}
                    </span>
                    <span>Wallet</span>
                    <span className="c5 c0">
                      , as required by engaging in the swapping process. Failure to keep
                      enough assets may result in cancellation of the swapping request.
                    </span>
                  </li>
                </ol>
                <p className="c22 c3 c13 c83">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-0" start={7}>
                  <li className="c22 c44 li-bullet-23">
                    <h1 style={{ display: "inline" }}>
                      <span className="c1 c0">
                        Identity Verification through KYC/AML/CFT Process{" "}
                      </span>
                    </h1>
                  </li>
                </ol>
                <p className="c13 c16">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_10-1 start" start={1}>
                  <li className="c4 c3 li-bullet-24">
                    <span className="c0">
                      The Company is a permissionless and fully decentralised platform for
                      token swaps. As a software development company, the Company has no role
                      in enforcing KYC/AML/CFT by default, however, we have a mandatory
                      requirement for Identification Verification KYC/AML/CFT tools for the
                      IDO fundraising companies using the Company to enforce on their users,
                      which IDO companies have to comply with the mandatory. The Company is
                      implementing KYC/AML/CFT tools into our launchpad through{" "}
                    </span>
                    <span className="c25">
                      <a
                        className="c17"
                        href="https://www.google.com/url?q=https://kycaid.com/&sa=D&source=editors&ust=1632074331137000&usg=AOvVaw3pzxisMl9yQiOMxd-LDJBL"
                      >
                        https://kycaid.com/
                      </a>
                    </span>
                    <span className="c5 c0">. </span>
                  </li>
                </ol>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_10-1" start={2}>
                  <li className="c2 c3 li-bullet-3">
                    <span className="c5 c0">
                      The use of KYC/AML/CFT tools on the Company by IDO fundraising companies
                      using the Services on TRONPAD Platform is not the discretion of said
                      entitles, and they must require you to complete KYC verification process
                      before participating in any IDO fundraising project events.{" "}
                    </span>
                  </li>
                </ol>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_10-1" start={3}>
                  <li className="c2 c3 li-bullet-3">
                    <span className="c5 c0">
                      Although the Company makes no warranty as to the merit, legality, or
                      juridical nature of any IDO token, we nonetheless understand the need of
                      IDO fundraising projects to require KYC/AML/CFT Verification on their
                      token sale participants of Users. Therefore, the Company reserves the
                      right:
                    </span>
                  </li>
                </ol>
                <p className="c34 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_15-2 start" start={1}>
                  <li className="c22 c35 li-bullet-1">
                    <span className="c0">
                      at any time, to ask for your personal information, Name-Surname,
                      Birthday,-e- mail address, nationality, location, government
                      identification number
                    </span>
                    <span className="c0 c19">&nbsp;</span>
                    <span className="c5 c0">
                      (Identification Card/Passport number and Date of Identification
                      Card/Passport issuing), telegram username, TRONPAD wallet address, and
                      any KYC documentation with the liveness test that it deems necessary to
                      determine the identity and location of a User, and reserves the right to
                      restrict Service and payment until identity is sufficiently determined;
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_15-2" start={2}>
                  <li className="c11 li-bullet-7">
                    <span className="c5 c0">
                      The liveness test shall mean taking a photo of your government
                      identification with your phone or camera. Then, also take a photo of
                      yourself (selfie of your face) holding your ID document and the paper
                      you wrote on next to your face (not covering your face), the piece of
                      paper shall write down the (1) TRONPAD, (2) the current date, and (3)
                      the last 4 characters of your wallet address”.{" "}
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_15-2" start={3}>
                  <li className="c11 li-bullet-7">
                    <span className="c5 c0">
                      to share the submitted KYC information and documentation to the third
                      parties to verify the authenticity of submitted information, and the end
                      user (you) agree to this by using the Service;
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_15-2" start={4}>
                  <li className="c11 li-bullet-7">
                    <span className="c5 c0">
                      to reject the use of the Service that the Company has the reasonable
                      ground to believe that they are found to be in violation of relevant and
                      applicable AML/CFT laws and regulations, and to cooperate with the
                      competent authorities or any investigation when and if necessary upon
                      the valid request by the court order.
                    </span>
                  </li>
                </ol>
                <p className="c34 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c34 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_10-1" start={4}>
                  <li className="c3 c4 li-bullet-25">
                    <span className="c5 c0">
                      The Company expressly prohibits and rejects the use of the Service for
                      any form of illicit activity, including money laundering, terrorist
                      financing or trade sanctions violations, consistent with various
                      jurisdictions’ laws, regulations and norms. To that end, the Service is
                      not offered to individuals or entities on any Politically Exposed
                      Persons (“PEP”) lists, or subject to any United States, European Union,
                      or other global sanctions or watch lists. By using the Service, you
                      represent that you are not on any such lists.
                    </span>
                  </li>
                </ol>
                <p className="c8 c50 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_10-1" start={5}>
                  <li className="c4 c3 li-bullet-18">
                    <span className="c5 c0">
                      You fully acknowledge that your information and KYC/AML/CFT
                      documentation may be disclosed to government agencies or regulator upon
                      the only valid request of the court order. Once you have decided to
                      participate in any IDO fundraising event and start Staking your $TRONPAD
                      Tokens, you must ensure that all information provided to the Company is
                      complete, accurate, and updated in a timely manner. The Company will
                      rely on the information you provided and should there be any reasonable
                      grounds to believe that the partial or the whole of your information
                      provided to us is incomplete, or incorrect, or outdated, the Company
                      reserves the right to send you a notice to demand correction, or to
                      delete such information directly, and, as the case may be, to prohibit
                      you to access to all or part of the Website and the Services.
                    </span>
                  </li>
                </ol>
                <p className="c13 c34">
                  <span className="c5 c0" />
                </p>
                <p className="c34 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_10-1" start={6}>
                  <li className="c4 c3 li-bullet-8">
                    <span className="c5 c0">
                      If the Company has the reasonable ground to believe that any user
                      transacts or use the Services by using the digital currencies derived
                      from any suspicious illegal activities, the Company shall be entitled to
                      freeze/close/delete accounts as necessary. The Company will hold no
                      liability to such users for any damage, or loss arising out of or in
                      connection with this manner herein. Please note that any attempt to
                      circumvent this Clauses will also result in a similar action.
                    </span>
                  </li>
                </ol>
                <p className="c22 c13 c45">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c30">
                  <span className="c5 c0">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </p>
                <ol className="c6 lst-kix_list_16-0" start={8}>
                  <li className="c22 c44 li-bullet-16">
                    <h1 style={{ display: "inline" }}>
                      <span className="c0">$TRONPAD Token</span>
                      <span className="c1 c0">s </span>
                    </h1>
                  </li>
                </ol>
                <p className="c16 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-1" start={7}>
                  <li className="c4 c3 li-bullet-8">
                    <span className="c0">
                      The Company will issue a blockchain-based token called “$TRONPAD Token”
                      on TRON Network. $TRONPAD Token is classified as the utility token
                      designed to be used solely on the TRONPAD Platform or on the Website.
                    </span>
                  </li>
                </ol>
                <p className="c8 c3 c13 c50">
                  <span className="c1 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-1" start={8}>
                  <li className="c4 c3 li-bullet-8">
                    <span className="c0">
                      Users who stake $TRONPAD Tokens in their wallets will be eligible to
                      participate in IDO fundraising project event in either lottery or
                      guaranteeallocation round using the TRONPAD Tiered System.{" "}
                    </span>
                  </li>
                </ol>
                <p className="c8 c50 c3 c13">
                  <span className="c1 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-1" start={9}>
                  <li className="c4 c3 li-bullet-26">
                    <span className="c0">
                      $TRONPAD Token is not considered as security of any kind, and it also
                      does not carry any right to vote, manage or share in the TRONPAD
                      Platform.{" "}
                    </span>
                  </li>
                </ol>
                <p className="c8 c50 c3 c13">
                  <span className="c1 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-1" start={10}>
                  <li className="c4 c3 li-bullet-27">
                    <span className="c0">
                      $TRONPAD Token is neither money nor legal tender/currency, whether fiat
                      or otherwise, and it does not carry any value whether it is intrinsic or
                      assigned.{" "}
                    </span>
                  </li>
                </ol>
                <p className="c22 c13 c80">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-0" start={9}>
                  <li className="c22 c44 li-bullet-22">
                    <h1 style={{ display: "inline" }}>
                      <span className="c63">&nbsp;</span>
                      <span>Misuse of the Website</span>
                    </h1>
                  </li>
                </ol>
                <p className="c22 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-1" start={11}>
                  <li className="c4 c3 li-bullet-20">
                    <span className="c5 c0">
                      In the event of any misuse and/or abuse of the Website or breach any
                      provision in these Terms, we reserve the right to block your access to
                      the Website and other until the matter is solved.
                    </span>
                  </li>
                </ol>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-1" start={12}>
                  <li className="c4 c3 li-bullet-24">
                    <span className="c5 c0">
                      Use of the Website for transmission, publication or storage of any
                      material on or via the Website which is in violation of any applicable
                      laws or regulations or any third-party’s rights is strictly prohibited,
                      including but not limited to the use of the Website or the transmission,
                      distribution, publication or storage any material on or via the Website
                      in a matter or for the purpose which infringes copyright, trademark,
                      trade secret or other intellectual property rights, is obscene or
                      harmful to minors or constitutes an illegal act or harassment, is
                      libellous or defamatory, violates any privacy or data protections laws,
                      is fraudulent or breaches any exchange control laws.
                    </span>
                  </li>
                </ol>
                <p className="c16 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c16 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-0" start={10}>
                  <li className="c22 c44 li-bullet-22">
                    <h1 style={{ display: "inline" }}>
                      <span className="c0">TRONPAD</span>
                      <span className="c1 c0">&nbsp;Services </span>
                    </h1>
                  </li>
                </ol>
                <p className="c22 c13">
                  <span className="c1 c0" />
                </p>
                <p className="c22 c32">
                  <span className="c5 c0">Representations and Warranties</span>
                  <span className="c5 c0">.</span>
                </p>
                <p className="c22 c32">
                  <span>
                    You hereby agree to make the following representations and warranties for
                    accessing to the Website and the Services of{" "}
                  </span>
                  <span className="c0">the Company</span>
                  <span className="c5 c0">:</span>
                </p>
                <p className="c22 c13 c82">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2 start" start={1}>
                  <li className="c11 li-bullet-7">
                    <span className="c5 c0">
                      You have full capacity and authority under the application laws to agree
                      and bind yourself to these Terms.
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={2}>
                  <li className="c11 li-bullet-7">
                    <span className="c5 c0">You are eighteen years of age or older.</span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={3}>
                  <li className="c11 li-bullet-5">
                    <span className="c5 c0">
                      You are not a citizen or a resident from the Prohibited Jurisdictions,
                      and you do not have any relevant connection with any jurisdictions where
                      we have prohibited Services and access to the Website.
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={4}>
                  <li className="c11 li-bullet-5">
                    <span className="c5 c0">
                      You are aware and agree to comply with all applicable domestic and
                      international laws, statutes, ordinances, and regulations applicable to
                      your use of the Website and the Services. Your use of the Website and
                      the Services are not for any unlawful or illegal purposes, including but
                      not limited to the usage against the copyright laws, AML/CFT laws.
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={5}>
                  <li className="c11 li-bullet-5">
                    <span className="c0">
                      You are the exclusive owner of $TRONPAD Tokens and your other
                      cryptocurrencies held in your wallet
                    </span>
                    <span>.</span>
                    <span className="c0">
                      &nbsp;The tokens maintained in your wallets are not and will not be
                      derived from money laundering, terrorist financing, fraud, or any other
                      illegal activities under any applicable laws. You further hereby
                      acknowledge and agree that the Company
                    </span>
                    <span>
                      &nbsp;will not be responsible for actions taken by you that result in
                      the loss or destruction of the value of the tokens and rewards you hold
                      in the wallet.
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={6}>
                  <li className="c11 li-bullet-7">
                    <span className="c5 c0">
                      You validly undertake any action or enter into any transaction with
                      regard to these Terms. You are solely responsible for use of the Website
                      and the Services for all activities, or transactions that occur on or
                      through your User account on TRONPAD Platform.
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={7}>
                  <li className="c11 li-bullet-7">
                    <span className="c5 c0">
                      You will provide only accurate, complete, and up-to-date information and
                      documents, if any, for the purposes of accessing or using or
                      participating TRONPAD Services on the TRONPAD Platform. You will further
                      agree to put your effort to ensure that the confidentiality of your
                      personal or credential information, including your wallet address is
                      restricted, and safely maintained to your device you use to access the
                      Website.
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={8}>
                  <li className="c11 li-bullet-28">
                    <span className="c5 c0">
                      You will be acknowledged and agree that if you lose access to the wallet
                      that you connected with the TRONPAD Platform, the Company will not be
                      able to help you recover these lost, or transfer IDO tokens or any
                      $TRONPAD Tokens back to your wallet. It will be your solely
                      responsibility to manage your, and private key.{" "}
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={9}>
                  <li className="c11 li-bullet-29">
                    <span className="c5 c0">
                      You will be responsible for obtaining the data network access necessary
                      to use the Website. Your network's data and rates and fees may apply if
                      you access or use the Website from a wireless-enabled device, and you
                      will be responsible for such rates and fees.
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={10}>
                  <li className="c11 li-bullet-5">
                    <span className="c0">
                      You will understand and be aware of risks associated with accessing or
                      using or participating in TRONPAD
                    </span>
                    <span>&nbsp;Services</span>
                    <span className="c5 c0">
                      , and you will be fully liable at your own risk.
                    </span>
                  </li>
                </ol>
                <p className="c22 c13 c48">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={11}>
                  <li className="c11 li-bullet-28">
                    <span className="c0">
                      You will be aware that you are subject to tax regulations in the
                      jurisdiction you reside in and will be fully responsible for filling or
                      reporting any taxes and paying them as required by the Applicable Laws.
                      The Company
                    </span>
                    <span>&nbsp;</span>
                    <span className="c0">
                      will not be liable to compensate you for your tax obligations or advise
                      you in relation to your tax obligations. Any uncertainties and
                      unpredictable matters in tax legislation with respect to any tokens may
                      expose you to any unknown or unforeseeable tax implications associated
                      with your holding of tokens and the use of the Company Services for
                      which the Company will have no liability. Moreover, you will not hold
                      the Company
                    </span>
                    <span>&nbsp;</span>
                    <span className="c5 c0">
                      liable for any expenses or losses resulting from unknown or
                      unforeseeable tax implications.
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={12}>
                  <li className="c11 li-bullet-5">
                    <span className="c5 c0">
                      You will not breach any of the provisions stipulated in these Terms, the
                      Privacy Policy, or any Applicable Laws in any relevant jurisdictions.{" "}
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={13}>
                  <li className="c11 li-bullet-30">
                    <span className="c5 c0">
                      You will not use the Website and the Services in one of any following
                      manners, except as expressly permitted in these Terms, and the Company’s
                      discretion.{" "}
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <p className="c15">
                  <span className="c5 c0">You will not:</span>
                </p>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <p className="c15">
                  <span className="c0">
                    (a) infringe any propriety rights, including but not limited to
                    copyrights, patents, trademarks, or trade secrets of the Company.
                  </span>
                  <span className="c1 c0">&nbsp;</span>
                </p>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <p className="c15">
                  <span className="c5 c0">
                    (b) use the Website or the Services to transmit any data or send or upload
                    any material or content that contains viruses, Trojan horses, worms,
                    time-bombs, keystroke loggers, spyware, adware, or any other harmful
                    programmes or similar computer code designed to adversely affect the
                    operation of the Website and the Services provided on the Platform.{" "}
                  </span>
                </p>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <p className="c15">
                  <span className="c5 c0">
                    (c) expressly or impliedly, use the Website and the Services that is
                    deemed unlawful, offensive, malicious, threatening, libellous, defamatory,
                    obscene, or otherwise objectionable or violates these Terms, or any other
                    party's intellectual property.{" "}
                  </span>
                </p>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <p className="c15">
                  <span className="c5 c0">
                    (d) modify, make any back-up or archival copies of the Platform or any
                    part thereof including disassembling, and you will also not adapt, hack
                    the Website or modify another website to imply that it is associated with
                    the Website falsely.
                  </span>
                </p>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <p className="c15">
                  <span className="c5 c0">
                    (f) crawl, scrape, or otherwise cache any content from the Website, and
                    you will agree not to use any automated data collection methods, data
                    mining, robots, or scraping or any data gathering methods of any kind on
                    the Website.
                  </span>
                </p>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <p className="c15">
                  <span className="c5 c0">
                    (g) use the Website or any of its contents for advertising or soliciting,
                    for any other commercial, political, or religious purpose, or to compete,
                    either directly or indirectly with the Company.
                  </span>
                </p>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={14}>
                  <li className="c11 li-bullet-30">
                    <span className="c0">
                      You will defend, indemnify, and not hold the Company, its Affiliates,
                      each of their respective employees, officers, directors, and
                      representatives liable to and against any claims, damages, losses,
                      liabilities, costs, and expenses (including reasonable attorney's fees),
                      arising out of or relating to any third-party claim concerning these
                      Terms, or your use of the Company’s
                    </span>
                    <span>&nbsp;S</span>
                    <span className="c5 c0">
                      ervices in violation of these Terms and applicable laws.
                    </span>
                  </li>
                </ol>
                <p className="c8 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-1" start={13}>
                  <li className="c22 c3 c47 li-bullet-31">
                    <span className="c5 c0">
                      Each of the Representations and Warranties will survive and continue to
                      have full force and effect after the execution of these Terms.
                    </span>
                  </li>
                </ol>
                <p className="c10">
                  <span className="c5 c0" />
                </p>
                <p className="c10">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-0" start={11}>
                  <li className="c22 c44 li-bullet-32">
                    <h1 style={{ display: "inline" }}>
                      <span className="c0 c1">Risk Disclosure</span>
                    </h1>
                  </li>
                </ol>
                <p className="c16 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c30">
                  <span className="c0">
                    By accessing or using or participating in the Company’s
                  </span>
                  <span>&nbsp;Services, </span>
                  <span className="c5 c0">
                    you expressly acknowledge and assume the following risks:
                  </span>
                </p>
                <p className="c22 c30 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-1" start={14}>
                  <li className="c22 c36 c3 li-bullet-33">
                    <span className="c5 c0">Risk of loss in value</span>
                  </li>
                </ol>
                <p className="c8 c3">
                  <span className="c5 c0">
                    Tokens or any digital currencies are not issued by any central banks or
                    national, supra-national, or quasi-national organizations. They are also
                    not backed by any hard assets or other credit. The value of tokens or any
                    digital currencies is affected by several factors, including but not
                    limited to, the total number of tokens or any digital currencies in
                    existence, the continued willingness of market participants to exchange
                    government-issued currency for tokens or digital currencies , purchasers'
                    expectations with respect to the rate of inflation of fiat currencies,
                    purchasers' expectations with respect to the rate of deflation of
                    cryptocurrencies, interest rates, currency exchange rates, cyber theft of
                    cryptocurrencies from online digital wallet providers, or news of such
                    theft from such providers or individuals' digital wallets, investment and
                    trading activities of large investors, monetary policies of the
                    governments, trade restrictions, currency devaluations and revaluations,
                    regulatory measures, the global or regional political, economic or
                    financial events and situations. Thus, all these factors will affect the
                    value of tokens or digital currencies, which may result in the permanent
                    partial or total loss of the value of a particular tokens or digital
                    currencies. No one will be obliged to guarantee the liquidity or the
                    market price of any of the tokens or digital currencies maintained into
                    your wallets. The volatility and unpredictability of the value of tokens
                    or digital currencies relative to the government-issued currency may
                    result in a significant loss over a short period of time.
                  </span>
                </p>
                <p className="c22 c3 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-1" start={15}>
                  <li className="c22 c36 c3 li-bullet-34">
                    <span className="c5 c0">
                      The regulatory regime governing tokens or digital currencies
                    </span>
                  </li>
                </ol>
                <p className="c8 c3">
                  <span className="c5 c0">
                    The regulatory framework relating to tokens or digital currencies remains
                    unsettled, and any laws, regulations, or guidelines may be significantly
                    revised and amended which will materially and adversely affect the value
                    of tokens or digital currencies and your Services on the Website or
                    TRONPAD Platform as follows.
                  </span>
                </p>
                <p className="c22 c32 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-1" start={16}>
                  <li className="c22 c36 c3 li-bullet-35">
                    <span className="c0">
                      Technical and System Failure affected the obligations stipulated in
                      these Terms
                    </span>
                    <span className="c19 c0">&nbsp;</span>
                    <span className="c5 c0">
                      the Company may experience system failures, unplanned interruptions in
                      its TRON Network or Services, hardware or software defects, security
                      breaches or other causes that could adversely affect the Company
                      infrastructure network, which includes the Website and TRONPAD Platform.
                    </span>
                  </li>
                </ol>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c8 c23">
                  <span className="c0">
                    The Company is unable to anticipate the occurrence of hacks,
                    cyber-attacks, mining attacks, including but not limited to double-spend
                    attacks, majority mining power attacks and selfish-mining attacks,
                    distributed denial of service
                  </span>
                  <span>&nbsp;attacks</span>
                  <span className="c5 c0">
                    &nbsp;or errors, vulnerabilities or defects in the Website, $TRONPAD
                    Tokens, Users' wallets or any technology, including but not limited to
                    smart contract technology. Also, the Company is unable to detect the hacks
                    as mentioned earlier, mining attacks, cyber-attacks, distributed denials
                    of service errors vulnerabilities, or defects in a timely manner and does
                    not have sufficient resources to efficiently cope with multiple service
                    incidents happening simultaneously or in rapid succession.{" "}
                  </span>
                </p>
                <p className="c8 c23 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c8 c23">
                  <span className="c5 c0">
                    In addition, the Company's network or Services could be disrupted by
                    numerous events, including natural disasters, equipment breakdown, network
                    connectivity downtime, power losses, or even intentional disruptions of
                    its Services, such as disruptions caused by software viruses or attacks by
                    unauthorized users, some of which are beyond the Company's control.
                    Although the Company has taken steps and used its best endeavour against
                    malicious attacks on its appliances or its infrastructure, which are
                    critical for the maintenance of the TRONPAD Platform and its Services,
                    there can be no assurance that cyber-attacks, such as distributed denials
                    of service, will not be attempted in the future, and that the Company's
                    enhanced security measures will be effective. Any significant breach of
                    the Company's security measures or other disruptions resulting in a
                    compromise of the usability, stability and security of the Company's
                    network or Services, including the TRONPAD Platform, may adversely affect
                    $TRONPAD Tokens.
                  </span>
                </p>
                <p className="c8 c23 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-1" start={17}>
                  <li className="c22 c36 c3 li-bullet-36">
                    <span className="c5 c0">
                      The Company will have no liability for any delay, error, interruption,
                      or failure to perform any obligation under these Terms where the delay
                      or failure is directly or indirectly resulting from any causes beyond
                      the Company's control, including, but not limited to:
                    </span>
                  </li>
                </ol>
                <p className="c22 c3 c13 c32">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={15}>
                  <li className="c11 li-bullet-5">
                    <span className="c5 c0">
                      Acts of God, nature, or court of government;
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={16}>
                  <li className="c11 li-bullet-7">
                    <span className="c5 c0">
                      Failure or interruption of public or private telecommunication networks,
                      the failure of TRON Network, communication channels or information
                      systems;
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={17}>
                  <li className="c11 li-bullet-5">
                    <span className="c5 c0">
                      Acts or omission of acts of a party for whom We are not responsible;
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={18}>
                  <li className="c11 li-bullet-7">
                    <span className="c5 c0">
                      Delay, failure, or interruption in, or unavailability of, Third Party
                      Services;
                    </span>
                  </li>
                </ol>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-2" start={19}>
                  <li className="c11 li-bullet-7">
                    <span className="c5 c0">
                      Strikes, lockouts, labour disputes, wars, terrorist acts and riots.
                    </span>
                  </li>
                </ol>
                <p className="c22 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-1" start={18}>
                  <li className="c22 c36 c3 li-bullet-37">
                    <span className="c5 c0">
                      YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE COMPANY SERVICES AND THE
                      WEBSITE IS COMPLETELY AT YOUR OWN RISK. THIS CLAUSE IS NOT EXHAUSTIVE
                      AND DOES NOT DISCLOSE ALL THE RISKS ASSOCIATED WITH DIGITAL CURRENCIES
                      AND THE USE OF SERVICES. THEREFORE, YOU ARE RECOMMENDED TO CAREFULLY
                      CONSIDER WHETHER SUCH USE IS SUITABLE FOR YOU IN LIGHT OF YOUR
                      JUDGEMENT, CIRCUMSTANCES, AND FINANCIAL POSITION.
                    </span>
                  </li>
                </ol>
                <p className="c22 c32 c3 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_16-0" start={12}>
                  <li className="c22 c44 li-bullet-21">
                    <h1 style={{ display: "inline" }}>
                      <span className="c1 c0">Limitation of Liability</span>
                    </h1>
                  </li>
                </ol>
                <p className="c22 c13">
                  <span className="c1 c0" />
                </p>
                <ol className="c6 lst-kix_list_12-1 start" start={1}>
                  <li className="c2 c3 li-bullet-1">
                    <span className="c5 c0">
                      NOTWITHSTANDING ANY PROVISIONS WITHIN THESE TERMS, IN NO EVENT WILL THE
                      COMPANY, ITS AFFILIATES, OR ITS EMPLOYEES, AGENTS, OFFICERS, OR
                      DIRECTORS BE LIABLE TO THE USER FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY,
                      PUNITIVE, INDIRECT, OR CONSEQUENTIAL DAMAGES OF ANY KIND, UNDER ANY
                      LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR
                      INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON
                      THE WEBSITE OR OTHER SUCH WEBSITES, OR ANY OTHER SERVICES OR ITEMS
                      OBTAINED THROUGH THE WEBSITE, OR OTHER SUCH WEBSITES, INCLUDING, BUT NOT
                      LIMITED TO, LOST REVENUE, LOST PROFITS, LOSS OF BUSINESS OR ANTICIPATED
                      SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, LOSS OF YOUR
                      CREDENTIAL INFORMATION, LOSS OR INTERRUPTION OF TECHNOLOGY, LOSS OF USE
                      OF SERVICE OR EQUIPMENT, EVEN IF THE USER WAS ADVISED OF THE POSSIBILITY
                      OF SUCH DAMAGES, AND WHETHER ARISING UNDER A THEORY OF CONTRACT, TORT,
                      STRICT LIABILITY OR OTHERWISE. THE COMPANY WILL ALSO HAVE NO LIABILITY
                      FOR ANY EMERGENCY AND/OR UNFORESEEABLE INCIDENTS RELATED TO YOUR USE OF
                      THE COMPANY SERVICES ON THE TRONPAD PLATFORM SUCH AS STOLEN PRIVATE KEY,
                      OR HACKED ACCOUNTS.{" "}
                    </span>
                  </li>
                </ol>
                <p className="c8 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_12-1" start={2}>
                  <li className="c2 c29 li-bullet-3">
                    <span className="c5 c0">
                      Except as expressly provided in these Terms, and to the maximum extent
                      permitted by any Applicable Laws, we disclaim all other representations
                      or warranties, express or implied, made to you, your affiliates, or any
                      other person, including, without limitation, any warranties regarding
                      the quality, suitability, merchantability, fitness for a particular
                      purpose or otherwise (regardless of any course of dealing, custom or
                      usage of trade) of any service provided incidental to the Company
                      Services under these Terms.
                    </span>
                  </li>
                </ol>
                <p className="c22 c3 c45 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c8 c29">
                  <span className="c5 c0">
                    In no event will our aggregate liability for any loss or damage that
                    arises in connection with the Company Services exceed the purchase amount
                    you paid to participate in the TRONPAD Platform, if any, during a twelve
                    (12) month period immediately preceding the event that gave rise to the
                    claim for liability. The preceding limitations of liability will apply to
                    the fullest actual amount you paid to participate or access in the TRONPAD
                    Platform.
                  </span>
                </p>
                <p className="c8 c13 c29">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_12-1" start={3}>
                  <li className="c2 c29 li-bullet-3">
                    <span>
                      Except as expressly provided in these Terms, and to the fullest extent
                      permitted by any Applicable Laws,{" "}
                    </span>
                    <span className="c0">the Company</span>
                    <span>
                      , its Affiliates, and their related parties each disclaim all liability
                      to you for any loss or damage arising out of or due to:
                    </span>
                  </li>
                </ol>
                <ol className="c6 lst-kix_list_16-2 start" start={1}>
                  <li className="c22 c60 c48 li-bullet-38">
                    <span className="c0">&nbsp; your</span>
                    <span>
                      &nbsp;use of, inability to use, or availability or unavailability of the
                      Services, including any Third Party Services made available through the
                      Services;
                    </span>
                  </li>
                </ol>
                <p className="c22 c53 c48 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_13-2 start" start={2}>
                  <li className="c22 c60 c48 li-bullet-39">
                    <span className="c0">&nbsp; </span>
                    <span>
                      the occurrence or existence of any defect, interruption, deletion of
                      files, delays in the operation or transmission of information to, from,
                      or through the Services, communications failure, theft, destruction or
                      unauthorised access to{" "}
                    </span>
                    <span className="c0">the Company</span>
                    <span>
                      ’s records, programs, Services, server, or other infrastructure relating
                      to the Services;
                    </span>
                  </li>
                </ol>
                <p className="c22 c48 c13 c53">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_13-2" start={3}>
                  <li className="c22 c60 c48 li-bullet-40">
                    <span>
                      the Services being infected with any malicious code or viruses; or
                    </span>
                  </li>
                </ol>
                <p className="c22 c53 c48 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_13-2" start={4}>
                  <li className="c22 c48 c60 li-bullet-41">
                    <span>
                      the failure of the Services to remain operational for any period of
                      time.
                    </span>
                  </li>
                </ol>
                <p className="c34 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c53 c48 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_13-0 start" start={12}>
                  <li className="c22 c44 li-bullet-21">
                    <h1 style={{ display: "inline" }}>
                      <span className="c1 c0">Indemnification</span>
                    </h1>
                  </li>
                </ol>
                <p className="c22 c37">
                  <span className="c5 c0">
                    You irrevocably undertake the responsibility of fully indemnifying and
                    holding harmless each of the Company, its Affiliates, licensors,
                    shareholders, officers, directors, managers, employees, and agents from
                    and against any and all losses, claims, actions, proceedings, damages,
                    demands, judgements, sums, liabilities, damages, costs, charges and
                    expenses, including, but not limited to, any reasonable attorney's fees or
                    penalties imposed by any regulatory authority, and reimbursements arising
                    out of or related to the following situations:
                  </span>
                </p>
                <p className="c22 c13 c57">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_17-1 start" start={1}>
                  <li className="c4 c29 li-bullet-20">
                    <span>Your</span>
                    <span className="c5 c0">
                      &nbsp;use or any person using the Services on your behalf or
                      participation in accordance with the Company Services on the Website or
                      the TRONPAD Platform;
                    </span>
                  </li>
                </ol>
                <p className="c8 c13 c29">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_17-1" start={2}>
                  <li className="c2 c29 li-bullet-3">
                    <span className="c5 c0">
                      Your breach of or our enforcement of these Terms;
                    </span>
                  </li>
                </ol>
                <p className="c8 c13 c29">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_17-1" start={3}>
                  <li className="c2 c29 li-bullet-1">
                    <span className="c5 c0">
                      Any violations of Applicable Laws, regulation, or rights of any
                      third-party during your use or participate in TRONPAD Platform.
                    </span>
                  </li>
                </ol>
                <p className="c22 c13 c37">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c37">
                  <span className="c5 c0">
                    If you are obligated to indemnify the Company, its Affiliates,
                    shareholders, licensors, officers, directors, managers, employees, and
                    agents, the Company will have the right, at our sole discretion, to
                    control any action or proceeding and to determine whether the Company
                    wishes to proceed, or settle, and if so, on what terms or provisions.
                  </span>
                </p>
                <p className="c16 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c16 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_13-0" start={13}>
                  <li className="c22 c44 li-bullet-42">
                    <h1 style={{ display: "inline" }}>
                      <span className="c1 c0">Termination</span>
                    </h1>
                  </li>
                </ol>
                <p className="c22 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_1-1 start" start={1}>
                  <li className="c4 c39 li-bullet-20">
                    <span className="c5 c0">
                      These Terms will be immediately terminated by discontinuing your use, or
                      participate in the Company Services and you agree to terminate the
                      accessibility on the Platform.
                    </span>
                  </li>
                </ol>
                <p className="c8 c13 c39">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_1-1" start={2}>
                  <li className="c2 c39 li-bullet-3">
                    <span className="c5 c0">
                      These Terms can be suspended or terminated without a notice from us if
                      there is a reasonable ground to believe that you have breached any of
                      the terms or provisions stipulated in these Terms, or if you do not
                      comply with these Terms.{" "}
                    </span>
                  </li>
                </ol>
                <p className="c8 c13 c39">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_1-1" start={3}>
                  <li className="c2 c39 li-bullet-3">
                    <span className="c0">
                      The termination of these Terms will not prevent the Company from seeking
                      remedies from you in the case where you breach any terms or provisions
                      before such termination.
                    </span>
                    <span>&nbsp;</span>
                    <span className="c0">The Company</span>
                    <span>
                      &nbsp;will not be liable to you or to any third party for any
                      termination, suspension, or modification of your access to the Services.
                    </span>
                  </li>
                </ol>
                <p className="c8 c13 c39">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_1-1" start={4}>
                  <li className="c2 c23 li-bullet-3">
                    <span className="c0 c5">
                      Any ongoing obligation to you as well as the provisions regarding (i)
                      the Company's Intellectual Property, (ii) No solicitation or Offering,
                      (iii) indemnification, (iv) Limitation of liability, and (v) any other
                      provisions designed to survive, will survive any termination or
                      expiration of these Terms for any reason.
                    </span>
                  </li>
                </ol>
                <p className="c8 c13 c23">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_13-0" start={14}>
                  <li className="c22 c44 li-bullet-16">
                    <h1 style={{ display: "inline" }}>
                      <span className="c1 c0">No Financial and Legal Advice</span>
                    </h1>
                  </li>
                </ol>
                <p className="c22 c3 c13 c74">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c30 c3">
                  <span className="c5 c0">
                    The Company is merely a technology platform, and we are not your broker,
                    intermediary, agent, or legal advisor and has no fiduciary relationship or
                    obligation to you in connection with any decisions or activities effected
                    by you using or participating on the Website or the TRONPAD Platform. No
                    communication or information provided to you by the Company is intended as
                    or will be considered or construed as, the solicitation of an offer to
                    buy, the investment advice, financial advice, legal advice, or any other
                    sort of advice. All Services, Transactions, and Investments will be
                    executed automatically based on the parameters of your consideration. You
                    will be solely responsible for determining whether any services, or
                    investments are suitable and match your interests according to your
                    judgement, objectives, circumstances and risk tolerance. You will be
                    solely responsible for any losses or liabilities therefrom.
                  </span>
                </p>
                <p className="c22 c30 c3 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c30 c3">
                  <span className="c5 c0">
                    Before executing any transactions, purchasing $TRONPAD Tokens or IDO
                    tokens on the TRONPAD Platform, you should consult with your independent
                    financial, legal, or tax professionals. The Company will not be liable for
                    the decisions you make to access and purchase through the Company.
                  </span>
                </p>
                <p className="c22 c3 c13 c30">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c30 c3 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_13-0" start={15}>
                  <li className="c22 c44 li-bullet-32">
                    <h1 style={{ display: "inline" }}>
                      <span className="c1 c0">Notice/Announcement</span>
                    </h1>
                  </li>
                </ol>
                <p className="c16 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c30 c3">
                  <span className="c0">
                    Any notice, requests, demands, and determinations for the Company under
                    these Terms (other than routine operational communications) shall be sent
                    to{" "}
                  </span>
                  <span className="c25">
                    <a className="c17" href="https://bluezilla.jitbit.com/helpdesk/" target="_blank">
                    Contact Support
                    </a>
                  </span>
                </p>
                <p className="c22 c13 c59">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c59 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_13-0" start={16}>
                  <li className="c22 c44 li-bullet-43">
                    <h1 style={{ display: "inline" }}>
                      <span>
                        Governing Law, Resolving Disputes, Arbitration and Class Action Waiver
                      </span>
                    </h1>
                  </li>
                </ol>
                <p className="c16 c13">
                  <span className="c5 c0" />
                </p>
                <p className="c22 c30 c39">
                  <span className="c0">
                    PLEASE READ THIS SECTION CAREFULLY AS IT INVOLVES A WAIVER OF CERTAIN
                    RIGHTS TO BRING LEGAL PROCEEDINGS, INCLUDING A CLASS ACTION.
                  </span>
                </p>
                <p className="c8 c13 c26">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_5-1 start" start={1}>
                  <li className="c22 c36 li-bullet-44">
                    <span className="c5 c0">
                      Notice of Claim and Dispute Resolution Period
                    </span>
                  </li>
                </ol>
                <p className="c8 c3">
                  <span className="c0">
                    Please contact the Company first. The Company will seek to address your
                    concerns without resorting to formal legal proceedings{" "}
                  </span>
                  <span>whenever </span>
                  <span className="c5 c0">
                    possible. If you have a dispute with the Company, you should contact the
                    Company, and a case number will be assigned. The Company will attempt to
                    resolve your dispute internally as soon as possible. The parties will
                    agree to negotiate in good faith to resolve the dispute and discussions
                    will remain confidential and subject to applicable laws protecting
                    settlement discussions from use as evidence in any legal proceeding.
                  </span>
                </p>
                <p className="c8 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_5-1" start={2}>
                  <li className="c2 li-bullet-3">
                    <span className="c5 c0">Agreement to Arbitrate</span>
                  </li>
                </ol>
                <p className="c8 c29">
                  <span className="c5 c0">
                    You and the Company agree that subject to paragraph 16.1 above, any
                    dispute, claim, or controversy between you and the Company that arises in
                    connection with, or relating in any way, to these Terms, or to your
                    relationship with the Company as a user of the Company Services (whether
                    by contract, tort, statute, fraud, misrepresentation, or any other legal
                    theory, and whether the claims arise during or after the termination of
                    these Terms) will be determined by a mandatory final and binding
                    individual arbitration rather than a class action, except as set forth
                    below under Exceptions to the Agreement to Arbitrate. You and the Company
                    further agree that the arbitrator will have the exclusive power to rule on
                    his or her jurisdiction, including, without limitation, any objections
                    with respect to the existence, scope, or validity of the Agreement to
                    Arbitrate, or to the arbitrability of any claim or counterclaim.
                    Arbitration is more informal than a lawsuit in court. THERE IS NO JUDGE OR
                    JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED.
                    There may be more limited discovery than in court. The arbitrator must
                    follow this agreement and can award the same damages and relief as a
                    court, including, if applicable, attorney fees, except the arbitrator may
                    not award declaratory or injunctive relief benefiting anyone but the
                    parties to the arbitration. The arbitration provisions set forth in this
                    Section will survive termination of these Terms.{" "}
                  </span>
                </p>
                <p className="c22 c45 c13 c29">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_5-1" start={3}>
                  <li className="c2 li-bullet-1">
                    <span className="c5 c0">Arbitration Rules</span>
                  </li>
                </ol>
                <p className="c22 c29 c41">
                  <span className="c5 c0">
                    Any dispute arising out of or in connection with these Terms including any
                    question regarding its existence, validity or termination, shall be
                    referred to and finally resolved by arbitration administered by the
                    Singapore International Arbitration Centre (“SIAC”) in accordance with the
                    Arbitration Rules of the Singapore International Arbitration Centre ("SIAC
                    Rules") for the time being in force, which rules are deemed to be
                    incorporated by reference in these Terms. The seat of the arbitration
                    shall be Singapore. The Tribunal shall consist of one arbitrator. The
                    language of the arbitration shall be English.{" "}
                  </span>
                </p>
                <p className="c22 c13 c41 c29">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_5-1" start={4}>
                  <li className="c2 li-bullet-1">
                    <span className="c5 c0">Governing Law/Jurisdiction</span>
                  </li>
                </ol>
                <p className="c8">
                  <span className="c5 c0">
                    &nbsp;The governing law of the arbitration will be that of the British
                    Virgin Islands.
                  </span>
                </p>
                <p className="c7">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_5-1" start={5}>
                  <li className="c2 li-bullet-3">
                    <span className="c5 c0">Confidentiality.</span>
                  </li>
                </ol>
                <p className="c8">
                  <span className="c5 c0">
                    The parties agree that the arbitration will be kept confidential. The
                    existence of the arbitration, any non-public information provided in the
                    arbitration, and any submissions, orders or awards made in the arbitration
                    will not be disclosed to any non-parties except the tribunal, the parties,
                    their counsel, experts, witnesses, accountants and auditors, insurers and
                    reinsurers, and any other person necessary to facilitate the arbitration.
                    Notwithstanding the preceding, a party may disclose information to the
                    extent that disclosure may be required to fulfil a legal duty, protect, or
                    pursue a legal right, or enforce or challenge an award in bona fide legal
                    proceedings. This confidentiality provision will survive the termination
                    of these Terms and any arbitration brought under these Terms.
                  </span>
                </p>
                <p className="c22 c13 c40">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_5-1" start={6}>
                  <li className="c2 li-bullet-3">
                    <span className="c5 c0">&nbsp; Class Action Waiver</span>
                  </li>
                </ol>
                <p className="c22 c33 c29">
                  <span className="c5 c0">
                    You and the Company agree that any claims relevant to these Terms, or your
                    relationship with the Company will be brought against the other party in
                    arbitration on an individual basis only and not as a plaintiff or class
                    member in a purported class or representative action. You and the Company
                    further agree to waive any right for such claims to be brought, heard, or
                    arbitrated as a class, collective, representative, or private attorney
                    general action, to the extent permissible by applicable laws. Combining or
                    consolidating individual arbitrations into a single arbitration is not
                    permitted without the consent of all parties involved.{" "}
                  </span>
                </p>
                <p className="c22 c33 c13 c29">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_5-1" start={7}>
                  <li className="c22 c58 li-bullet-5">
                    <span className="c0">
                      The Company reserves the right to update, modify, revise, suspend, or
                      make future changes to Section 16.2 regarding the parties' Agreement to
                      Arbitrate, subject to applicable laws. You hereby consent and agree that
                      it is your responsibility to ensure that your understanding of this
                      Clause is up to date. Subject to applicable laws, your continued use of
                      your TRONPad’s account will be interpreted as your acceptance of any
                      modifications to Clause 16 regarding the parties' Agreement to
                      Arbitrate. You agree that if you object to the modifications to Clause
                      16, the Company may block access to your account pending closure of your
                      account. In such circumstances, these Terms prior to modification will
                      remain in full force and affect the pending closure of your
                      accessibility.
                    </span>
                    <span className="c31">&nbsp;</span>
                  </li>
                </ol>
                <p className="c22 c33 c13">
                  <span className="c1 c0" />
                </p>
                <p className="c22 c13 c33">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_13-0" start={17}>
                  <li className="c22 c44 li-bullet-43">
                    <h1 style={{ display: "inline" }}>
                      <span className="c1 c0">Miscellaneous</span>
                    </h1>
                  </li>
                </ol>
                <p className="c22 c13">
                  <span className="c1 c0" />
                </p>
                <ol className="c6 lst-kix_list_7-1 start" start={1}>
                  <li className="c22 c66 li-bullet-45">
                    <span className="c5 c0">Severability</span>
                  </li>
                </ol>
                <p className="c8 c56">
                  <span className="c5 c0">
                    If any of the provisions in these Terms is found by a court of competent
                    authority to be invalid, void, unlawful or unenforceable under any
                    applicable laws, such unenforceability or invalidity will not render these
                    Terms unenforceable or invalid as a whole, and such provisions will be
                    deleted without affecting the remaining provisions herein.
                  </span>
                </p>
                <p className="c8 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_7-1" start={2}>
                  <li className="c2 li-bullet-1">
                    <span className="c5 c0">Variation of Terms</span>
                  </li>
                </ol>
                <p className="c8 c3">
                  <span className="c5 c0">
                    The Company has the right to revise these Terms at our sole discretion at
                    any time, and by using the Website or other TRONPAD Platform, you will be
                    expected to review such Terms regularly to ensure that you understand all
                    provisions stipulated in these Terms.
                  </span>
                </p>
                <p className="c13 c22">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_7-1" start={3}>
                  <li className="c2 li-bullet-3">
                    <span className="c5 c0">Assignment</span>
                  </li>
                </ol>
                <p className="c8">
                  <span className="c5 c0">
                    The Company will be allowed to assign, transfer, and subcontract Our
                    rights and/or obligations under these Terms without the need to provide
                    you any notification or acquire your consent. Nevertheless, you will not
                    be permitted to assign, transfer, or subcontract any of your rights and/or
                    obligations under these Terms.
                  </span>
                </p>
                <p className="c8 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_7-1" start={4}>
                  <li className="c2 li-bullet-1">
                    <span className="c5 c0">Entire Agreement</span>
                  </li>
                </ol>
                <p className="c8 c23">
                  <span className="c5 c0">
                    These Terms, including the Privacy Policy and any rules contained on the
                    Website or others TRONPAD Platform, constitute the sole and entire
                    agreement between You and the Company with respect to your use of the
                    TRONPAD Platform, and supersedes other prior or contemporaneous
                    negotiations, discussions, agreements, understandings, representations,
                    and warranties, both written and oral, between You and the Company with
                    respect to such subject matter.
                  </span>
                </p>
                <p className="c8 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_7-1" start={5}>
                  <li className="c2 li-bullet-3">
                    <span className="c5 c0">No Third-Party Rights</span>
                  </li>
                </ol>
                <p className="c8 c56">
                  <span className="c5 c0">
                    Nothing in these Terms will be deemed to create any rights to any
                    creditors or other persons, not a party hereto. Moreover, these Terms will
                    not be construed, in any respect, to be a contract, in whole or in part,
                    for the benefit of any third parties.
                  </span>
                </p>
                <p className="c8 c13 c56">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_7-1" start={6}>
                  <li className="c2 li-bullet-1">
                    <span className="c5 c0">Clickwrap</span>
                  </li>
                </ol>
                <p className="c8">
                  <span className="c5 c0">
                    The Company may deliver the Service through electronic means such as
                    download links, graphical, Tools or other technologies for providing the
                    Service for users. The user interfaces to such electronic means may
                    require that the users agree to these Terms by checking a box, clicking a
                    button, or continuing with the Service. If user through such action the
                    user becomes a Party to these Terms. Such an action of acceptance shall be
                    sufficient to bind the users to the terms and conditions herein these
                    Terms.{" "}
                  </span>
                </p>
                <p className="c8 c13">
                  <span className="c5 c0" />
                </p>
                <ol className="c6 lst-kix_list_7-1" start={7}>
                  <li className="c2 li-bullet-1">
                    <span className="c5 c0">Waiver</span>
                  </li>
                </ol>
                <p className="c8 c3">
                  <span className="c0">
                    The failure of one Party to require the performance of any provision will
                    not affect that Party's right to require performance at any time
                    thereafter. At the same time, the waiver of one Party to seek recovery for
                    the other Party's violation of these Terms of any provisions of applicable
                    terms will not constitute a waiver by that Party of any subsequent breach
                    or violation by the other Party or of the provision itself.{" "}
                  </span>
                </p>
                <hr className="c28" />
                <ul>
                  <li className="c55">
                    <a href="#ftnt_ref1" id="ftnt1" onClick={(e)=>e.preventDefault()}>
                      [1]
                    </a>&nbsp;<span className="c0 c63 c64">&nbsp;Version 6</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUsePage;
