import React from "react";


import Countdown from 'react-countdown';
import { useTranslation } from "react-i18next";

const countDown1 = 1623513600000;
const countDown2 = 1623513600000;
const countDown3 = 1624291200000;
const now = new Date().getTime()

const ComingSoonBanner = (props) => {
    const { t } = useTranslation();

    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span>{t('Completed')}</span>;
        } else {
            // Render a countdown
            return <span>{days}days {hours}h {minutes}m {seconds}s</span>;
        }
    };
    return (
        <>

            <div className="pp-section pb-2" id="road-map">
                <div className="container text-center">
                    <div className="section-head">
                        <h2 className="title title-xl" data-aos="fade-up" data-aos-delay="100" title={t('Road map')}>{t('COMING SOON')}</h2>
                    </div>
                </div>
            </div>
            <div className="pp-road-map mt-150 pb-5">
                <div className="container">
                <div className="pp-road-map-line"></div>
                </div>
                <div className="container text-center">
                    <div className="row justify-content-center">
                        <div className="col-md-3">
                            <div className="pp-road-map-item">
                                <div className="pp-road-map-date">
                                    <Countdown
                                        date={countDown1}
                                        renderer={renderer}

                                    />
                                </div>
                                <div className="pp-road-map-name">
                                     {t('12th June')}<br /><span className="text-uppercase">{t('Launch on JustSwap')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="pp-road-map-item">
                                <div className="pp-road-map-date">
                                    <Countdown
                                        date={countDown2}
                                        renderer={renderer}

                                    />
                                </div>
                                <div className="pp-road-map-name">
                                     {t('12th June')}<br /><span className="text-uppercase">{t('TRON <> BSC BRIDGE')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="pp-road-map-item">
                                <div className="pp-road-map-date">
                                    <Countdown
                                        date={countDown2}
                                        renderer={renderer}

                                    />
                                </div>
                                <div className="pp-road-map-name">
                                     {t('12th June')}<br /><span className="text-uppercase">{t('TRONPAD STAKING')}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="pp-road-map-item">
                                <div className="pp-road-map-date">
                                    {/* { (now >= countDown2) && */}

                                    <Countdown style
                                        date={countDown3}
                                        renderer={renderer}

                                    />
                                    {/* } */}
                                </div>
                                <div className="pp-road-map-name">
                                     {t('21th June')}<br /><span className="text-uppercase">{t('1st IDO')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )

}


export default ComingSoonBanner;