

import { BigNumber } from "bignumber.js";

import _ from "lodash";
import { MODE, TRON_NODE_HOST, API_KEY, PRIVATE_KEY } from "../_configs";
import stakingABI from "../contracts/staking.json";
import { generateAccount } from 'tron-create-address'

const TronWeb = require('tronweb');

const { privateKey } = generateAccount()



let tronWeb = new TronWeb({
    fullHost: TRON_NODE_HOST[MODE],
    privateKey: privateKey,
    // headers: { "TRON-PRO-API-KEY": _.sample(API_KEY[MODE]) }
})
export const getInfoContract = async({addresses, walletUtil}) => {
    // console.log("before==>", tronWeb);
  
   if(walletUtil){
    tronWeb = walletUtil.getTronWeb()

    // console.log("after==>", tronWeb);
   }
    const info = {};

    // //call project info
    await Promise.all(
        addresses
        .filter((address) => address !== "TBA")
        .map(async(address) => {
            // console.log(address)
            if (address === "TBA") {
                info[`${address}`] = {
                    state: "P",
                    symbol: "TBA",
                    rate: 0,
                    totalCountWallet: 0,
                    totalCountUserParticipated: 0,
                    totalFundParticipated: 0,
                    maxSingleParticipationAllocated: 0,
                    maxTotalParticipationAllocated: 0,
                };
            } else {
                const tokenContract = await tronWeb.contract().at(address); 
          
                // console.log('call contract ',address)
                // debugger
                const contractInfo = await tokenContract.info().call();

                const contractInfoRound = await tokenContract.infoRounds().call();

                // console.log(contractInfoRound);
                const tokenAddress = tronWeb.address.fromHex(contractInfo[0].toString());
                const symbol = contractInfo[1].toString();
                const decimal = parseInt(contractInfo[2].toString());
                const rate = parseFloat(contractInfo[3].toString());
                const openTime = parseInt(contractInfo[4].toString());
                const fcfsOpenTime = parseInt(contractInfo[5].toString());
                const closeTime = parseInt(contractInfo[6].toString());
                // const isPrivate = contractInfo[6]
                const totalCountWallet = contractInfo[7].toString();
                const state = contractInfo[8].toString();
                const totalCountUserParticipated = contractInfo[9].toString();
                const totalFundParticipated = parseFloat(
                    contractInfo[10].toString() / 10 ** decimal
                );
                // const maxSingleParticipationAllocated = parseFloat(contractInfo[11] / 10 ** decimal)
                // const maxTotalParticipationAllocated = parseFloat(contractInfo[11] / 10 ** decimal)
                const maxTotalParticipationAllocated = contractInfo[11].toString();
                // const maxTotalParticipationAllocated = parseInt(
                //     contractInfo[11].substring(0, contractInfo[11].length - decimal)
                // );

                let infoRound = [];
                for (let i = 0; i < contractInfoRound["0"].length; i++) {
                    infoRound.push({
                        round: contractInfoRound[0][i],
                        opens: contractInfoRound[1][i],
                        closes: contractInfoRound[2][i],
                    });
                }
                // const now = new Date().getTime() /10000 + 10000;

                info[`${address}`] = {
                    tokenAddress,
                    symbol,
                    decimal,
                    rate,
                    openTime,
                    fcfsOpenTime,
                    closeTime,
                    totalCountWallet,
                    totalCountUserParticipated,
                    totalFundParticipated,
                    maxTotalParticipationAllocated,
                    state,
                    infoRound,
                };
            }
        })
    );
    console.log(info)
    return info;
};

//get info from staking contract

export async function getStakingContractInfo(stakingContract) {

    try {
        const contract = await tronWeb.contract(stakingABI, stakingContract);
        const info = await contract.info().call({ _isConstant: true });

        return {
            tokenAddr: info[0],
            tokenSymbol: info[1],
            tokenDecimals: Number(info[2]),
            stakerCount: Number(info[3]),
            totalStakedAmount: BigNumber(info[4].toString())
              .dividedBy(10 ** 18)
              .toString(),
            apyPercentage: Number(info[5]),
            unstakePeriod: Number(info[6]),
            isStakingPaused: info[7],
            totalRewardsDistributed: BigNumber(info[8].toString())
              .dividedBy(10 ** 18)
              .toString(),
        };
    } catch (error) {
        // debugger
        console.log(error);
        return {
            tokenAddr: 0,
            tokenSymbol: 0,
            tokenDecimals: 0,
            stakerCount: 0,
            totalStakedAmount: 0,
            apyPercentage: 0,
            unstakePeriod: 0,
            isStakingPaused: 0,
            totalRewardsDistributed: 0,
        };
    }
}


export function convertTronToBscAddress(tronAddress) {
    const hexString = tronWeb.address.toHex(tronAddress)
    const bscAddress = '0x' + hexString.substring(2)
    return bscAddress.toLowerCase()
}