import React from "react";

import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";

import Particles from 'react-particles-js';
import AdvisorComponent from "./AdvisorComponent";
import TieredSystemComponent from './TieredSystemComponent'
import ComingSoonBanner from "./ComingSoonBanner";
import { useTranslation } from "react-i18next";


const HomePage = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="pp-homepage">
        <div className="pp-home-banner">
          <div className="container">
            <h1 className="text-center text-uppercase" data-aos="fade-up" data-aos-delay="100"> {t('TRONPAD IS THE FIRST LAUNCHPAD BUILT ON AND PARTNERED WITH THE TRON NETWORK')}</h1>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <p data-aos="fade-up" data-aos-delay="200">{t('TRONPAD will empower crypto currency projects with the ability to distribute tokens and raise liquidity.')}</p>
              </div>
            </div>
            <div className="mt-4">
              <Link data-aos="fade-up" data-aos-delay="300" className="btn btn-primary btn-lg me-3" to={ROUTES.PROJECTS}>
                <span>{t('View all Projects')}</span>
              </Link>
              <a style={{ whiteSpace: 'nowrap' }} target="_blank" className="btn btn-primary btn-justswap btn-lg btn-buy-on me-3 d-inline-flex align-items-center" href="https://justswap.org/#/home?tokenAddress=TBLrDTgEsQk6a6PkB6KYp9xU4oJpHiRde8&type=swap">
                <b className="me-2">{t('Buy on')}</b>
                <img src="/images/justswap.svg" height="24"/>
              </a>
              <a target="_blank" className="btn btn-primary btn-pancake btn-lg btn-buy-on me-3" href="https://www.dextools.io/app/pancakeswap/pair-explorer/0x31ec8f77a11aa0277948ed5f9b7b1daf23de3fda">
                <b style={{ whiteSpace: 'nowrap' }} className="d-flex align-items-center justify-content-center">{t('View on DexTools')}</b>
              </a>
              <a data-aos="fade-up" data-aos-delay="400" className="btn btn-success btn-lg" href="https://docs.google.com/forms/d/e/1FAIpQLSevFsaTvcdHotj3hb5XR392bESUz_bBklYvd-YTOKzgCE90YQ/viewform" target="_blank">
                <span>{t('Apply for IDO')}</span>
              </a>
            </div>
            <div className="mt-5 text-center">
              {/* <a className="btn btn-outline-primary" href="https://t.me/Tronpad" target="_blank">
                <i className="fas fa-paper-plane me-1"></i>
                <span>{t('Join us on Telegram')}</span>
              </a> */}
              <a className="btn btn-outline-primary mx-3" href="https://medium.com/@tronpad" target="_blank">
                <i className="fab fa-medium-m me-1"></i>
                <span>{t('Follow our Medium')}</span>
              </a>
              <a className="btn btn-outline-primary" href="https://twitter.com/Tronpadofficial" target="_blank">
                <i className="fab fa-twitter me-1"></i>
                <span>{t('Follow our Twitter')}</span>
              </a>
            </div>
          </div>
        </div>

        <div className="pp-home-about pp-section py-5" id="about">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-12 px-lg-0">
                <div className="section-head">
                  <h2 className="title title-xl title-xl-40" data-aos="fade-up" data-aos-delay="100" title="TRUST">
                    {t("The world's fastest-growing public chain has officially partnered with the world's most lucrative launchpad.")}
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="pp-home-about-content pp-section-body mt-4">
            <div className="container text-center">
              <div className="mb-3">
                <img className="light-show mx-auto img-280-mb" src='/images/tron-logo.png' width="350" alt="Tron" />
                <img className="dark-show mx-auto img-280-mb" src="/images/banner.png" width="350" alt="Tron" />
              </div>
              <p className="font-24 px-md-5">{t('Tronpad combines the proven track record of BSCPad’s launchpad solutions with the global reach and efficiency of Tron’s blockchain technology to deliver a cutting edge IDO experience.')}</p>
            </div>
          </div>
        </div>

        {/* <ComingSoonBanner /> */}

        <div className="pp-home-about pp-section" id="about">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-6">
                <div className="section-head">
                  <h2 className="title title-xl" data-aos="fade-up" data-aos-delay="100" title={t('What and Why')}>{t('ABOUT US')}</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="pp-home-about-content pp-section-body mt-4">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
                  <h6>{t('WHAT IS TRONPAD?')}</h6>
                  <h2>{t('TronPad is the only IDO platform built on and partnered with the Tron Network.')}</h2>
                  <p className="lead-s2">{t('TronPad will empower cryptocurrency projects with the ability to distribute tokens and raise liquidity.')}</p>
                  <h6 className="mt-4">{t('WHY CHOOSE US?')}</h6>
                  <h2>{t('Following the success of BSCPAD, the BSCPAD Team has partnered with Tron to bring its popular low barrier to entry IDO solutions to to the Tron network that will incentivize and reward all token holders in a way that is inclusive and fair.')}</h2>
                  <p>{t('One of the fundamental flaws of existing launchpads is acquiring enough tokens to participate in the ecosystem can be prohibitive. This can be based on a first come first serve basis where automated bots can fill the whitelist spots in a matter of seconds. TronPad addresses this issue and is designed for fair decentralized launches.')}</p>
                  <p>{t("The hallmark of TronPad is a two-round system, consisting of a participation lottery for lower levels and guaranteed allocation for higher levels. Any of the allocation that is not bought in time is then offered on a 'first come first served' basis.")}</p>
                </div>

                <div className="col-md-6">
                  <div className="nk-block-img nk-block-ca text-center" data-aos="fade-up" data-aos-delay="200" style={{ position: 'relative' }}>
                    <div className="nk-circle-animation nk-df-center fast"></div>
                    <img className="shadow rounded mx-auto" style={{ width: '60%' }} src="images/sc-medium-a.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <TieredSystemComponent />
        <AdvisorComponent />

        <div className="pp-home-contact pp-section pb-5 pt-5" id="contact">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-6">
                <div className="section-head">
                  <h2 className="title title-xl" data-aos="fade-up" data-aos-delay="100" title={t('CONTACT US')}>{t('CONTACT')}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="pp-home-contact-content pp-section-body mt-5">
            <div className="container">
              <div className="row justify-content-center align-items-center gutter-vr-50px">

                <div className="col-lg-5 text-center order-lg-first">
                  <div className="nk-block-contact nk-block-contact-s1" data-aos="fade-up" data-aos-delay="100">
                    <ul className="contact-list ps-1">
                      <li data-aos="fade-up" data-aos-delay="300">
                        <em className="contact-icon fas fa-headset"></em>
                        <div className="contact-text">
                          <a href="https://bluezilla.jitbit.com/helpdesk/" target="_blank"><span>Contact Support</span></a>
                        </div>
                      </li>
                      {/* <li data-aos="fade-up" data-aos-delay="400">
                        <em className="contact-icon fas fa-paper-plane"></em>
                        <div className="contact-text">
                          <a href="https://t.me/Tronpad" target="_blank"> <span>{t('Join us on Telegram')}</span></a>
                        </div>
                      </li> */}
                      <li data-aos="fade-up" data-aos-delay="400">
                        <em className="contact-icon fab fa-twitter"></em>
                        <div className="contact-text">
                          <a href="https://twitter.com/Tronpadofficial" target="_blank"> <span>{t('Follow our Twitter')}</span></a>
                        </div>
                      </li>
                      <li data-aos="fade-up" data-aos-delay="500">
                        <em className="contact-icon fas fa-globe"></em>
                        <div className="contact-text">
                          <span>www.tronpad.network</span>
                        </div>
                      </li>
                    </ul>
                    <div className="nk-circle-animation nk-df-center white small"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <Particles params={{
        "particles": {
          "number": {
            "value": 80,
            "density": {
              "enable": true,
              "value_area": 2000
            }
          },
          "color": {
            "value": "#ffffff"
          },
          "shape": {
            "type": "circle",
            "stroke": {
              "width": 0,
              "color": "#000000"
            },
            "polygon": {
              "nb_sides": 5
            }
          },
          "opacity": {
            "value": 0.5,
            "random": false,
            "anim": {
              "enable": false,
              "speed": 1,
              "opacity_min": 0.1,
              "sync": false
            }
          },
          "size": {
            "value": 3,
            "random": true,
            "anim": {
              "enable": false,
              "speed": 40,
              "size_min": 0.1,
              "sync": false
            }
          },
          "line_linked": {
            "enable": true,
            "distance": 150,
            "color": "#ffffff",
            "opacity": 0.4,
            "width": 1
          },
          "move": {
            "enable": true,
            "speed": 6,
            "direction": "none",
            "random": false,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
              "enable": false,
              "rotateX": 600,
              "rotateY": 1200
            }
          }
        },
        "interactivity": {
          "detect_on": "canvas",
          "events": {
            "onhover": {
              "enable": true,
              "mode": "repulse"
            },
            "onclick": {
              "enable": true,
              "mode": "push"
            },
            "resize": true
          },
          "modes": {
            "grab": {
              "distance": 400,
              "line_linked": {
                "opacity": 1
              }
            },
            "bubble": {
              "distance": 400,
              "size": 40,
              "duration": 2,
              "opacity": 8,
              "speed": 3
            },
            "repulse": {
              "distance": 200,
              "duration": 0.4
            },
            "push": {
              "particles_nb": 4
            },
            "remove": {
              "particles_nb": 2
            }
          }
        },
        "retina_detect": true
      }} />
    </>
  );
};

export default HomePage;
