import React from 'react';
import AdvisorCard from '../AdvisorCard';
import LegalPartner from '../LegalPartner'


export default function AdvisorsContainer({ legalPartner }) {
    return (
        <>
            <div className="pp-home-advisors-content pp-section-body">
                <div className="container">
                    {/* <div className="row justify-content-center">
                        <AdvisorCard advisor={advisors["JASPER_BYUN"]}/>
                        <AdvisorCard advisor={advisors["LESTER_LIM"]}/>
                        <AdvisorCard advisor={advisors["IAN_FRIEND"]}/>
                        
                        <AdvisorCard advisor={advisors["DANISH_CHAUDHRY"]}/>
                        <AdvisorCard advisor={advisors["EXNETWORK_CAPITAL"]}/>
                        <AdvisorCard advisor={advisors["TIM_FROST"]}/>
                    </div> */}

                    {/* <LegalPartner legalPartner= {legalPartner}  /> */}
                    
                </div>
            </div>

        </>
    )
}