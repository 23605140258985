import React from 'react';
import ConnectWalletModal from '../ConnectWalletModal';
import WalletModal from '../WalletModal';
import HelpModal from '../HelpModal';
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants";
import packageJson from '../../../../package.json';
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="pp-footer">
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="text-muted"> {t('Copyright ©')} {currentYear}. {t('All Rights Reserved by TRONPAD')}</div>
            <div className="text-muted"> v{packageJson.version}</div>
            <div className="text-end pp-footer-link">
              <Link target="_blank" to={ROUTES.PRIVACY_POLICY}>{t('Privacy Policy')}</Link>
              <Link target="_blank" to={ROUTES.COOKIE_POLICY}>{t('Cookies Policy')}</Link>
              <Link target="_blank" to={ROUTES.TERMS_OF_USE}>{t('Terms of Use')}</Link>
            </div>
          </div>
        </div>
      </div>
      <ConnectWalletModal />
      <HelpModal />
      <WalletModal />
    </>
  );
}