import { get } from "lodash";
import React, { useEffect, useState } from "react";
import $ from 'jquery';
import { useDispatch, useSelector } from "react-redux";
import { ACTION_CONST, ROUTES, STATUS } from "../../constants";
import { helpers } from "../../utils/helpers";
import { getCountDown } from "../../utils/helper";
import { useTranslation } from "react-i18next";

const RightBar = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  function toggleSidebar() {
    $('.p-sidebar').toggleClass('active');
  }
  // const [isBrowserMobile, setIsBrowserMobile] = useState(false)
  const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));

  const walletAddress = useSelector((state) => get(state, "utils.walletAddress", ""));
  const walletUtils = useSelector((state) => get(state, "utils.walletUtils", null));

  const [stakedAmount, setStakedAmount] = useState('0');
  const [unStakedAmount, setUnStakedAmount] = useState('0');
  const [rewardAmount, setRewardAmount] = useState('0');
  const [withdrawTimestamp, setWithdrawTimestamp] = useState(0);

  // const [isSubmitOK, setIsSubmitOK] = useState(false);
  const submitOK = useSelector((state) =>
    get(state, "utils.blocking", false)
  );



  useEffect(() => {

    if (walletUtils && walletAddress) {
      // get stake amount
      walletUtils.getStakingInfoWallet().then(data => {
        // debugger
        setStakedAmount(data.stakedAmount);
        setUnStakedAmount(data.unStakedAmount);
        setRewardAmount(data.rewardAmount);

        const now = new Date().getTime();
        if (data.withdrawTimestamp > now) {
          setWithdrawTimestamp(data.withdrawTimestamp);
        }
      
        dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
      });

      //setup interval
      const job = setInterval(() => {
        walletUtils.getStakingInfoWallet().then(data => {
          setStakedAmount(data.stakedAmount);
          setUnStakedAmount(data.unStakedAmount);
          setRewardAmount(data.rewardAmount);

          const now = new Date().getTime();
          if (data.withdrawTimestamp > now && data.withdrawTimestamp !== withdrawTimestamp) {
            setWithdrawTimestamp(data.withdrawTimestamp);
          }

          dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
        })
      }, 13 * 1000);

      dispatch({ type: ACTION_CONST.SET_JOB_GET_STAKING_WALLET_INFO, job });
    }
  }, [walletUtils, walletAddress, submitOK, dispatch, withdrawTimestamp]);

  
  useEffect(() => {
    if (withdrawTimestamp > 0) {
      getCountDown(`${walletAddress}-endtime`, withdrawTimestamp, (job) => {

        dispatch({
          type: ACTION_CONST.SET_JOB_COUNTDOWN_STAKE_TIME,
          data: job
        });

      }, (job) => {
        setWithdrawTimestamp(0);
      });
    }
  }, [dispatch, walletAddress, withdrawTimestamp])

  //handle button withdraw click 
  const handleExcWithDraw = () => {


    if (walletUtils) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      walletUtils.stakingExecuteWithdrawRewards(data => {
        if (data.status === STATUS.WITHDRAW_STAKING_REWARDS_SUCCESS) {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: t('Withdraw stake successfully')
          })

        }
        if (data.status === STATUS.WITHDRAW_STAKING_REWARDS_FAIL) {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: t('Withdraw stake fail')
          })
        }
      })
    }
    //to do somrthing
  }

  //stake reward
  const handleExStakeRewards = async () => {
    if (walletUtils) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      walletUtils.stakingRewards(data => {
        if (data.status === STATUS.STAKING_REWARDS_SUCCESS) {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: t('Stake rewards successfully')
          })

        }
        if (data.status === STATUS.STAKING_REWARDS_FAIL) {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: t('Stake rewards fail')
          })
        }
      })
    }
  }

  return (
    <>
      <div className="p-sidebar">
        <div>
          <div className="p-sidebar-close mb-2">
            <button className="btn btn-link text-danger" type="button" onClick={() => toggleSidebar()}><i className="mdi mdi-arrow-right" /></button>
          </div>
          <div className="p-sidebar-card mt-md-3">
            <div className="p-sidebar-card-title">{t('Staked')}</div>
            <div className="p-sidebar-card-body">
              <div className="p-sidebar-card-value">{helpers.formatNumberDownRoundWithExtractMax(stakedAmount, 4)}</div>
            </div>
          </div>
          <div className="p-sidebar-card mt-md-4 mt-3">
            <div className="p-sidebar-card-title">{t('Unstaked')}</div>
            <div className="p-sidebar-card-body">
              <div className="p-sidebar-card-value">{helpers.formatNumberDownRoundWithExtractMax(unStakedAmount, 4)}</div>
              {parseFloat(unStakedAmount) > 0 && withdrawTimestamp > 0 && (
                <div>
                  {t('Withdrawable in:')}
                  <div className="p-sidebar-card-title" id={`${walletAddress}-endtime`}></div>
                </div>
              )}
              {parseFloat(unStakedAmount) > 0 && withdrawTimestamp === 0 && (<div>{t('Withdrawable Now')}</div>)}
            </div>
          </div>
          <div className="p-sidebar-card mt-md-4 mt-3">
            <div className="p-sidebar-card-title">{t('Rewards')}</div>
            <div className="p-sidebar-card-body">
              <div className="p-sidebar-card-value">{helpers.formatNumberDownRoundWithExtractMax(rewardAmount, 4)}</div>
              <div className="p-sidebar-card-actions">

                <button className="btn btn-primary btn-round" style={{ fontSize: '16px' }}
                  disabled={!isConnectWallet || parseFloat(rewardAmount) === 0 || parseFloat(unStakedAmount) > 0}
                  onClick={() => handleExStakeRewards()}><span>{t('Stake')}</span></button>
                <button className="btn btn-primary btn-round" style={{ fontSize: '16px' }}
                  disabled={!isConnectWallet || parseFloat(rewardAmount) === 0 || parseFloat(unStakedAmount) > 0}
                  onClick={() => handleExcWithDraw()}><span>{t('Withdraw')}</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-sidebar-backdrop"></div>
    </>
  );
};

export default RightBar;
