import React from "react";
import moment from 'moment'
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { ACTION_CONST } from "../../constants";
import { addTokenToMetamask } from '../../utils/metamaskUtils'
import { isMetamaskAvailable } from "../../utils/utils";
import { helpers } from "../../utils/helpers";
import { useTranslation } from "react-i18next";

const YourAllocationComponent = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const walletUtils = useSelector((state) =>
        get(state, "utils.walletUtils", null)
    );


    const handleClaimClick = (index) => {
        debugger
        if (walletUtils) {
            dispatch({
                type: ACTION_CONST.REQUEST_SUBMIT
            })

            walletUtils.claim({
                contractAddress: props.contractAddress,
                index: index
            }, (data) => {

                if (data.status == "CLAIM_SUCCESS") {

                    dispatch({ type: ACTION_CONST.REQUEST_DONE })

                    dispatch({
                        type: ACTION_CONST.ALERT_SUCCESS,
                        message: t('Tokens Successfully Claimed')
                    })
                    props.handleBuyClick()

                }
                if (data.status == "CLAIM_FAIL") {
                    dispatch({ type: ACTION_CONST.REQUEST_DONE })
                    dispatch({
                        type: ACTION_CONST.ALERT_FAILS,
                        message: t('Failed to claim tokens, Please contact support')
                    })
                    props.handleBuyClick()
                }
                //code handle event claim
            }).catch(err=>{
                console.log(err);
            })
        }
    }

    const handleAddTokenToMetamask = async () => {
        if (props.tokenAddress) {
            addTokenToMetamask({
                tokenAddress: props.tokenAddress,
                tokenSymbol: props.tokenSymbol,
                tokenDecimals: props.decimals,
                tokenImage: ""
            }, (res) => {
                if (res.status == "ADD_TOKEN_SUCCESS") {
                    dispatch({
                        type: ACTION_CONST.ALERT_SUCCESS,
                        message: t('Successfully added token to MetaMask')
                    })
                }
                if (res.status == "ADD_TOKEN_FAILS") {
                    dispatch({
                        type: ACTION_CONST.ALERT_FAILS,
                        message: t('Failed to add token to MetaMask')
                    })
                }
            })
        } else {
            dispatch({
                type: ACTION_CONST.ALERT_FAILS,
                message: t('Token incorrect!')
            })
        }

    }

    return (
        <>

            <div className="tab-pane fade" id="allocation" role="tabpanel" aria-labelledby="allocation-tab">

                <div className="py-3">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table mb-0 pp-table-info">
                                            <tbody>
                                                <tr className="card-header" style={{ border: "none" }}>
                                                    <td>{t('No.')}</td>
                                                    <td>{t('Token allocation')}</td>
                                                    <td>{t('Percentage')}</td>
                                                    <td>{t('Date')}</td>
                                                    <td>{t('Token(s) claimed')}</td>
                                                  
                                                </tr>
                                                {props.allocationInfo.length > 0 &&
                                                    props.allocationInfo.map((item, key) => (
                                                        <tr key={key}>
                                                            <td>{item.no}</td>
                                                            <td>{helpers.formatNumberDownRoundWithExtractMax((item.allocationAmount / 10 ** props.decimals || 0), 6)} {props.tokenSymbol}</td>
                                                            <td>{(item.percentage/100).toFixed(2)}%</td>
                                                            <td>{item.timestamp !== 0 ?
                                                                <div>{moment(item.timestamp * 1000 || 0).utc().format('YYYY-MM-DD HH:mm:ss')} {t('UTC')} </div>
                                                                : t('DEX Listing')
                                                            }</td>
                                                            <td>{helpers.formatNumberDownRoundWithExtractMax((item.claimedAmount / 10 ** props.decimals || 0), 6)} {props.tokenSymbol}</td>
                                                            <td><button className="btn btn-primary " onClick={() => handleClaimClick(key)} disabled={!(item.status === "OPEN")}><span>{t('Claim Tokens')}</span></button></td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-4 text-md-end">
                            {
                                props.allocationInfo.length === 0 ? <div></div>
                                :
                                <button onClick={() => handleAddTokenToMetamask()} className="btn btn-primary ms-2"><i className="fas fa-plus me-2"></i><span>Add token to <b>MetaMask</b></span></button>
                            }
                        </div> */}
                    </div>
                </div>

            </div>
        </>
    );
};

export default YourAllocationComponent;
