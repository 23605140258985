import window from "global";
import { calculateBalanceBigNumberTron } from "./utils";
import { BigNumber } from "bignumber.js";
import exactMath from "exact-math";
import { MODE, SMART_CONTRACT, TRON_NODE_HOST } from "../_configs";

import { STATUS } from "../constants";
// import exactMath from 'exact-math';
import { extensionName } from "../constants/values";
import { helpers } from "./helpers";
import stakingABI from "../contracts/staking.json";
import erc20Abi from "../contracts/erc20.abi.json";
import idoAbi from "../contracts/bscpad.json";
// console.log(BLOCKCHAIN_NETWORK);
export default class WalletExtensionUtils {
  
  constructor(ex) {
    this.tronWeb = null;
    this.isWrongNetwork = false;
    this.extensionName = ex;
  }

  async connect() {
    if (this.extensionName === extensionName.tronLink) {
      if (window.tronWeb) {
        if (window.tronWeb.fullNode.host === TRON_NODE_HOST.LOCAL) {
          this.tronWeb = null;
          this.isWrongNetwork = true;
          return;
        }

        this.tronWeb = window.tronWeb;

        console.log("MODE==>", MODE);
        const isWrongNetwork =
          MODE === "TESTNET"
            ? window.tronWeb.fullNode.host !== TRON_NODE_HOST.TESTNET
            : window.tronWeb.fullNode.host !== TRON_NODE_HOST.MAINNET;

        if (isWrongNetwork) {
          this.isWrongNetwork = true;
          return;
        }

        this.address = window.tronWeb.defaultAddress.base58;
      } else throw new Error("Detect Wallet failed!");
    }
  }

  accountsChanged(callback) {
    // console.log(this.extension);
    if (this.extension) {
      this.extension.on("accountsChanged", function (accounts) {
        this.address = accounts[0];
        callback(accounts[0]);
      });
    }
  }

  chainChanged(callback) {
    // const this = this;
    // // debugger;
    // this.extension.on("chainChanged", function(chainId) {
    //     console.log("chainId==>", chainId);
    //     this.extension = window.ethereum;
    //     this.web3 = new Web3(window.ethereum);
    //     callback(chainId);
    // });
  }

  isConnected() {
    return this.tronWeb !== null;
  }

  checkWrongNetwork() {
    return this.isWrongNetwork;
  }

  getTronWeb () {
    return this.tronWeb;
  }

  //get approve
  async approve(
    { tokenContractAddress, spenderAddress, amount, decimals = 18 },
    callback
  ) {
    try {
      callback({
        status: STATUS.APPROVING,
      });

      const contract = await this.tronWeb.contract().at(tokenContractAddress);
      amount = calculateBalanceBigNumberTron(amount, decimals);
      let result = await contract.approve(spenderAddress, amount).send();

      console.log("amount input==>", amount);
      let cond = true;
      let count = 0;
      while (cond || count >= 4) {
        const checkAllowance = await this.getAllowance(
          tokenContractAddress,
          spenderAddress
        );
        console.log("Allowance ==>", checkAllowance);
        // debugger
        console.log(
          "condition=>",
          new BigNumber(checkAllowance).gte(new BigNumber(amount))
        );
        if (new BigNumber(checkAllowance).gte(new BigNumber(amount))) {
          cond = false;
          callback({
            status: STATUS.APPROVED,
            txID: result,
          });
        }

        count++;
        await new Promise((r) => setTimeout(r, 3000));
      }
      // debugger
      callback({
        status: STATUS.APPROVED,
        txID: result,
      });

      // return result;
    } catch (error) {
      callback({
        status: STATUS.APPROVE_FAILS,
      });
      console.log(error);
    }
  }

  async claim({ contractAddress, index }, callback) {
    let contract = await this.tronWeb.contract().at(contractAddress);
    // debugger;
    try {
      const claimResult = await contract.claim(index).send();
      // callback({
      //   status: STATUS.CLAIM_SUCCESS,
      //   txID: claimResult,
      // });

      if (claimResult) {
        await new Promise((r) => setTimeout(r, 5000));

        const tx = await this.tronWeb.trx.getTransaction(claimResult);
        if (tx && tx.ret && tx.ret.length > 0 && tx.ret[0].contractRet) {
          if (tx.ret[0].contractRet === "SUCCESS") {
            callback({
              status: STATUS.CLAIM_SUCCESS,
              txID: claimResult,
            });
          } else {
            callback({
              status: STATUS.CLAIM_FAIL,
            });
          }
        }
      } else {
        callback({
          status: STATUS.CLAIM_FAIL,
        });
      }
      // return claimResult;
    } catch (e) {
      debugger;
      console.error(e.message);
      callback({
        status: STATUS.CLAIM_FAIL,
      });
      return e.message;
    }
  }

  async buyTokens(
    { contractAddress, tokenAddress, amount, decimal },
    callback
  ) {
    try {
      callback({
        status: STATUS.BUY_IDO_SUBMITTING,
      });
      let contract = await this.tronWeb.contract().at(contractAddress);
      amount = calculateBalanceBigNumberTron(amount, decimal || 6);
      const buyResult = await contract.participate(tokenAddress, amount).send();
      await new Promise((r) => setTimeout(r, 3000));

      callback({
        status: STATUS.BUY_IDO_SUCCESS,
        txID: buyResult,
      });

      // if (buyResult) {
      //   await new Promise((r) => setTimeout(r, 5000));

      //   const tx = await this.tronWeb.trx.getTransaction(buyResult);
      //   if (tx && tx.ret && tx.ret.length > 0 && tx.ret[0].contractRet) {
      //     if (tx.ret[0].contractRet === "SUCCESS") {
      //       callback({
      //         status: STATUS.BUY_IDO_SUCCESS,
      //         txID: buyResult,
      //       });
      //     } else {
      //       callback({
      //         status: STATUS.BUY_IDO_FAIL,
      //       });
      //     }
      //   }
      // } else {
      //   callback({
      //     status: STATUS.BUY_IDO_FAIL,
      //   });
      // }
    } catch (error) {
      console.log(error);
      callback({
        status: STATUS.BUY_IDO_FAIL,
      });
    }
  }

  //get current account
  getCurrentAddress() {
    return this.address;
  }

  async getInfoAllocations(contractAddress) {
    const claimStatus = {
      0: "PENDING",
      1: "OPEN",
      2: "CLOSED",
    };
    const contract = await this.tronWeb.contract().at(contractAddress);
    const contractInfoAllocation = await contract.infoAllocations().call();
    const decimals = contractInfoAllocation[0].toString()
    let infoAllocation = [];
    for (let i = 0; i < contractInfoAllocation[1].length; i++) {
      infoAllocation.push({
        no: contractInfoAllocation[1][i].toString(),

        allocationAmount: contractInfoAllocation[2][i].toString(),
        percentage: contractInfoAllocation[3][i].toString(),
        timestamp: parseInt(contractInfoAllocation[4][i].toString()),
        claimedAmount: contractInfoAllocation[5][i].toString(),
        status: claimStatus[contractInfoAllocation[6][i]].toString(),
      });
    }

    return {decimals, infoAllocation};
  }

  async getInfoWallet(contractAddress) {
    try {
      const contract = await this.tronWeb.contract().at(contractAddress);
      const contractInfo = await contract.infoWallet().call();

      const trxBalance = contractInfo[0].toString() / 10 ** 6;
      const tokenBalance = contractInfo[1].toString();
      const tier = contractInfo[2].toString();
      const roundState = parseInt(contractInfo[3].toString());
      const roundStateText = contractInfo[4].toString();
      const roundTimestamp = parseInt(contractInfo[5].toString());
      const remainingAllocation = contractInfo[6].toString();
      const userParticipation = parseInt(contractInfo[7].toString());
      return {
        tokenBalance,
        tier,
        roundState,
        roundStateText,
        // roundStateString,
        roundTimestamp,
        remainingAllocation,
        trxBalance,
        userParticipation,
      };
    } catch (error) {
      console.log(error);
      // debugger;
      return null;
    }
  }

  async getBalanceAccount() {
    try {
      let balance = await this.tronWeb.trx.getBalance(this.address);

      return helpers.formatNumberDownRoundWithExtractMax(balance / 10 ** 6, 6);
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  async getTokenBalance({ tokenAddress, decimal }) {
    try {
      let contract = await this.tronWeb.contract().at(tokenAddress);
      const tokenBalance = await contract.balanceOf(this.address).call();

      // const balance = new BigNumber(tokenBalance.toString())
      //   .dividedBy(10 ** decimal || 18)
      //   .toFixed(6)
      //   .replace(/\.?0+$/, "")
      //   .toString();
      // return balance;
      return tokenBalance.toString();
    } catch (error) {
      console.log(error);
    }

    return 0;
  }

  async getAllowance(tokenAddress, contractAddress) {
    try {
      let tokenContract = await this.tronWeb.contract(erc20Abi, tokenAddress);
      // let tokenContract = await this.tronWeb.contract().at(tokenAddress);

      const resAllocationNumber = await tokenContract
        .allowance(this.address, contractAddress)
        .call();
      // return exactMath.div(allocationNumber, exactMath.pow(10, 18))
      const allocationNumber = resAllocationNumber.toString();
      // debugger
      return allocationNumber;
    } catch (error) {
      console.log(error);
      return 0;
    }

    // return parseFloat(allocationNumber / 10 ** 18);
  }
  async getAllowanceIDO(tokenAddress, contractAddress) {
    try {
      let tokenContract = await this.tronWeb.contract().at(tokenAddress);
      // const tokenContract = new this.web3.eth.Contract(erc20Abi, tokenAddress);
      const resAllocationNumber = await tokenContract
        .allowance(this.address, contractAddress)
        .call();

      // return exactMath.div(allocationNumber, exactMath.pow(10, 18))
      const allocationNumber = resAllocationNumber["remaining"].toString();
      // debugger
      return allocationNumber;
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  async stakingDeposit({ amount, decimals = 18 }, callback) {
    callback({
      status: STATUS.STAKING_DEPOSIT_SUBMIT,
      txID: "",
    });

    const contract = await this.tronWeb.contract(
      stakingABI,
      SMART_CONTRACT.STAKING[MODE]
    );
    amount = calculateBalanceBigNumberTron(amount, decimals);

    try {
      let depositResult = await contract.stake(amount).send();
      await new Promise((r) => setTimeout(r, 3000));
      callback({
        status: STATUS.STAKING_DEPOSIT_SUCCESS,
        txID: depositResult,
      });

      return depositResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: STATUS.STAKING_DEPOSIT_FAIL,
      });
      return e.message;
    }
  }

  //request withdraw staking
  async stakingInitiateWithdrawal({ amount, decimals = 18 }, callback) {
    const contract = await this.tronWeb.contract(
      stakingABI,
      SMART_CONTRACT.STAKING[MODE]
    );
    amount = calculateBalanceBigNumberTron(amount, decimals);
    callback({
      status: STATUS.STAKING_INITIATE_WITHDRAWAL_SUBMIT,
      txID: "",
    });
    try {
      const initiateWithdrawalResult = await contract.unstake(amount).send();
      await new Promise((r) => setTimeout(r, 3000));
      callback({
        status: STATUS.STAKING_INITIATE_WITHDRAWAL_SUCCESS,
        txID: initiateWithdrawalResult,
      });

      return initiateWithdrawalResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL,
      });
      return e.message;
    }
  }

  // execute withdraw staking
  async stakingExecuteWithdrawal(callback) {
    const contract = await this.tronWeb.contract(
      stakingABI,
      SMART_CONTRACT.STAKING[MODE]
    );
    callback({
      status: STATUS.STAKING_EXECUTE_WITHDRAWAL_SUBMIT,
      txID: "",
    });
    try {
      const executeWithdrawalResult = await contract.withdraw().send();
      await new Promise((r) => setTimeout(r, 3000));
      callback({
        status: STATUS.STAKING_EXECUTE_WITHDRAWAL_SUCCESS,
        txID: executeWithdrawalResult,
      });

      return executeWithdrawalResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL,
      });
      return e.message;
    }
  }

  // execute withdraw rewards
  async stakingExecuteWithdrawRewards(callback) {
    const contract = await this.tronWeb.contract(
      stakingABI,
      SMART_CONTRACT.STAKING[MODE]
    );

    try {
      callback({
        status: STATUS.WITHDRAW_STAKING_REWARDS_SUBMIT,
        txID: "",
      });
      const executeWithdrawRewardsResult = await contract
        .withdrawRewards()
        .send();

      await new Promise((r) => setTimeout(r, 3000));

      callback({
        status: STATUS.WITHDRAW_STAKING_REWARDS_SUCCESS,
        txID: executeWithdrawRewardsResult,
      });

      return executeWithdrawRewardsResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: STATUS.WITHDRAW_STAKING_REWARDS_FAIL,
      });
      return e.message;
    }
  }

  async stakingRewards(callback) {
    const contract = await this.tronWeb.contract(
      stakingABI,
      SMART_CONTRACT.STAKING[MODE]
    );

    try {
      callback({
        status: STATUS.STAKING_REWARDS_SUBMIT,
        txID: "",
      });
      const executeStakeRewardsResult = await contract.stakeRewards().send();
      await new Promise((r) => setTimeout(r, 3000));

      callback({
        status: STATUS.STAKING_REWARDS_SUCCESS,
        txID: executeStakeRewardsResult,
      });
      return executeStakeRewardsResult;
    } catch (e) {
      console.error(e.message);
      callback({
        status: STATUS.STAKING_REWARDS_FAIL,
      });
      return e.message;
    }
  }

  async getStakingInfoWallet() {
    let contract = await this.tronWeb.contract(
      stakingABI,
      SMART_CONTRACT.STAKING[MODE]
    );
    const infoWallet = await contract
      .infoWallet(this.address)
      .call({ _isConstant: true });

    return {
      stakedAmount: BigNumber(infoWallet[0].toString())
        .dividedBy(10 ** 18)
        .toString(),
      unStakedAmount: BigNumber(infoWallet[1].toString())
        .dividedBy(10 ** 18)
        .toString(),
      depositTimestamp: Number(infoWallet[2]) * 1000,
      lastUnstakeTimestamp: Number(infoWallet[3]) * 1000,
      withdrawTimestamp: Number(infoWallet[4]) * 1000,
      rewardAmount: BigNumber(infoWallet[5].toString())
        .dividedBy(10 ** 18)
        .toFixed(18)
        .replace(/\.?0+$/, "")
        .toString(),
    };
  }
  //add staking
}
