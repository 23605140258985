import React from 'react';
import { useTranslation } from 'react-i18next';
const HelpModal = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="modal fade in" id="helpModal" tabIndex="-1" aria-labelledby="helpModalLabel" >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content modal-content modal-help">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex mb-3">
                                <a className="btn btn-info me-2 w-100 text-white text-center" href="https://link.trustwallet.com/open_url?coin_id=56&url=https://tronpad.network/">
                                    {t('Open with')} <img src="/images/trust_platform.png" width="30px" className="me-2" alt="tronpad" />
                                </a>
                            </div>

                            <h2 className="text-danger">{t('How to Set Up and Use Trust Wallet for TRONPAD.NETWORK')}</h2>
                            <h5 className="mt-3">{t('Setting up your wallet for Tron')}</h5>
                            <ul className="ps-0" style={{ listStyle: 'none' }}>
                                <li>{t('1). Download Trust Wallet. If you already have Trust Wallet, make sure your app is up to date.')}</li>
                                <li>{t('2). Complete')} <a href="https://community.trustwallet.com/t/how-to-create-a-multi-coin-wallet/41" target="_blank">{t('basic setup of a multi-coin wallet')}</a>. {t('Remember to save your backup phrases.')}</li>
                                <li>{t('3). Go to your Smart Chain wallet and press receive to find your address')}</li>
                                <li>{t('4). That’s it! You’re ready to start using Tron')}</li>
                            </ul>
                            <h5 className="mt-4">{t('Using TRONPAD.NETWORK in Trust Wallet')}</h5>
                            <ul className="ps-0" style={{ listStyle: 'none' }}>
                                <li>{t('5). For this part, iOS users will need the Dapp browser. Android users have the Dapp browser by default.')}</li>
                                <li>{t('6). Once you’ve topped up your wallet, open the Dapp browser by pressing on the four squares at the bottom of the app.')}</li>
                            </ul>
                            <p className="text-center">
                                <img style={{ maxWidth: '97%' }} className="mx-auto" src='/images/help-1.png' alt="pscpad" />
                            </p>
                            <ul className="ps-0" style={{ listStyle: 'none' }}>
                                <li dangerouslySetInnerHTML={{__html: t('7). Input <b>tronpad.network</b>')}}></li>
                                <li>{t('8). Change network to Tron')}</li>
                                <li>{t('9). Use the Dapp and have fun!')}</li>
                            </ul>
                            <h5 className="mt-5">{t('Enable DApp Browser on Trust Wallet (iOS version)')}</h5>
                            <ul className="ps-0" style={{ listStyle: 'none' }}>
                                <li>
                                    <p dangerouslySetInnerHTML={{__html: t('1). Open <b>Safari Browser</b> and then type in the URL: <b>trust://browser_enable</b>, then tap on <b>Go</b>')}}></p>
                                    <p><img style={{ maxWidth: '97%' }} className="mx-auto" src="/images/help-2.png" alt="" /></p>
                                </li>
                                <li>
                                    <p dangerouslySetInnerHTML={{__html: t('2). A prompt will appear that will ask if you want to <b>Open this page in “Trust”?</b>, tap on <b>Open</b>')}}></p>
                                    <p><img style={{ maxWidth: '97%' }} className="mx-auto" src="/images/help-3.png" alt="" /></p>
                                    <p dangerouslySetInnerHTML={{__html: t('The <b>Trust Wallet</b> app will launch and the <b>DApp browser</b> will be enabled')}}></p>
                                    <p><img style={{ maxWidth: '97%' }} className="mx-auto" src="/images/help-4.png" alt="" /></p>
                                </li>
                            </ul>

                            <div className="d-flex mt-4">
                                <a className="btn btn-info me-2 w-100 text-white text-center" href="https://link.trustwallet.com/open_url?coin_id=56&url=https://tronpad.network/">
                                    {t('Open with')} <img src="/images/trust_platform.png" width="30px" className="me-2" />
                                </a>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    );
}

export default HelpModal;


