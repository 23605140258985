import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

import WithDrawTab from "./WithDrawTab";
import StakingTab from "./StakingTab";
import UnStakingTab from "./UnStakingTab";
import { ACTION_CONST } from "../../constants";
import { getStakingContractInfo } from "../../utils/tronHelpers";
import { helpers } from "../../utils";
import RightBar from "./RightBar";
import { MODE, SMART_CONTRACT } from "../../_configs";
import { useTranslation } from "react-i18next";

const StakingPortalPage = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [stakerCount, setStakerCount] = useState(0);
    const [totalStakedAmount, setTotalStakedAmount] = useState(0);
    const [apyPercentage, setApyPercentage] = useState(0);
    const stakingInfo = useSelector((state) => get(state, "wallet.stakingInfo", {}));

    const submitOK = useSelector((state) =>
        get(state, "utils.blocking", false)
    );

    useEffect(() => {
        // console.log("call getStakingContractInfo-->");
        try {
            getStakingContractInfo(SMART_CONTRACT.STAKING[MODE]).then(data => {
           
                setStakerCount(data.stakerCount);
                setTotalStakedAmount(data.totalStakedAmount);
                setApyPercentage(data.apyPercentage);
                dispatch({ type: ACTION_CONST.GET_STAKING_INFO, data: data });
            })

        } catch (error) {
            console.log(error);
        }


        //setup interval
        const job = setInterval(() => {
            getStakingContractInfo(SMART_CONTRACT.STAKING[MODE]).then(data => {

                setStakerCount(data.stakerCount);
                setTotalStakedAmount(data.totalStakedAmount);
                setApyPercentage(data.apyPercentage);
                dispatch({ type: ACTION_CONST.GET_STAKING_INFO, data: data });
            })
        }, 60 * 1000);

        dispatch({ type: ACTION_CONST.SET_JOB_GET_STAKING_INFO, job });

    }, [dispatch, submitOK]);



    return (
        <>
            {
                stakingInfo["isStakingPaused"] &&
                <div className="container mt-4 text-center d-md-block d-none">
                    <span className="pp-banner-top">
                        <i className="mdi mdi-alert-outline me-2 text-danger">
                        </i> {t('All staking functions are temporarily paused. Please check back again later.')}
                    </span>
                </div>
            }
            <div className="page-container">
                {
                    stakingInfo["isStakingPaused"] &&
                    <div className="container mt-4 text-center d-md-none d-block pp-notice">
                        <span className="pp-banner-top">
                            <i className="mdi mdi-alert-outline me-2 text-danger">
                            </i> {t('All staking functions are temporarily paused. Please check back again later.')}
                    </span>
                    </div>
                }
                <div className="p-content">
                    <div className="container-fluid mt-4 p-scroll">
                        <div className="p-content-tabs">
                            <div className="row align-items-start">
                                <div className="col-xl-4">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="stake-tab" data-bs-toggle="tab"
                                               
                                                data-bs-target="#stake" type="button" role="tab" aria-controls="stake" aria-selected="true">{t('Stake')}</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="unStake-tab" data-bs-toggle="tab"
                                               
                                                data-bs-target="#unStake" type="button" role="tab" aria-controls="unStake" aria-selected="false">{t('Unstake')}</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="withdraw-tab" data-bs-toggle="tab"
                                               
                                                data-bs-target="#withdraw" type="button" role="tab" aria-controls="withdraw" aria-selected="false">{t('Withdraw')}</button>
                                        </li>
                                       
                                    </ul>
                                </div>
                                <div className="col-xl-8 mt-xl-0 mt-3">
                                    <div className="p-cards-top d-flex justify-content-xl-end justify-content-lg-center justify-content-md-center justify-content-start">
                                        <div className="p-sidebar-card p-card-top text-center mb-0 py-2 px-3 me-md-3 me-2">
                                            <div className="p-sidebar-card-title">{t('Number of Stakers')}</div>
                                            <div className="p-sidebar-card-body mt-0">
                                                <b className="text-danger font-24">{stakerCount}</b>
                                            </div>
                                        </div>
                                        <div className="p-sidebar-card p-card-top text-center mb-0 py-2 px-3 me-md-3 me-2">
                                            <div className="p-sidebar-card-title">{t('Total TRONPAD Staked')}</div>
                                            <div className="p-sidebar-card-body mt-0">
                                                <b className="text-danger font-24">
                                                    { helpers.formatNumberDownRoundWithExtractMax(totalStakedAmount, 2)}
                                                </b>
                                            </div>
                                        </div>
                                        <div className="p-sidebar-card p-card-top text-center mb-0 py-2 px-3">
                                            <div className="p-sidebar-card-title">{t('APY')}</div>
                                            <div className="p-sidebar-card-body mt-0">
                                                <b className="text-danger font-24">
                                                    N/A
                                                    {/* {helpers.formatNumberDownRound((apyPercentage / 100).toString(), 2)}% */}
                                            </b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-content" id="myTabContent">
                                <StakingTab />
                                <UnStakingTab />
                                <WithDrawTab />
                            </div>
                        </div>
                    </div>
                </div>
                <RightBar />
            </div>
        </>
    );
};

export default StakingPortalPage;
