export const MODE = process.env.REACT_APP_MODE || "TESTNET";

export const EXPLORER = {
  MAINNET: "https://tronscan.org/#",
  TESTNET: "https://nile.tronscan.org/#",
};

export const BACKEND_URL = {
  MAINNET: "https://tronpad.network", //product
  // MAINNET: "https://staging-tp.cloud",//staging
  // MAINNET: "http://tronpad.something.casa", //test
};

export const TRONPAD_ADDRESS = {
  MAINNET: 'TBLrDTgEsQk6a6PkB6KYp9xU4oJpHiRde8', // product
  TESTNET: "TW1pXaPEMyU6nyvnkCRWsQnpiAfgcCf9fR",
};

export const SMART_CONTRACT = {
  STAKING: {
    MAINNET: "TD5DFqLSJCG59WweFvcW5w7fydGjXZNTKg", //product
    TESTNET: "TPwoUM3y8XfPAZKdjTKow6YZ9y1XinwFRy",
  },
};

export const TRON_NODE_HOST = {
  MAINNET: "https://api.trongrid.io",
  TESTNET: "https://api.nileex.io",
  LOCAL: "http://127.0.0.1",
};

export const BRIDGE_URI = {
  MAINNET: "https://bridge.tronpad.network", //product
  // MAINNET: 'http://bridge.staging-tp.cloud', //test
  TESTNET: "http://bridge.myfantasy.cloud",
};



export const KYC_BACKEND_URL = {
  MAINNET: 'https://bscpad.com',  //product
  // MAINNET: "https://myfantasy.cloud", //test
  TESTNET: "https://myfantasy.cloud",
};
export const API_KEY = {
  MAINNET:[process.env.REACT_APP_API_KEY1,process.env.REACT_APP_API_KEY2, process.env.REACT_APP_API_KEY3 ],
  TESTNET: ["ba07a61e-0d39-4cb2-9dc8-390cf976fe00", "345fc575-bb80-4338-937a-ef5dbe687a2b","8bb9de79-a63c-48eb-a36d-8e9d288dc663"]
}

export const PRIVATE_KEY = {
  MAINNET: process.env.REACT_APP_PRIVATE_KEY,  //product
  // MAINNET: "https://myfantasy.cloud", //test
  TESTNET: "",
}