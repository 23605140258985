
import { ENDPOINTS } from "../../constants";
import axios from "axios";
import { BACKEND_URL, MODE } from "../../_configs";


export const getProjects = async()=>{
  try {
    const result = await axios.get(
      `${BACKEND_URL[MODE]}${ENDPOINTS.GET_PROJECTS}`
    );
    console.log(result);
    if (result.status === 200) {
      return result.data;
    }

    return [];
  } catch (error) {
    const response = error.response;

    console.log(response);
    //    return response

    return [];
  }
}

export const getProject = async (contract) => {
  try {
    const result = await axios.get(
      `${BACKEND_URL[MODE]}${ENDPOINTS.GET_PROJECT_DETAILS.replace(':id', contract)}`
    );
    if (result.status === 200) {
      return {
        status: 200,
        data: result.data
      }
    } else {
      return {
        status: 404,
        data: null
      }
    }
  } catch (error) {
    const response = error.response;

    console.log(response);

    return {
      status: 404,
      data: null
    }
  }
}
