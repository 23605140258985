import React, { useEffect, useState } from 'react';
import WalletExtensionUtils from "../../utils/walletExtensionUtils";
import { extensionName } from "../../constants/values";
import { isTronLinkAvailable, } from "../../utils/utils";
import { actAlertMsgWarning } from '../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_CONST } from '../../constants';
import { isMobile } from 'web3modal';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
// import { getKYC } from '../../redux/services/kyc.api'

const ConnectWalletModal = (props) => {
    const { t } = useTranslation();
    // const wallet = useWallet();
    const dispatch = useDispatch();
    //show hide button
    const [hasTronLink, setHasTronLink] = useState(false);
    const [modelTitle, setModelTitle] = useState("")

    useEffect(() => {
        setTimeout(() => {
            addWallet();
        }, 5000);
    }, []);
    const addWallet = () => {
        setHasTronLink(isTronLinkAvailable());
    };

    useEffect(() => {
        if (isMobile() && !hasTronLink) {
            setModelTitle(t('Please open site in Tronlink app'))
        } else {
            setModelTitle(t('Connect to wallet'))
        }
    }, [isMobile, hasTronLink])



    const connectWithExtension = async (extensionName) => {
        const temp = new WalletExtensionUtils(extensionName);
        //Connect action
        await temp.connect();

        if (!temp.isConnected()) {
            dispatch(
                actAlertMsgWarning(t('Cannot connect to TRON network! Please check your TronLink!'))
            );
            return;
        }

        if (temp.checkWrongNetwork()) {
            dispatch(
                actAlertMsgWarning(t('Wrong network! You need connect to TRON network!'))
            );
            return;
        }

        //Show Block UI
        dispatch({
            type: ACTION_CONST.REQUEST_SUBMIT
        })


        //Disable Block UI
        dispatch({
            type: ACTION_CONST.REQUEST_DONE
        })


        dispatch({
            type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
            data: temp
        })

        

        // // iff account exchange
        // try {
        //     temp.accountsChanged(async (res) => {
        //         if (res !== undefined) {
        //             // console.log('account changed')
        //             dispatch({
        //                 type: ACTION_CONST.CLEAR_KYC_STATE
        //             })
        //             await getBalanceAndAddress(temp);
        //         }
        //     });

        //     return true;
        // } catch (e) {
        //     dispatch({
        //         type: ACTION_CONST.REQUEST_DONE
        //     })
        //     console.log("error: ", e);
        // }

        // //if chain ID
        // try {
        //     temp.chainChanged(async (chanId) => {
        //         // debugger
        //         await temp.connect();
        //         if (temp.checkWrongNetwork()) {

        //             dispatch(
        //                 actAlertMsgWarning(
        //                     "Wrong network! You need connect to TRON network!"
        //                 )
        //             );
        //             return;
        //         }
        //         await getBalanceAndAddress(temp);
        //     })
        // } catch (error) {
        // }
    };

    const getExtension = () => {
        return extensionName;
    };

    const getBalanceAndAddress = async (extension) => {
        const walletAddress = await extension.getCurrentAddress();

        dispatch({
            type: ACTION_CONST.CONNECT_WALLET_SUCCESS,
            data: walletAddress
        })
    };

    const walletUtils = useSelector((state) =>get(state, "utils.walletUtils", null));
    useEffect(()=>{
        if(walletUtils){
            getBalanceAndAddress(walletUtils);
        }
        
    }, [walletUtils])

    return (
        <>
            <div className="modal fade" id="connectWalletModal" tabIndex="-1" aria-labelledby="connectWalletModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="connectWalletModalLabel">{modelTitle}</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            {
                                hasTronLink &&
                                <div className="c-list border-b px-3 py-2 d-flex align-items-center" data-bs-dismiss="modal" onClick={() => {
                                    connectWithExtension(
                                        getExtension().tronLink
                                    )
                                }}>
                                    <img src="/images/trx-icon.svg" width="30px" className="me-2" alt="TronLink" />
                                    <div>
                                        {t('TronLink')}
                                        {/* - <span className="font-weight-bold">Desktop</span> */}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConnectWalletModal;


